import React, { useState } from 'react';
import '../../index.css';
import '../../stoke.css';
import '../../bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ContentItem, FAQcategory } from '../../types';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

 
const FAQsstyle: React.FC<ContentItem> = ({
    leftContent,
    rightContent,
    topCurve,
    topCurveFilter,
    backgroudColorSection,
    bottomCurveFilter,  
    bottomCurve,
    leftContentStyle,
    rightContentStyle,
    leftImage,
    rightImage,
    useMargin = true,
    faqs = [],
    cardColor,
  }) => {
  
    const marginContent = React.useMemo(
      () => bottomCurve && useMargin ? { marginTop: "-130px"} : undefined
    ,[]);

    
    const sectionRef = React.useRef<HTMLDivElement | null>(null);
    
    const onScrollIntoView = () => {
      let position: DOMRect | undefined = sectionRef?.current?.getBoundingClientRect();
    
      // checking for partial visibility
      if(position && (position.top < window.innerHeight && position.bottom >= 0)) {
        sectionRef?.current?.classList.add('animation-end');
        window.removeEventListener('scroll', onScrollIntoView);
      }
    }
  
    React.useEffect(() => {
      onScrollIntoView();
      window.addEventListener('scroll', onScrollIntoView);
    }, []);

    const [selectedCategory, setSelectedCategory] = React.useState<FAQcategory | undefined>(undefined);

//this says browser we want to listen to this event, we will receive the event and set the category int he state to the sam that was passed as the event

/*  if (!selectedCategory)  <this is checking if it is falsy
/*  without the exclamation mark it would check if it is truthy
/* if youre not comparing for a specific inside the brackets youre comparing falsy or truthy


    falsy:
        - false;
        - undefined;
        - null;
        - 0;
    truthy:
        - true;
        - "";
        - 1 or +;
        - [];
        - {};
*/

    React.useEffect(() => {
        window.addEventListener("changeFAQCategory", (e: any) => {
            setSelectedCategory(e.detail as FAQcategory);
        });
    }, []);

    const filteredFaqs = React.useMemo(() => {
        if (!selectedCategory) {
            return faqs;
        }
        return faqs.filter(faq => faq.category.includes(selectedCategory));
    }, [faqs, selectedCategory]);
  
    return (


        <div id='first-section-of-page'>
            <div className="content site-content">
                <div className="primary content-area">
                    <div className="main site-main" style={backgroudColorSection}>
                        <div className='section help-child-section-1' ><img src={topCurve} style={topCurveFilter} />
                            <div style={{ paddingTop: "50px" }}>
                                <Container className='animation-start' ref={sectionRef}>
                                    <Accordion defaultActiveKey="0">
                                        {
                                            filteredFaqs.map((faq, index) => {
                                                return (
                                                    <Card style={{backgroundColor: cardColor}}>
                                                        <Accordion.Toggle className='accordion-toggle' as={Card.Header} eventKey={index.toString()} style={{color: "white"}}>
                                                            {faq.question}
                                                        </Accordion.Toggle>
                                                        <Accordion.Collapse eventKey={index.toString()} style={{color: "white"}}>
                                                            <Card.Body> {faq.answer}</Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                )
                                            })
                                        }
                                    </Accordion>

                                </Container></div>
                            <div style={{ paddingTop: "80px" }}>
                                <img src={require ('../../img/symmetrical-bottom-curve-green.png')}  />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

  );
}
export default FAQsstyle;

// //dangerouslySetInnerHTML={{ __html: topContent }} this makes html work somehow, try to avoid using it because it means DANGEEEEERRRR

