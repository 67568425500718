import React, { useState } from 'react';
import '../../index.css';
import '../../stoke.css';
import '../../bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ContentItem } from '../../types';



const Imagestyle: React.FC<ContentItem> = ({
  leftContent,
  rightContent,
  topCurve,
  topCurveFilter,
  backgroudColorSection,
  bottomCurveFilter,  
  bottomCurve,
  leftContentStyle,
  rightContentStyle,
  leftImage,
  rightImage,
  useMargin = true,
  backgroundImage,
}) => {

  const marginContent = React.useMemo(
    () => bottomCurve && useMargin ? { marginTop: "-130px"} : undefined
  ,[]);


  const sectionRef = React.useRef<HTMLDivElement | null>(null);
    
  const onScrollIntoView = () => {
    let position: DOMRect | undefined = sectionRef?.current?.getBoundingClientRect();
  
    // checking for partial visibility
    if(position && (position.top < window.innerHeight && position.bottom >= 0)) {
      sectionRef?.current?.classList.add('animation-end');
      window.removeEventListener('scroll', onScrollIntoView);
    }
  }

  React.useEffect(() => {
    onScrollIntoView();
    window.addEventListener('scroll', onScrollIntoView);
  }, []);
  
  return (
    <div id='first-section-of-page'>
      <div className="content site-content" style={{marginTop: -1}}>
        <div className="primary content-area">
          <main className="main site-main" style={{marginTop:-3,marginBottom:-3}}>
            <section className="section about-section-2" >
              <img src={leftImage} />
              <div className="section__overlay">
                <Container  className="fadeInTop fadeInTop--active animation-start" ref={sectionRef} >
                  <Row >
                    <Col md="6" className="col-md-6">
                      <div className="section-content">
                        <div className="section-content__inner">
                          <div className="section-text">
                            <div className="section-circle" style={{background:' #a7226e ',}}>
                              {leftContent && (
                                <div style={leftContentStyle} dangerouslySetInnerHTML={{ __html: leftContent }} />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  
                    <Col md="6" className="col-md-6 no-mobile" >
                      <div className="section-content no-mobile">
                        <div className="section-content__inner">
                          <div className="section-text">
                            <div className="section-circle" style={{background:' #3b7bb8'}}>
                              {rightContent && (
                                <div style={leftContentStyle} dangerouslySetInnerHTML={{ __html: rightContent }} />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </section>
          </main>
        </div>
      </div>
    </div>
  );
}
export default Imagestyle;

//dangerouslySetInnerHTML={{ __html: topContent }} this makes html work somehow, try to avoid using it because it means DANGEEEEERRRR

