import React, { useState } from 'react';
import '../../index.css';
import '../../stoke.css';
import '../../bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ContentItem } from '../../types';
import classes from '../../BackgroundVideo.module.css';



// const videoSource = require('../../img/red.mp4');
const videoSource = require('../../img/baby.mp4');



const logo = require('../../img/ces-logo.png');


const Bannerstyle: React.FC<ContentItem> = ({
  leftContent,
  rightContent,
  topCurve,
  topCurveFilter,
  backgroudColorSection,
  bottomCurveFilter,
  bottomCurve,
  leftContentStyle,
  rightContentStyle,
  leftImage,
  rightImage,
  useMargin = true,
  vidSource,
  bottomCurvePeopleMobile,
  bottomCurvePeopleDesktop,

}) => {

  const marginContent = React.useMemo(
    () => bottomCurve && useMargin ? { marginTop: "-130px" } : undefined
    , []);


  const imageRef = React.useRef<HTMLImageElement | null>(null);

  const peopeDesktopRef = React.useRef<HTMLImageElement | null>(null);
  const peopeMobileRef = React.useRef<HTMLImageElement | null>(null);

  const onScrollIntoView = () => {
    let position: DOMRect | undefined = imageRef?.current?.getBoundingClientRect();

    // checking for partial visibility
    if (position && (position.top < window.innerHeight && position.bottom >= 0)) {
      imageRef?.current?.classList.add('logo-animation-end');
      peopeDesktopRef?.current?.classList.add('people-animation-end');
      peopeMobileRef?.current?.classList.add('people-animation-end');
      window.removeEventListener('scroll', onScrollIntoView);
    }
  } 

  React.useEffect(() => {
    onScrollIntoView();
  }, []);

  return (


      <div >
        <section className="section home-section-bg" >

          {/* <div className={classes.Container} >
            <video autoPlay={true} loop={true} muted className={classes.Video} >
              <source src={vidSource} type="video/mp4" style={{ filter: "red(100%)" }} />
                Your browser does not support the video tag.
            </video>
          </div > */}


          <img src={topCurve} style={topCurveFilter} className="whale-curve-top pointer-events-none" />
          
          
          <img ref={peopeDesktopRef} src={bottomCurvePeopleMobile} style={bottomCurveFilter} className="whale-curve-bottom pointer-events-none no-desktop people-animation" />
          <img ref={peopeMobileRef}  src={bottomCurvePeopleDesktop} style={bottomCurveFilter} className="whale-curve-bottom pointer-events-none no-mobile people-animation" />

          <div  >




          <div className={classes.Content}>
            <div className={classes.SubContent} >
              <div className="saraimargin">
              <Container className='logo-animation' ref={imageRef}>
               
{/* 
                  <Col md="12" style={{ height: `400px`, margin: "0 auto" }}>
                    <div className="section-content">
                      <div className="section-content__inner"> */}

                        {/* <img src={logo} className='logo-animation' ref={imageRef} /> */}

                        <Row style={marginContent} >
                          <Col md="6" className="col-md-6"   >
                            <div className="section-content">
                              <div className="section-content__inner">
                                <img src={leftImage} />
                                {leftContent && (
                                  <div style={leftContentStyle} dangerouslySetInnerHTML={{ __html: leftContent }} />
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col md="6" className="col-md-6"  style={{margin: "0 auto" }} >
                            <div className="section-content">
                              <div className="section-content__inner">
                                {rightContent && (
                                  <div style={rightContentStyle} dangerouslySetInnerHTML={{ __html: rightContent }} />
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>

                        {/* 
                    <div className="section-text section-text--wide fadeInTop fadeInTop--active">
                    <h2 className="heading heading--large" > Affordable, renewable electricity for everyone</h2> 
                    </div>

                      <div className="section-text section-text--wide fadeInTop fadeInTop--active">
                        <h1 className="heading heading--large" style={{color:'white', textShadow: "0px 0px 20px rgba(0, 0, 0, 0.6);"}}>
                          <strong>Lorem ipsum dolor</strong>
                          <span style={{fontWeight:300, display:'block', color:'white'}}>consectetur adipiscing elit.<br/> Morbi faucibus risus.</span>
                        </h1>
                      </div> */}
                      {/* </div>
                    </div>
                  </Col> */}
                
              </Container>
              </div>
            </div> </div>

            </div>
        </section>

      </div>

  );
}
export default Bannerstyle;

//dangerouslySetInnerHTML={{ __html: topContent }} this makes html work somehow, try to avoid using it because it means DANGEEEEERRRR

