import { ContentItem } from "../../types";
import { images, getFilterStyle, Colors, getBgColorStyle, getTextColorStyle, colors } from "../../theme";
import NTPstyle from "../../components/styles/NTPstyle";
import Singlestyle from "../../components/styles/Singlestyle";
import FAQsstyle from "src/components/styles/FAQsstyle";
import Circlestyle from "src/components/styles/Circlestyle";
import Imagepopupstyle from "src/components/styles/Imagepopupstyle";

// const newcustomer = require('../../img/newcustomer.png');
const tariffs = require('../../img/tariffs.png');
// const handglobe = require('../../img/hand-globe.png');

const getintouch = require('../../img/getintouchwith.png');
const heretohelp = require('../../img/heretohelp.png');
const redlight = require('../../img/redlight.png');
const billing_large = require('../../img/billing_large_new.png');

const newcustomer = require('../../img/newcustomer.png');

// const handglobe = require('../img/hand-globe.png');

const photobg2 = require('../../img/2-photo-bg3.jpg');

const katie = require('../../img/katie.jpg');
const handsun = require('../../img/hand-sun.png');
const coinssmall = require('../../img/coinssmall.png');
const logo = require('../../img/ces-logo.png');
const keys2 = require('../../img/ces-key2.png');
const house = require('../../img/illustration.png');
const plug = require('../../img/plug.png');
const ceshouse = require('../../img/ces-house.png');
const community = require('../../img/community.png');
const pig = require('../../img/pig.png');
const people = require('../../img/people.png');

const fair = require('../../img/fair.png');
const match = require('../../img/match.png');
const rebate = require('../../img/rebate.png');

const hello = require('../../img/hello.png');


export const config: ContentItem[] = [
  {
    leftImage: billing_large,
    rightContent: `
      <h1 class="heading heading--large" style="color: #464e51"> 
          Your bill explained 
      </h1> 
      <p style="color: #464e51">
          The Community Energy Scheme will only bill you for the solar energy generated by the panels on your roof and consumed within your home. Any extra energy consumed will come from the grid and will be billed to you by your grid supplier of choice. The energy generated by the panels will always work out cheaper, thanks to the <a href=${`/the_scheme`} style="text-decoration:underline!important;"><span style="font-weight:500;" >price protections</span></a> in your contract.
      </p>    
      <h3 class="heading" style="color: #464e51; margin-top:1em;"> 
          Struggling to pay?
      </h3> 
      <p style="color: #464e51">
          We are here to support you. If you're not up to date with your bill just get in touch. We can help you create a payment plan affordable to you. If you would like to discuss a payment plan, please contact our credit control team on <a href="tel:01782 438391" style="text-decoration:underline!important;"><span style="font-weight:500;" >01782 438391</span></a>.
      </p>    
    `,
    topCurve: images.topcurvedarkgreen,
    topCurveFilter: getFilterStyle(Colors.none),
    component: NTPstyle,
    backgroudColorSection: getBgColorStyle(Colors.lightsky),
    rightContentStyle: getTextColorStyle(Colors.grey),
    bottomCurve: images.asymmetricalCurveBottom,
    bottomCurveFilter: getFilterStyle(Colors.blue),
  },
  {
    leftContent: `
      <p>
         The total of your bill with the Community Energy Scheme is made up of: Solar Energy Consumed, any outstanding amount from your previous bill and Solar rebate.
      </p>
      <p>  
          For information on how to pay check out our <a href=${`/payments`} style="text-decoration:underline!important;"><span style="font-weight:500;">Payments section</span></a>. For frequently asked questions related to payments and billing visit our <a href=${`/faq`} style="text-decoration:underline!important;"><span style="font-weight:500;">Help section</span></a>.
      </p>
      <h4 style="margin-top:2.5em;">
          Click on the highlighted areas on the bill below for details about each item.
      </h4>
    `,
    topCurve: images.asymmetricalCurveTop,
    topCurveFilter: getFilterStyle(Colors.blue),
    backgroudColorSection: getBgColorStyle(Colors.blue),
    leftContentStyle: { marginBottom: "0px" },
    component: Singlestyle,
    useMargin: false,
  },
  {
    component: Imagepopupstyle,
    backgroudColorSection: getBgColorStyle(Colors.blue),
    bottomCurve: images.symmetricalbottomcurvedarkgreen,
    bottomCurveFilter: getFilterStyle(Colors.none),
  }
];

