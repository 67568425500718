import  React , { useState } from 'react';
import '../../index.css';
import { ReactComponent as BellIcon } from '../../icons/bell.svg';
import { ReactComponent as MessengerIcon } from '../../icons/messenger.svg';
import { ReactComponent as CaretIcon } from '../../icons/caret.svg';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ReactComponent as CogIcon } from '../../icons/cog.svg';
import { ReactComponent as ChevronIcon } from '../../icons/chevron.svg';
import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg';
import { ReactComponent as BoltIcon } from '../../icons/bolt.svg';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';


const logo = require('../../img/ces-logo.png');

function NavbarMain() {
  



  return (
    <Navbar>      
      {/* menu items w dropdown  */}
      <NavItem text= "Menu">  
        <DropdownMenu />
      </NavItem>
    </Navbar>
  );
}

//{props.children} controls the text ont that link
//icon-button right we are also creating slots for right icon or left icon on the span line that only will render if we add them

//we give the dropdown menu a state to specify which menu is currently visible
//main menu is default, and show a settings and eg menu as well
//our active menu is the state and set active menu is how we change it 
//now that we have state we can animate elements in and out using css transition

//we need the user to be able to activate a menu - we have setActiveMenu function from useState and we need to bind that to the click event on the drop-own item bottom 
//instead of hardcoding a value we will look for a prop that is passeddown from the parent component and we'll want to make sure that prop exists before we call the set active menu function

//calculate height takes a DOM elemtns as its argument because a DOM element has a property called offset hegiht whichcontains the actual height in pixels of that element

const DropdownMenu = () => {

  const [activeMenu, setActiveMenu] = useState('main');
  
  const [menuHeight, setMenuHeight] = useState('auto');
  
  const calcHeight = (el: any) => {
    const height = el.offsetHeight;
    setMenuHeight(height);
  }

  function DropdownItem(props: any) {


    return (
      <Link style={{lineHeight:"1em"}} to={props.page} className="menu2-item2" onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}>
        {props.leftIcon && <span className="icon2-button2">{props.leftIcon}</span>}
        {props.children}
        {props.goToMenu 
        ? <span className="icon2-button2 icon2-right">{props.rightIcon || null}</span>
        : null}      
      </Link>
    );

  }

  return (

  //we wrap dropdown elements in the CSSransition component
  //this looks for a prop of in which when truth will render and animate its children into the UI, in other words when the active menu equals main it will show the children inside of this component
  //a second property - unmounOnExit will remove these children when they're not active 
  //a timeout will define the duration of the animation
  //the prop classnames will be classnames

  //CSSTransition looks for the first child element which will set up as a div, then when it;s in prop changes it will add or remove css classes to this element based on the state of the animation and it uses the class names prop to prefix those classes 
  
  //CSSTransition doesn't actually animate anything directly, intead it ads and removes classes based on the state on the animation and you handle the animations in CSS


  //===	equal value and equal type

    <div className="dropdown2" style={{ height: "auto" }}>
      
      <CSSTransition 
        in={activeMenu === 'main'} 
        unmountOnExit 
        timeout={500}
        classNames="menu2-primary"
        onEnter={calcHeight}
        >

          <div className="menu2">
          <DropdownItem
    
                page="/">
                  Home
              </DropdownItem>
              <DropdownItem
                rightIcon={<ChevronIcon />}
                goToMenu="whatis">
                  How does it work
              </DropdownItem>
              <DropdownItem
                rightIcon={<ChevronIcon />}
                goToMenu="join">
                  Join today 
              </DropdownItem>
              <DropdownItem
                rightIcon={<ChevronIcon />}
                goToMenu="payments">
                Billing
              </DropdownItem>  
              <DropdownItem
                rightIcon={<ChevronIcon />}
                goToMenu="help">
                  Help
              </DropdownItem>

          </div>

      </CSSTransition> 

      <CSSTransition 
        in={activeMenu === 'whatis'} 
        unmountOnExit 
        timeout={500}
        classNames="menu2-secondary"
        onEnter={calcHeight}        
        >
            <div className="menu2">
                <DropdownItem goToMenu="main" leftIcon={<ArrowIcon />}>
                  Go back
                </DropdownItem>
                <DropdownItem page="/the_scheme" >The Scheme</DropdownItem>
                {/* <DropdownItem>X Calulate your savings</DropdownItem> */}
                          <DropdownItem page="/the_process">The Process</DropdownItem>     
                          <DropdownItem page="/testimonials">Testimonials</DropdownItem>     
            </div>
      </CSSTransition> 

      <CSSTransition 
        in={activeMenu === 'join'} 
        unmountOnExit 
        timeout={500}
        classNames="menu2-secondary"
        onEnter={calcHeight}        
        >
            <div className="menu2">
                <DropdownItem goToMenu="main" leftIcon={<ArrowIcon />}>
                  Go back
                </DropdownItem>

                <DropdownItem page="/joining">Joining options</DropdownItem>

                <DropdownItem page="/sign_up">Sign up online</DropdownItem>
            </div>
      </CSSTransition> 

      <CSSTransition 
        in={activeMenu === 'payments'} 
        unmountOnExit 
        timeout={500}
        classNames="menu2-secondary"
        onEnter={calcHeight}        
        >
            <div className="menu">
                <DropdownItem goToMenu="main" leftIcon={<ArrowIcon />}>
                  Go back
                </DropdownItem>
                <DropdownItem page="/billing">Your bill explained</DropdownItem>
                <DropdownItem page="/payments">Ways to pay</DropdownItem>
                <DropdownItem page="/paperless">Go paperless</DropdownItem>
            </div>
      </CSSTransition> 

      <CSSTransition 
        in={activeMenu === 'help'} 
        unmountOnExit 
        timeout={500}
        classNames="menu2-secondary"
        onEnter={calcHeight}        
        >
            <div className="menu2">
                <DropdownItem goToMenu="main" leftIcon={<ArrowIcon />}>
                  Go back
                </DropdownItem>

                <DropdownItem page="/tips">Energy Tips</DropdownItem>

                <DropdownItem page="/videos">Video Tutorials</DropdownItem>

               

                <DropdownItem page="/PPA">Scheme PPA, TVA, leaflets and booklets</DropdownItem>

                <DropdownItem page="/faq">FAQs</DropdownItem>
                 
                <DropdownItem page="/contact">Contact us</DropdownItem>

                
            </div>
      </CSSTransition>       
    </div>
  );
}

function Navbar(props: any) {  //props pass data from parent to children

  const home_special_style = location.pathname === '/' ? {
    backgroundColor: '#00503d' } : undefined;

  console.log('I AM HERE', location.pathname === '/', home_special_style)

  return (
    <nav className="navbar2" style={home_special_style}>
      
      <img src={logo} style={{
          height: '80%',
          float: 'left',
          marginLeft: 30,
            marginTop: 10,
            cursor: 'pointer',
      }} onClick={() => window.location.href="/" } />
      <ul className="navbar2-nav2">{props.children}</ul>
    </nav>
  );
}

function NavItem(props: any) {

  //**************props: any ?? what is this??? */

  //state = data that changes throughout the life cycle of the Navbar
  //useStata is a hook that manages state
  //when we call the useState function it returns 2 values in an array that can be destructures as variables using brakets
  //the first value is the current state, in this case 'open' which is a boolean value telling if open
  //the second value is a function that we can use to change the state
  //the bit inside the () indicase the default, in this case it is closed by default

  //ref line 58 onClick={() => setOpen(!open)}
  //onClick is an event handler that changes the value when the user clicks a button
  //it will get use the setOpen function that we got from useState adn it will flip the open value to whatever the oposite is 
  // the excamation mark ! in front of a booleon gives you its opposite value (!open)
  //onClick={() => setOpen(!open)} in this case onClick is the event and setOpen is the event handler
  
  const [open, setOpen] = useState(false);
  
    //note: when using && in JSX, the operand behaves differently. the right only hNavbarens if the left is true.
      // if the open state is true it will show props.children, if not nothing will be shown

  return (
    <>
    <li className="nav2-item2">

      <a href="#" className="icon2-button2" onClick={() => setOpen(!open)}>
        {props.icon}
        {props.text}        
      </a>
      {open && props.children}
    </li>
    <div 

      style={{
        display: open ? 'block' : 'none', 
        position: 'absolute',
        width: '100%',
        height: '100vh',
        top: 0,
        left: 0,
        zIndex: -1,
      }}
      onClick={() => setOpen(false)}
    />
    </>
  );
}

export default NavbarMain; 

