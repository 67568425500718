import { ContentItem, FAQcategory } from "../../types";
import { images, getFilterStyle, Colors, getBgColorStyle, getTextColorStyle, colors } from "../../theme";
import NTPstyle from "../../components/styles/NTPstyle";
import Helpstyle from "src/components/styles/Helpstyle";
import Narrowstyle from "../../components/styles/Narrow";
import FAQsstyle from "../../components/styles/FAQsstyle";
import { Link } from 'react-router-dom';

// const newcustomer = require('../../img/newcustomer.png');
const tariffs = require('../../img/tariffs.png');
// const handglobe = require('../../img/hand-globe.png');

const getintouch = require('../../img/getintouchwith.png');
const billing = require('../../img/billing.png');
const newcustomer = require('../../img/newcustomer.png');
const supplier = require('../../img/grid.png');
const meter = require('../../img/meter.png');
const complaints = require('../../img/complaints.png');
const heretohelp = require('../../img/heretohelp.png');
const misc = require('../../img/havingalookaround.png');



export const config: ContentItem[] = [
  {
    leftImage: getintouch,
    rightContent: `<h1 class="heading heading--large" > Got a question? Chances are, we’ve got the answer.</h1>
  `,
    topCurve: images.topcurvedarkgreen,
    topCurveFilter: getFilterStyle(Colors.none),
    component: Narrowstyle,
    backgroudColorSection: getBgColorStyle(Colors.teal),
    rightContentStyle: getTextColorStyle(Colors.grey),
  },
  {
    leftImage: tariffs,
    component: Helpstyle,
    backgroudColorSection: getBgColorStyle(Colors.teal),
    bottomCurve: images.symmetricalCurveBottom,
    bottomCurveFilter: getFilterStyle(Colors.lilac),
    help: [
      {
        helpImage: billing,
        helpCategory: "Billing",
        helpLink: "/faq",
        faqCategory: FAQcategory.BILLING,
      },
      {
        helpImage: newcustomer,
        helpCategory: "Scheme",
        helpLink: "/faq",
        faqCategory: FAQcategory.SCHEME,
      },
      {
        helpImage: supplier,
        helpCategory: "Supplier",
        helpLink: "/faq",
        faqCategory: FAQcategory.SUPPLIER,
      },
      {
        helpImage: meter,
        helpCategory: "System",
        helpLink: "/faq",
        faqCategory: FAQcategory.SYSTEM,
      },
      {
        helpImage: complaints,
        helpCategory: "Advice",
        helpLink: "/faq",
        faqCategory: FAQcategory.COMPLAINTS,
      },
      {
        helpImage: misc,
        helpCategory: "Other",
        helpLink: "/faq",
        faqCategory: FAQcategory.MISC,

      },
    ],
  },

  {
    leftContent: `<h1 class="heading">Sghsdrjsry £££s with the Community Energy Scheme.</h1>
    <p>Call us on <a href="tel:01782438427" style="text-decoration: underline !important"><span style="font-weight: 500">01782 438 427</span></a> now to book your appointment and watch the savings add up.</p>`,
    rightContent: `<h1 class="heading">Sghsdrjsry £££s with the Community Energy Scheme.</h1>
    <p>Call us on <a href="tel:01782438427" style="text-decoration: underline !important"><span style="font-weight: 500">01782 438 427</span></a> now to book your appointment and watch the savings add up.</p>`,
    // topCurve: images.asymmetricalCurveTop,
    // topCurveFilter: getFilterStyle(Colors.pink),
    component: FAQsstyle,
    backgroudColorSection: getBgColorStyle(Colors.lilac),
    bottomCurve: images.asymmetricalCurveBottom,
    bottomCurveFilter: getFilterStyle(Colors.orange),
    leftContentStyle: getTextColorStyle(Colors.white),
    rightContentStyle: getTextColorStyle(Colors.white),
    useMargin: false,
    faqs: [
      {
        category: [FAQcategory.SCHEME],
        question: "How does the Community Energy Scheme work?",
        answer: "The Community Energy scheme was developed to help people save money, reduce their carbon footprint & by enabling occupants to  use energy efficiently in their homes. Our community energy scheme installs solar panels for FREE on a council tenant’s property. The renewable energy produced from these panels is then available to purchase by the tenants at our fair market price – a lower price. In fact, the scheme guarantees our customers that they will always pay less than the average tariff charged by the big energy firms which means by using the energy from the panels the tenant PAYS LESS. Those who sign up also receive free LED light bulbs to help reduce their energy use and an intelligent sub meter placed alongside the smart meter from their grid energy provider, meaning they only pay for what they use. Everyone who signs up pays the same rate. So far. Thousands of households in Stoke have joined the scheme to help save money and make a positive contribution to saving the planet from the effects climate change.      "
      },
      {
        category: [FAQcategory.SCHEME],
        question: "Does Stoke council support the scheme?",
        answer: "Yes. Stoke Council and Community Energy have come together for this ground-breaking scheme. The reason for this collaboration is to build a sustainable city whilst saving tenants money. Together we are fighting fuel poverty numbers in Stoke in a way that is sustainable for our residents, the council itself and for the Community Energy Scheme. This is not a quick fix energy scheme aimed purely at attracting customers – we are here for the long run and believe in building a better Stoke. "
      },
      {
        category: [FAQcategory.SCHEME],
        question: "What is different about the Community Energy Scheme?",
        answer: "The Community Energy Scheme uses the Solar Panels we install on the Council properties to supply the houses they are installed on with Renewable Energy generated from the sun. This enables the scheme to provide the household with electricity at a price which is much cheaper than they would pay from an energy supplier. We do this by investing in large quantities of Solar Panels in advance, at the most competitive price, which therefore enables us to supply your home with energy at the best possible price."
      },
      {
        category: [FAQcategory.SYSTEM],
        question: "How do I know if I am using the solar energy",
        answer: "The Solar PV system in your house is electrically connected to the same wiring as your Grid suppliers meter so as long as the sun is shining and the PV is generating you will use this energy first which is good news as the energy from the PV costs you less than that supplied from the grid. So the more you use the more you save.  If you switch on more appliances than the PV energy can cover they will still continue to work but some of the electricity will now come from your grid supplier which is charged at a higher rate. The important thing to remember is you are NOT paying twice so using the PV energy will ALWAYS make your bills cheaper than using the same amount of energy just from the GRID.  "
      },
      {
        category: [FAQcategory.SYSTEM],
        question: "How long will the solar panels last for",
        answer: "Solar PV panels have no moving parts and as such have very long life spans and the panels installed on your property should last for at least 25 years. "
      },
      {
        category: [FAQcategory.SYSTEM],
        question: "Will my solar panels work if there is a power cut",
        answer: "If there is a power cut your inverter will shut down automatically which stops any energy being produced and fed back into the property. This is a safety feature of the system to prevent you feeding energy back out onto the grid where someone may be working to restore supplies. "
      },
      {
        category: [FAQcategory.SYSTEM],
        question: "Is there any danger of the panels being blown off in a storm",
        answer: "No, all systems are installed in accordance with industry recommendations and are securely fixed using a proprietary hook and rail system. We have been installing solar panels on thousands of homes for over a decade and never had a panel blown off."
      },
      {
        category: [FAQcategory.SYSTEM],
        question: "How do the Solar Panels work",
        answer: "The panels on your roof convert daylight into electrical energy which is connected to your electrical installation through the inverter and our PV meter. This means when the system is generating you can use the energy from the system in your home  in the same way as the electricity from your grid supplier  "
      },
      {
        category: [FAQcategory.SYSTEM],
        question: "What do I do if my system goes off",
        answer: "In the event that your system stops working you should call the customer service team on 01782438427 the team will be able to tell you if you can reset this yourself  and will guide you through this process ,or alternatively f you need a service visit they will arrange this for you. "
      },
      {
        category: [FAQcategory.SYSTEM],
        question: "Do my panels need to be cleaned",
        answer: "Solar panels are generally self cleaning through normal rainfall, however if you think that there is an unusual amount of dirt or bird droppings on the panels you should call the customer service team who will arrange for our maintenance team to attend on 01782 438427"
      },
      {
        category: [FAQcategory.MISC],
        question: "How do I claim my free LED light bulbs?",
        answer: "As part of our drive to lower energy bills and help the environment, Community Energy offers free LED light bulbs to members of the Community Energy Scheme in Stoke. All members need to do is get in touch with our friendly Customer Service team on 01782 438 427. We will arrange to have your LED light bulbs delivered to your doorstep. You can also follow this link to order your free LED bulbs - https://www.communityenergyscheme.com/stoke-led or alternatively fill out the the LED form in your CES booklet which you will have been given when you signed up. To qualify for our free LED bulbs you will either need to be fully up to date with your payments or a payment plan/direct debit must be in place. Any LED orders which do not meet these requirements will not be placed. "
      },
      {
        category: [FAQcategory.SCHEME],
        question: "Am i helping the environment",
        answer: "Using energy generated from the sun has many environmental benefits and by maximising your usage of the PV energy you are contributing to the reduction in harmful green house gases. This technology enables you to reduce your carbon footprint. Typically in a year a PV system will save 1 tonne of carbon emission which is the equivalent of 25 trees "
      },
      {
        category: [FAQcategory.SUPPLIER],
        question: "What happens if I switch grid suppliers?",
        answer: "Whichever supplier you use, our solar panels will continue to generate and supply renewable energy to your home, reducing the amount of electricity you use from the grid. This means that we will still send you a monthly bill for the solar electricity you use. However, our Price Match Promise means that you will be paying the same price, or cheaper, for the electricity you use from your solar panels as you do for the electricity supplied to you by your grid supplier. Once your switch has been successful we would advise you to contact the supplier and let them know about your solar panels"
      },
      {
        category: [FAQcategory.BILLING],
        question: "What should I do if I have not got my energy bill this month?",
        answer: "Invoices are sent out monthly to our customers, if we have your email address.  If we do not have your up to date contact details please contact Customer Service Team.  on 01782 438 427 or email us at stoke@communityenergyscheme.com and we will send your bill straight out to you. Invoices sent out in the post are sent out together on a quarterly basis.  "
      },
      {
        category: [FAQcategory.BILLING],
        question: "What if I cant pay my bill?",
        answer: "Contact our Credit Control Team on 01782 438391 or cc@communityenergyscheme.com where the team can look into an affordable payment plan for you. We will work together to ensure you are able to make monthly payments which are also financially suitable to you. If you do have a payment plan in place or direct debit set up with us, you are entitled to a monthly rebate."
      },
      {
        category: [FAQcategory.BILLING],
        question: "How will you measure  my consumption?",
        answer: "Our meter communicates to us three important bits of information, the amount of energy the Solar panels Generate, the amount of energy which is generated that you benefit from (your consumption) and the amount of energy which you do not consume, which is sent back into the electricity grid. You will only be billed for the energy you consume which will mean you use less energy from your grid supplier."
      },
      {
        category: [FAQcategory.BILLING],
        question: "How do I read my solar meter?",
        answer: "We have created a helpful video which shows you how to read your solar meter! Please follow this link where you will be able to watch - https://youtu.be/NOJj5yN7mI4"
      },
      {
        category: [FAQcategory.BILLING],
        question: "What do I do if the meter read on my bill is incorrect?",
        answer: "Let our customer service team know on 01782 438 427, they will look into your meter reading and will book an appointment for an engineer to attend if necessary.  "
      },
      {
        category: [FAQcategory.BILLING],
        question: "What if I overpay?",
        answer: "If you think your have overpaid on your energy, please contact us on email at cc@communityenergyscheme.com so we can review your account with you.  If you have overpaid a refund will be issued upon your request."
      },
      {
        category: [FAQcategory.BILLING],
        question: "How do I make a payment?",
        answer: `We have a number of ways for you to pay your bill:
        By phone just call 01782 438391
        
        Direct Debit - you can set this up over the phone or we can send a paper mandate.
        
        By Card – Online, please follow the link on your invoice which will send you to the payment portal
        
        Online Banking – 
        Account Name:      Community Energy Scheme Stoke Ltd
        Sort Code:                23-18-84
        Account Number:  64496353
        Please use your customer reference number beginning with STO 
        
        Cheque – Please make cheques payable to Community Energy Scheme Stoke Ltd and send to Unit 8 Peerglow Centre, Marsh Lane, Ware , Herts, SG12 9QL`
      },
      {
        category: [FAQcategory.BILLING],
        question: "Can I set up a payment plan to pay my outstanding balance?",
        answer: "Yes, we can look at a payment plan which will pay your outstanding balance as well as your usage going forward over a period of time, please contact our Credit Control Team on 01782 438391  or cc@communityenergyscheme.com and they can help work through this with you.  "
      },
      {
        category: [FAQcategory.BILLING],
        question: "Can I set up a direct debit?",
        answer: "Yes, you can set up a monthly direct debit, please contact our credit control team on 01782 438391 where they can set this up for you or alternatively ask for a direct debit mandate form which we can post to your address or email across to you"
      },
      {
        category: [FAQcategory.BILLING],
        question: "What is the Community Energy Scheme rebate?",
        answer: "As a member of the Community Energy Scheme, if you are making regular payments on your account, or your account is clear on an annual basis each December, your rebate will automatically be added to your account each month.  Community Energy Scheme rebate is shown on your invoices. If your account is in arrears and no payment plan is set up, the annual rebate will be charged back to you."
      },
      {
        category: [FAQcategory.BILLING],
        question: "I am in credit and would like a refund?",
        answer: "If you are in credit on your account and would like a refund, please request this in writing by sending an email to CC@communityenergyscheme.com, so your account can be reviewed and a refund issued"
      },
      {
        category: [FAQcategory.BILLING],
        question: "My bill is in dispute, do I still need to pay?",
        answer: "Yes, however if you have a valid dispute, please provide us with all the information as to why, so we can investigate and rectify as soon as possible."
      },
      {
        category: [FAQcategory.BILLING],
        question: "I’ve been trying to contact you and I can’t get through to anyone to make a payment?",
        answer: `We are sorry to hear you have been having issues in contacting us. Our team are here to take your call between the hours of 8:30am and 17:30pm.
        If we miss your call we will endeavour to get back to you at the earliest opportunity.
        Please remember you can make a payment online, 24 hours a day by following the link on your invoice or on your reminder email.`
      },
      {
        category: [FAQcategory.BILLING],
        question: "Why is my bill estimated?",
        answer: "If you have an old style meter your bills will be estimated. Please contact us to arrange a meter exchange to a meter that we can read remotely, which will in turn give you more accurate bills."
      },
      {
        category: [FAQcategory.BILLING],
        question: "I had an engineer visit my property, and after checking my bills informed me I was billed incorrectly. Do I get a new invoice with correct amount ?",
        answer: "Once a visit is complete, a report is generated and sent to accounts for review. If it is found that you were billed incorrectly, a credit note will be sent out to you via email or post.  "
      },
      {
        category: [FAQcategory.BILLING],
        question: "I have paid, but it is not showing on my account?",
        answer: "If you have made a payment to us and it is not reflected on your statement, please contact us and we will look into this for you. Please send us an email to CC@communityenergyscheme.com stating the amount, date, method of payment and any reference you entered, then we can locate the payment and allocate to your account."
      },
      {
        category: [FAQcategory.COMPLAINTS],
        question: "How do I make a complaint?",
        answer: "Contact our Customer Service Team on 01782 438 427 or stoke@communityenergyscheme.com who will log your complaint on our system and we will look to resolve this within the next 10 working days"
      },
      {
        category: [FAQcategory.COMPLAINTS],
        question: "What if my complaint takes to long to resolve?",
        answer: "If you feel your complaint has not been handled correctly or you have not heard from us within 10 working days of your complaint being logged you can contact Citizens advice on 0344 411 1444"
      },
    ],
    cardColor: colors[Colors.teal],
  },



];