import { ContentItem } from "../../types";
import { images, getFilterStyle, colors, Colors, getBgColorStyle, getTextColorStyle } from "../../theme";
import NTPstyle from "../../components/styles/NTPstyle";
import Imagestyle from "../../components/styles/Imagestyle";
import Formstyle from "../../components/styles/Formstyle";
import Circlestyle from "../../components/styles/Circlestyle";
import Buttonstyle from "../../components/styles/Buttonstyle";
import Bannerstyle from "../../components/styles/Bannerstyle";
import Narrowstyle from "../../components/styles/Narrow";
import Singlestyle from "../../components/styles/Singlestyle";
import Narrowstyle2 from "../../components/styles/Narrow2";

const videoSource = "https://www.w3schools.com/tags/movie.mp4"

const clouds = require('../../img/video.mp4');

const newcustomer = require('../../img/newcustomer.png');
const tariffs = require('../../img/tariffs.png');
// const handglobe = require('../img/hand-globe.png');

const photobg2 = require('../../img/2-photo-bg2.jpg');

const katie = require('../../img/katie.jpg');
const handsun = require('../../img/hand-sun.png');
const coinssmall = require('../../img/coinssmall.png');
const logo = require('../../img/ces-logo.png');
const keys2 = require('../../img/ces-key2.png');
const house2 = require('../../img/house2.png');
const plug2 = require('../../img/plug.png');
const ceshouse = require('../../img/ces-house.png');
const community = require('../../img/community.png');
const pig = require('../../img/pig.png');
const people = require('../../img/people.png');

const fair = require('../../img/fair.png');
const match = require('../../img/match.png');
const rebate = require('../../img/rebate.png');

const hello = require('../../img/hello.png');
const directdebit = require('../../img/directdebit-1.png');
const talk = require('../../img/talk.png');
const grid = require('../../img/grid.png');
const meter = require('../../img/meter-1.png');
const led = require('../../img/led.png');
const family = require('../../img/family.png');
const phone = require('../../img/phone.png');

export const config: ContentItem[] = [

  {
    leftContent: `	 <h1 class="heading heading--large">Privacy Policy</h1>

    <p><p class="p3">This privacy policy sets out how <span class="s1">communityenergyscheme.com</span> uses and protects any information that you give <span class="s1">communityenergyscheme</span><span class="s1">.com</span> when you use this website.</p>
<p class="p3">We are committed to providing you with a high standard of customer service, throughout your time with the Community Energy Scheme</p>
<p class="p3"><span class="s1">communityenergyscheme.com</span> is committed to ensuring that your privacy is protected. Should we ask for you to give us any information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement.</p>
<p class="p3"><span class="s1">communityenergyscheme.com</span> may change this policy from time to time by updating this page, so do check this page regularly to ensure that you understand any changes. This policy is effective from 2nd July 2018.</p>
<p class="p9"><strong>SCOPE <span class="Apple-converted-space">   </span></strong></p>
<p><span style="font-weight: 400;">All data subjects whose data is processed by the Community Energy Scheme. This includes all customers that we provide services to, all potential customers who may be interested in our products and services and all business partners that assist us in the provision of our services.    </span></p>
<p class="p11"><span style="font-weight: 400;">For the purposes of data protection law, the “controller” is  the Community Energy Scheme. </span></p>
<p class="p9"><strong>RESPONSIBILITIES <span class="Apple-converted-space">   </span></strong></p>
<p><span style="font-weight: 400;">The Data Protection Officer is responsible for ensuring that this notice is placed in front of potential data subjects prior to the Community Energy Scheme collecting/processing their personal data. All Employees/Staff of the Community Energy Scheme who interact with data subjects are responsible for ensuring that this notice is drawn to the data subject’s attention and their consent, if applicable, to the processing of their data is secured. We are committed to providing you with a high standard of customer service, throughout your time with the Community Energy Scheme.</span></p>
<p class="p9"><strong>PRIVACY NOTICE</strong></p>
<p><span style="font-weight: 400;">We take your privacy very seriously. Please read this privacy notice carefully: it contains important information about</span></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">who we are </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">how and why we collect, store, use and share personal data, including any data you may provide when you sign up to our newsletter, purchase a product or service or take part in a competition </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">your rights in relation to your personal data </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">how to contact us.  </span></li>
</ul>
<p><span style="font-weight: 400;">Please note that this website is not intended for children and we do not knowingly collect data relating to children. </span></p>
<p class="p21"><strong>What we collect <span class="Apple-converted-space">   </span></strong></p>
<p><span style="font-weight: 400;">We collect information about you when you register with us or place an order for products or services. We also collect information when you voluntarily complete customer surveys, provide feedback to us and participate in competitions with us. Website usage information is also collected using cookies on the Community Energy Scheme and during our business functions – namely, marketing, providing utilities and selling products for use – we collect the following personal data when you provide it to us:  </span></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Your name    </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Your contact information including email address and telephone number    </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Your postcode and number of bedrooms within your household for generating  accurate estimations </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Your current gas and electricity supplier for comparative purposes    </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Your preferences and interests    </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Any other information relevant to customer surveys and/or offers    </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Any other information necessary to meet our contractual obligations or provision of a better service to meet your requirements </span></li>
</ul>
<p><b>We do not collect “special category” personal data. </b><span style="font-weight: 400;">This is a special type of sensitive data</span> <span style="font-weight: 400;">to which more stringent processing conditions apply, and comprises data concerning your racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, health, sex life or sexual orientation, and genetic data and/or biometric data. </span></p>
<p><span style="font-weight: 400;">We also do not collect information about criminal convictions or offences.  </span></p>
<p><span style="font-weight: 400;">We may monitor and record communications with you (including phone conversations and emails) for quality assurance and to make sure that we are meeting our legal and regulatory requirements. We may also use these recordings for training purposes. </span></p>
<p><strong>How we collect personal data</strong></p>
<p><span style="font-weight: 400;">We obtain personal data from sources as follows: </span></p>
<ul>
<li><span style="font-weight: 400;">directly from you when you interact with us (for example, when you create an account order products or services , subscribe to mailing lists, request information, enter a competition or provide feedback </span></li>
<li><span style="font-weight: 400;">from customers, if they provide your details in relation to an order (for example, switching suppliers) </span></li>
<li><span style="font-weight: 400;">from automated technologies when you use our website </span></li>
<li><span style="font-weight: 400;">from suppliers who may deliver products to you, if they collect personal data from you in addition to the personal data that we collect </span></li>
<li><span style="font-weight: 400;">from third parties with whom we have a trading relationship</span></li>
</ul>
<p><span style="font-weight: 400;">We may monitor and record communications with you (including phone conversations and emails) for quality assurance and to make sure that we are meeting our legal and regulatory requirements. We may also use these recordings for training purposes.    </span></p>
<p><b>How we use the Information    </b></p>
<p><span style="font-weight: 400;"> the Community Energy Scheme will process – that means collect, store and use – the information you provide in a manner that is compatible with the EU’s General Data Protection Regulation (GDPR). We will endeavour to keep your information accurate and up to date and not keep it for longer than is necessary. In some instances, the law sets the length of time information has to be kept, but in most cases the Community Energy Scheme will use its discretion to ensure that we do not keep records outside of our normal business requirements.    </span></p>
<p><span style="font-weight: 400;">Our aim is not to be intrusive, and we undertake not to ask irrelevant or unnecessary questions. Moreover, the information you provide will be subject to rigorous measures and procedures to minimise the risk of unauthorised access or disclosure.    </span></p>
<p><span style="font-weight: 400;"> the Community Energy Scheme may use this information to understand your needs and provide you with a better service. This could mean:    </span></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Internal record keeping.    </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;"> the Community Energy Scheme may use the information to improve the website.    </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;"> the Community Energy Scheme may occasionally send emails about other information which we think you may find interesting using the email address which you have provided. To provide you with an accurate quotation.    </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">To correctly set you up as a customer and to provide you with your energy supply.    </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">To administer your account, including informing you of any changes.    </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">To carry out risk assessment, credit-referencing and fraud prevention.    </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">To remind you about the quotations we’ve provided you with for a certain period after having visited our site.    </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">To undertake market research with a view to better understanding the requirements of our customers.    </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Most importantly, to generally provide the best service to you that we can.    </span></li>
</ul>
<p><span style="font-weight: 400;">We may also contact credit reference agencies who will record the search. If we intend to use information for any other purposes, we’ll tell you when we collect it and you’ll have at least 28 days to object.</span></p>
<p><b>Why do we need to collect, process and store personal data?</b></p>
<p><span style="font-weight: 400;">For us to provide you with a product or service we need to collect personal data for correspondence purposes and/or detailed service provision. In any event, we are committed to ensuring that the information we collect, and use is appropriate for this purpose, and does not constitute an invasion of your privacy. We may pass your personal data on to our service providers who are contracted to the Community Energy Scheme in the course of service provision. Our contractors are obliged to keep your details securely and use them only to fulfil the service they provide you on our behalf. Once your service need has been satisfied or the case has been closed, they will dispose of the details in line with the Community Energy Scheme’s procedures. If we wish to pass your sensitive personal data onto a third party, we will only do so once we have obtained your consent unless we are legally required to do so.</span></p>
<p><span style="font-weight: 400;">We will only use your personal data when the law allows us to. Most commonly, we will use personal data in the following circumstances: </span></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">to perform a contract, we are about to enter or have entered into with you </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">if it is necessary for our legitimate interests (or those of a third party) and these are not overridden by your rights and interests </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">where we need to comply with a legal or regulatory obligation. </span></li>
</ul>
<p><span style="font-weight: 400;">In order to process personal data, we must have a lawful reason. We always ensure that this is the case, and we set out our lawful bases below – note that more than one may apply: for example, if we inform you of changes to our privacy notice, we may process your personal data on the ground of complying with law and on the ground of legitimate interests.  </span></p>
<p><span style="font-weight: 400;">We will only use your personal data for the purposes for which we collected it, unless we fairly consider that we need it for another reason that is compatible with the original purpose. </span></p>
<p><b>What are the lawful bases for processing?</b></p>
<p><span style="font-weight: 400;">The lawful bases for processing are set out in Article 6 of the GDPR. At least one of these must apply whenever you process personal data:             </span></p>
<ul>
<li><b>Consent: <span style="font-weight: 400;">the individual has given clear consent for you to process their personal data for a specific purpose.  </span></b></li>
<li><b>Contract: <span style="font-weight: 400;">the processing is necessary for a contract you have with the individual, or because they have asked you to take specific steps before entering into a contract.  </span></b></li>
<li>Legal obligation: <span style="font-weight: 400;">the processing is necessary for you to comply with the law (not including contractual obligations).  </span></li>
<li>Vital interests: <span style="font-weight: 400;">the processing is necessary to protect someone’s life.  </span></li>
<li>Public task: <span style="font-weight: 400;">the processing is necessary for you to perform a task in the public interest or for your official functions, and the task or function has a clear basis in law.  </span></li>
<li>Legitimate interests: <span style="font-weight: 400;">the processing is necessary for your legitimate interests or the legitimate interests of a third party unless there is a good reason to protect the individual’s personal data which overrides those legitimate interests. (This cannot apply if you are a public authority processing data to perform your official tasks.)</span></li>
</ul>
<p><span style="font-weight: 400;">The primary reason for collecting and processing data will be contractual, however we may utilise a combination of the above, dependent upon the circumstances. We will be transparent in any interactions and ensure that the lawful basis of processing for each circumstance are documented and available.   </span></p>
<p><b>Contractual Necessity </b></p>
<p><span style="font-weight: 400;">If you are our customer, we will process your personal data for the following purposes, on the legal basis that it is necessary for us to provide our products and services to you: </span></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">to identify you </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">to respond to your inquiries  </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">to the extent necessary to provide pre-contractual information about our products and services </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">to provide our products and services, including enabling them to be delivered to you </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">to carry out billing and administration activities. </span></li>
</ul>
<p><span style="font-weight: 400;">Accordingly, your failure to provide such personal data may hinder or prevent us from being able to perform a contract for you. </span><b> </b></p>
<p><b>Legitimate Interests </b></p>
<p><span style="font-weight: 400;">We process your personal information for our legitimate business purposes, which include the following: </span></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">to conduct and manage our business </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">to enable us to carry out our services </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">to ensure our website and systems are secure </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">to improve and update our services for the benefit of our customers </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">to let you know about our products or services that we consider may be of interest to you: we carry out this processing on the legal basis that we have a legitimate interest in marketing our products and services, and only to the extent that we are permitted to do so by applicable direct marketing laws. Please see section below for further information about our marketing activities and regarding your right to opt out. </span></li>
</ul>
<p><span style="font-weight: 400;">Whenever we process your personal data for these purposes, we ensure that your interests, rights and freedoms are carefully considered.  </span></p>
<p><b>Compliance with laws  </b></p>
<p><span style="font-weight: 400;">We may process your personal data in order to comply with applicable laws (for example, if we are required to co-operate with an investigation pursuant to a court order). </span></p>
<p><b>Consent </b></p>
<p><span style="font-weight: 400;">Generally we do not rely on consent as a legal basis for processing your personal data other than in relation to sending marketing communications to you via e-mail (or text message). You have the right to withdraw consent to marketing at any time by contacting us.  </span></p>
<p><span style="font-weight: 400;">We will always be clear whenever we intend to process on the basis of consent, and we will process lawfully and only for the purpose for which consent was given. </span></p>
<p><b>Do we share your personal data? </b></p>
<p><span style="font-weight: 400;">We are legally obliged to share data with Government agencies and regulatory bodies which may involve elements of personal data. </span></p>
<p><b>Do you have to supply your personal data and if so WHY? </b></p>
<p><span style="font-weight: 400;">In short, the answer is no: however, it may affect your ability to receive our products and services if you do not do so. </span></p>
<p><span style="font-weight: 400;">To form a contract with you, we will need some or all the personal data described above so that we can perform that contract or the steps that lead up to it: this is set out above in this notice. If we do not receive the data, the contract could not be performed. </span></p>
<p><span style="font-weight: 400;">If you sign up to our mailing list, you will have to provide certain personal data. Of course, you may decide to stop receiving our mailings at any time. </span></p>
<p><b>How long will your personal data be kept for? </b></p>
<p><span style="font-weight: 400;">We carefully consider the personal data that we store, and we will not keep your information in a form which identifies you for longer than is necessary for the purposes set out in this notice.  </span></p>
<p><span style="font-weight: 400;">We use the following criteria to determine data retention periods for your personal data:  </span></p>
<ul>
<li><b>Retention in case of queries. </b><span style="font-weight: 400;">We will retain your personal data as long as is necessary to deal with your queries. </span></li>
<li><b>Retention for providing products or services. </b><span style="font-weight: 400;">We will retain your personal data as long as is necessary for us to provide relevant products and services.</span></li>
<li><b>Retention in case of claims. </b><span style="font-weight: 400;">We will retain your personal data for as long as we may require it to defend claims that may be brought against us or our customers.</span></li>
<li><b>Retention in accordance with legal and regulatory requirements. </b><span style="font-weight: 400;">We will retain your personal data after we have provided products or services based on our legal and regulatory requirements: for example, UK tax law currently specifies a six-year period for retention of some of your personal data.</span></li>
</ul>
<p><b>What is Personal Data</b></p>
<p><span style="font-weight: 400;">Under the EU’s General Data Protection Regulation: Personal Data is defined as “any information relating to an identified or identifiable natural person (&#8216;data subject&#8217;); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person”.</span></p>
<p><b>Online Identifiers</b></p>
<p><span style="font-weight: 400;"> IP Addresses and Cookies    </span></p>
<p><span style="font-weight: 400;">Cookies are files that are recorded in temporary Internet folders on your PC. They’re a useful tool because they enable us to better understand how people use our website. This in turns means we can continually improve your online experience on the <span class="s1">Community Energy Scheme</span> websites. We use cookies to help analyse web traffic. These small text files let you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.    </span></p>
<p><span style="font-weight: 400;"><span class="s1">communityenergyscheme</span>.com use traffic log cookies to identify which pages are being used. This helps us analyse data about web page traffic and improve our website in order to tailor it to website users’ needs. <span class="s1">communityenergyscheme</span>.com only uses this information for statistical analysis.     </span></p>
<p><span style="font-weight: 400;">Overall, cookies help <span class="s1">communityenergyscheme</span>.com to provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.    </span></p>
<p><span style="font-weight: 400;">You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website. Please also bear in mind that if you do block cookies, you may negatively impact the experience of some pages on the site, particularly the ‘quotation’ engine which relies on cookies to ensure it functions as it should. We use cookies on this site to track users who have visited the quotations page to monitor conversion rates from quote to sign up. We also use cookies for all aspects of our online account management service.  Alternatively, you may wish to visit www.aboutcookies.org which contains comprehensive information on how to remove cookies on a wide variety of browsers. You will also find details on how to delete cookies from your computer as well as more general information about cookies. For information on how to do this on the browser of your mobile phone you will need to refer to your handset manual. </span></p>
<p><span style="font-weight: 400;">  </span><span style="font-weight: 400;">We collect the information requested by any of our web forms as well as the information you provide for the ‘Get a Quote’ facility on our website. We also collect information about how people use our website via Google analytics. This is so we can continually improve the online customer experience.</span></p>
<p><span style="font-weight: 400;">We may occasionally use a third party advertising service which collects information on your IP address via a smart pixel in order to retarget you with relevant advertising. To do this we work with a partner called adroll. You can find out more about cookies below and at www.aboutcookies.org.</span></p>
<p><b>DATA PROTECTION PRINCIPLES    </b></p>
<p>We handle any information you provide us responsibly and securely. The Community Energy Scheme complies with the Data Protection Act 2018 and EU General Data Protection Regulations (GDPR). We collect, store and use personal data carefully, and ensure that it is kept secure. We do not, and will not, pass the information that you give us to any organisations except our agents and relevant industry service providers unless you expressly give us your permission to do so.</p>
<p>To comply with the law, information must be collected and used fairly, stored safely and not disclosed to any other person unlawfully. This means we must comply with the Data Protection Principles set out in the Data Protection Act 2018 and GDPR.</p>
<p><span style="font-weight: 400;">To comply with the law, information must be collected and used fairly, stored safely and not disclosed to any other person unlawfully. This means we must comply with the Data Protection Principles set out in the Data Protection Act 1998 and GDPR. These principles require that personal data must be:    </span></p>
<ol>
<li><span style="font-weight: 400;">Obtained fairly and lawfully and shall not be processed unless certain conditions are met   </span></li>
<li><span style="font-weight: 400;">Obtained for specific and lawful purposes and not further processed in a manner incompatible with that process   </span></li>
<li><span style="font-weight: 400;">Adequate, relevant and not excessive    </span></li>
<li><span style="font-weight: 400;">Accurate and up to date    </span></li>
<li><span style="font-weight: 400;">Kept for no longer than is necessary    </span></li>
<li><span style="font-weight: 400;">Protected by appropriate security    </span></li>
<li><span style="font-weight: 400;">Not transferred to a country outside the European economic area without adequate </span>protection</li>
</ol>
<p><b>SECURITY</b></p>
<p><span style="font-weight: 400;">The Community Energy Scheme is committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online. To ensure we keep your personal details secure, and restrict unauthorised access to your account, we will always ask you key security questions before discussing your account with you. Only named account holders can discuss or request changes to be made on your Community Energy Scheme  account. Please cooperate with us when establishing your identity, to help combat identity fraud.</span></p>
<p><span style="font-weight: 400;">Please let us know if any of your personal details change, for example a change of name, email address, or other details. You can do this by contacting us by post, email, or telephone at our usual contact details.</span></p>
<p><b>MARKETING</b></p>
<p><span style="font-weight: 400;">We would like to send you information about products and services of ours and other business units in our group which may be of interest or a direct benefit to you. If you have consented to receive marketing, you may opt out at a later date. You have a right at any time to stop us from contacting you for marketing purposes. If you no longer wish to be contacted for marketing purposes, you have a right to unsubscribe. We may contact you by email, Telephone, SMS or by post. You have the right to object or opt out to any of the above forms of communication for marketing purposes. There will be occasions where we will contact you to inform you of special offers or promotions which may benefit you. We may utilise the provisions of legitimate interest under the GDPR regulations. Where we use legitimate interest provisions, we will ensure that all checks and balances are thoroughly performed including the appropriate assessments. We may store your contact details, and carry out marketing profiling activities, for direct marketing purposes. If you have given your consent, or if we are otherwise permitted to do so, we may contact you about our products or services that may be of interest to you. If you prefer not to receive any direct marketing communications from us, you can opt out at any time by sending an email or by utilising the unsubscribe functionality.</span></p>
<p><b>CONTROLLING YOUR PERSONAL INFORMATION</b></p>
<p><span style="font-weight: 400;">With the Community Energy Scheme you are in total control of your personal information.   </span></p>
<p><span style="font-weight: 400;">We will not sell, distribute or lease your personal information to third parties unless we are required by law to do so. We may use your personal information to send you further information, which we think you may find interesting if you tell us that you wish this to happen.</span></p>
<p><b>Data Subject Access Request</b></p>
<p><span style="font-weight: 400;">You may request details of personal information which we hold about you. If you would like a copy of the information held on you, or you think it is incorrect or incomplete, please contact us at:   </span></p>
<p><span style="font-weight: 400;">Data Protection, the Community Energy Scheme, </span><span style="font-weight: 400;">Batchworth House,  99 Church Street, Rickmansworth WD3 1JJ </span><span style="font-weight: 400;"> </span></p>
<p><span style="font-weight: 400;">Or email us at: <span class="s1">stoke@communityenergyscheme.com</span></span></p>
<p><span style="font-weight: 400;"> the Community Energy Scheme will promptly correct any information found to be incorrect.     </span></p>
<p><span style="font-weight: 400;">You can also get details of the credit agencies from which we get, and which we record, information about you.    </span></p>
<p><span style="font-weight: 400;">The information that you request will be provided within a maximum of one calendar month and we will not charge unless the request requires a lot of effort. We try to ensure that the information we hold is accurate, up to date and relevant and we’ll be happy to correct any inaccuracies.    </span></p>
<p><span style="font-weight: 400;">Priority service customers:  </span></p>
<p><span style="font-weight: 400;">Where we believe that you, or a member of your household, need extra care due to factors such as age, health, disability or financial insecurity, we may record this fact on our records.  We will use this information specifically for protecting these customers and their household from loss of service. </span></p>
<p><span style="font-weight: 400;">If you give us information on behalf of someone else, you confirm that you have given them the information set out in this document and that they have not objected to their personal information being used in the way described in it.</span></p>
<p><b>Your rights as Data Subjects</b></p>
<p><span style="font-weight: 400;">Under the General Data Protection Regulations, you have the right to know and access the following.    </span></p>
<ul>
<li><span style="font-weight: 400;">The purposes of the processing    </span></li>
<li><span style="font-weight: 400;">The categories of personal data concerned    </span></li>
<li><span style="font-weight: 400;">The recipients to whom the personal data has been or will be disclosed    </span></li>
<li><span style="font-weight: 400;">The period for which the personal data will be stored;    </span></li>
<li><span style="font-weight: 400;">The right to rectification, erasure, restriction or objection;    </span></li>
<li><span style="font-weight: 400;">The right to lodge a complaint with a supervisory authority;    </span></li>
<li><span style="font-weight: 400;">Where the personal data are not collected from the data subject, any available  information as to their source </span></li>
</ul>
<p><b>Do we transfer data outside the EU? </b></p>
<p><span style="font-weight: 400;">To provide the best possible service to our customers, we sometimes use third party companies which may process the data outside the EU. We have taken steps to ensure all personal data is provided with adequate protection and that all transfers of personal data outside the EU are done lawfully. Where we transfer personal data outside of the EU, the transfers will be under an agreement which covers the EU requirements for the transfer of personal data outside the EU, such as the European Commission approved standard contractual clauses</span><span style="font-weight: 400;">.</span></p>
<p><b>Contact  </b></p>
<p><span style="font-weight: 400;">For further information please write to the </span><span style="font-weight: 400;">Data Protection Manager  </span></p>
<p class="p1"><span class="s1">Smart Systems Limited, 8 Peerglow Centre, Marsh Lane, Ware, SG12 9QL</span></p>
<p><span style="font-weight: 400;">Or email </span><span class="s1">stoke@communityenergyscheme.com</span></p>
</p>
`,
    topCurve: images.topcurvedarkgreen,
    topCurveFilter: getFilterStyle(Colors.none),
    backgroudColorSection: getBgColorStyle(Colors.teal),
    leftContentStyle: { marginBottom: "0px" },
    component: Singlestyle,
    useMargin: false,
    bottomCurve: images.bottomcurvedarkgreen ,
    bottomCurveFilter: getFilterStyle(Colors.none),
  },

];