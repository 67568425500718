import { ContentItem } from "../../types";
import { images, getFilterStyle, colors, Colors, getBgColorStyle, getTextColorStyle } from "../../theme";
import NTPstyle from "../../components/styles/NTPstyle";
import Imagestyle from "../../components/styles/Imagestyle";
import Formstyle from "../../components/styles/Formstyle";
import Circlestyle from "../../components/styles/Circlestyle";
import Buttonstyle from "../../components/styles/Buttonstyle";
import Bannerstyle from "../../components/styles/Bannerstyle";
import Narrowstyle from "../../components/styles/Narrow";
import Singlestyle from "../../components/styles/Singlestyle";
import Narrowstyle2 from "../../components/styles/Narrow2";

const videoSource = "https://www.w3schools.com/tags/movie.mp4"

const clouds = require('../../img/video.mp4');

const newcustomer = require('../../img/newcustomer.png');
const tariffs = require('../../img/tariffs.png');
// const handglobe = require('../img/hand-globe.png');

const photobg2 = require('../../img/2-photo-bg2.jpg');

const katie = require('../../img/katie.jpg');
const handsun = require('../../img/hand-sun.png');
const coinssmall = require('../../img/coinssmall.png');
const logo = require('../../img/ces-logo.png');
const keys2 = require('../../img/ces-key2.png');
const house2 = require('../../img/house2.png');
const plug2 = require('../../img/plug.png');
const ceshouse = require('../../img/ces-house.png');
const community = require('../../img/community.png');
const pig = require('../../img/pig.png');
const people = require('../../img/people.png');

const fair = require('../../img/fair.png');
const match = require('../../img/match.png');
const rebate = require('../../img/rebate.png');

const hello = require('../../img/hello.png');
const directdebit = require('../../img/directdebit-1.png');
const talk = require('../../img/talk.png');
const grid = require('../../img/grid.png');
const meter = require('../../img/meter-1.png');
const led = require('../../img/led.png');
const family = require('../../img/family.png');
const phone = require('../../img/phone.png');

export const config: ContentItem[] = [



  {
    leftContent: `		<h1 class="heading heading--large">COVID-19</h1>


    <p>Installations for Key Workers will be given priority wherever possible. However, we are expanding the total number of Domestic and Commercial customers for whom we can install, by adopting a case-specific approach to carrying out our solar and battery installations.&nbsp; This follows an ongoing review of our installation criteria with the latest government advice.</p>
    <p>As part of that review, we are now risk assessing installs early in our process and only installing with agreement from the customer to move forward, avoiding the inconvenience a called-off install can cause, while also safely expanding the overall number of installs we can carry out.</p>
    <p>In practice, it means that:</p>
    <ul>
    <li>We&rsquo;re conducting remote risk assessments to identify installs that have a &ldquo;Lower Risk&rdquo; of COVID-19 transmission.</li>
    <li>Any installs that fall into the Lower Risk category will be eligible to continue.</li>
    <li>Installs that fall into the &ldquo;Higher Risk&rdquo; category will be paused until we can reclassify them as Lower Risk.</li>
    </ul>
    <p>&nbsp;</p>
    <p>Please note:&nbsp;<em>If you fall into the Lower Risk category, we will still carry out additional risk assessment during installation planning and on the day of install. If any subsequent assessment results in you falling into the Higher Risk category we won&rsquo;t be able to go ahead with your install.</em></p>
    <p>What this means for <strong>Domestic solar</strong> installations</p>
    <p>Key Workers will continue to be prioritised wherever possible but we will now also be able to install for other customers too, where there is a Lower Risk of COVID-19 transmission.</p>
    <p>&nbsp;</p>
    <p>If your installation is classified as:</p>
    <ol>
    <li>Lower Risk - we will be able to progress your install.</li>
    </ol>
    <p>In this case, we will contact you to let you know when your installation can take place.</p>
    <ol>
    <li>Higher Risk - we will have to pause your install until we are able to reclassify it as Lower Risk.</li>
    </ol>
    <p>Once your install is reclassified as Lower Risk we will contact you to schedule an install date. This will happen in line with government guidance and our ability to introduce new safety measures that decrease the risk level.</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>What this means for <strong>Commercial Solar</strong> installations</p>
    <p>Commercial installations will continue &ndash; there is an argument to say its actually a good time to install your solar now, while your commercial property is quiet.&nbsp; However, &nbsp;with all cases only where the transmission risk of COVID-19 is lower.</p>
    <p>We&rsquo;ll ask you to assist us in the completion of a pre-installation, remote risk assessment which will allow us to classify your install followed by a physical survey. This will be needed for all commercial installations</p>
    <p>If your installation is classified as:</p>
    <ol>
    <li>Lower Risk - we will progress the installation as soon as possible.</li>
    </ol>
    <ol>
    <li>Higher Risk - the installation will either be postponed until it can be reclassified as Lower Risk (in line with evolving government guidance and our ability to introduce additional safety measures that decrease the risk level) or cancelled.</li>
    </ol>
    <p>&nbsp;</p>
    <p><strong>Maintenance and survey activity</strong></p>
    <p>Maintenance and survey activity will proceed across all site types, however, each activity will again be subject to a remote risk assessment.&nbsp;</p>
    <p>Lower Risk activities will be progressed as soon as possible, while those classified as Higher Risk will be postponed until they can be reclassified as Lower Risk (in line with the evolving government guidance and our ability to introduce additional safety measures).</p>
    <p>Please note:&nbsp;<em>Should we need to undertake any safety critical maintenance activities during the COVID-19 period, a higher risk threshold will be applied and more robust safety measures will be observed.</em></p>
    <p>About our remote risk assessment process</p>
    <p>Our remote risk assessment process has the goal of limiting contact between our team and customers to prevent further spread of the virus, while allowing us to complete installations where safe to do so.</p>
    <p>This process will evolve in line with government guidance and our ability to introduce additional safety measures (eg. Face Shields as well as Masks) that protect our team and customers.</p>
    <p>As a result, we&rsquo;re able to carry out installations for more customers, in addition to prioritised key workers.</p>
    <p>We&rsquo;re still following robust safety measures, including social distancing requirements during installation and an effective hygiene and PPE regime for our installers. In all cases, scheduling is dependent upon these measures being followed.</p>
    <p>We expect that our updated operating protocols will make it possible for a greater number of installations to be completed but also apologise for any inconvenience caused to those customers who will need to wait a bit longer.</p>
    <p>In what is a challenging time, we appreciate your understanding as we do our best to balance the needs of homes and businesses with the duty of care we have for our team and those we install for.&nbsp;</p>
    <p>We remain committed to our vision of a world where energy shouldn&rsquo;t damage the earth and to protecting the health of our customers, team and wider society.&nbsp; We hope that you, your friends and families are keeping safe and well.</p>
    <p>&nbsp;</p>
    <p>Further information can be found in the following documents:</p>
    <ul>
    <li><a target="_blank" href="https://community-energy-scheme-documents.netlify.app/Covid_19_Domestic_Install_Methodology_CES.pdf" style="text-decoration:underline!important;font-weight:500;">Covid-19 Domestic Install Methodology.pdf</a></p></li>
    <li><a target="_blank" href="https://community-energy-scheme-documents.netlify.app/Covid_19_Warehouse_Methodology_CES.pdf" style="text-decoration:underline!important;font-weight:500;">Covid-19 Warehouse Methodology.pdf</a></p></li>
    <li><a target="_blank" href="https://community-energy-scheme-documents.netlify.app/HSF02B_14_Gold_Standard_Risk_Assessment_14__Coronavirus_COVID_19_(B_1)_Return_To_Work.pdf" style="text-decoration:underline!important;font-weight:500;">HSF02B-14 - Gold Standard Risk Assessment 14 - Coronavirus COVID-19 (B-1) Return To Work.pdf</a></p></li>
    </ul>
`,
    topCurve: images.topcurvedarkgreen,
    topCurveFilter: getFilterStyle(Colors.none),
    backgroudColorSection: getBgColorStyle(Colors.teal),
    leftContentStyle: { marginBottom: "0px" },
    component: Singlestyle,
    useMargin: false,
    bottomCurve: images.bottomcurvedarkgreen,
    bottomCurveFilter: getFilterStyle(Colors.none),
  },


];