// Note: Colors will be a string i.e 'darkgreen' or (|) 'aubergine' ...
export enum Colors {
    darkgreen = 'darkgreen',
    aubergine = 'aubergine',
    yellow = 'yellow',
    blue = 'blue',
    skyblue = 'skyblue',
    plum = 'plum',
    lightgreen = 'lightgreen',
    lilac = 'lilac',
    magenta = 'magenta',
    orange = 'orange',
    teal = 'teal',
    grey = 'grey',
    pink = 'pink',
    white = 'white',
    none = `none`,
    grassgreen = `grassgreen`,
    lightsky = `lightsky`,
}

export const colors: Record<Colors, string> = {
    darkgreen: "#00503d",
    aubergine: "#7c1c5e",
    yellow: "#f2e48c",
    blue: "#3b78b7",
    skyblue: "#9dd9f6",
    plum: "#320735",
    lightgreen: "#a8d138",
    lilac: "#8c57a3",
    magenta: "#a7226e",
    orange: "#f26b38",
    teal: "#599a98",
    grey: "#464e51",
    pink: "#c42575",
    white: 'white',
    none: 'transparent',
    grassgreen: `#81b279`,
    lightsky: `#a7dcdf`,
  };

// Note: Partial<T> allow the type to use "some" of the properties instead of "every"
const filters: Partial<Record<Colors, string>> = {
    yellow: "brightness(0) saturate(100%) invert(95%) sepia(89%) saturate(506%) hue-rotate(334deg) brightness(100%) contrast(90%)",
    blue: "brightness(0) saturate(100%) invert(40%) sepia(84%) saturate(385%) hue-rotate(169deg) brightness(93%) contrast(92%)",
    skyblue: "brightness(0) saturate(100%) invert(78%) sepia(5%) saturate(4192%) hue-rotate(180deg) brightness(110%) contrast(93%)",
    plum: "brightness(0) saturate(100%) invert(9%) sepia(29%) saturate(3933%) hue-rotate(271deg) brightness(99%) contrast(108%)",
    lightgreen: "brightness(0) saturate(100%) invert(100%) sepia(26%) saturate(6113%) hue-rotate(16deg) brightness(97%) contrast(68%)",
    lilac: "brightness(0) saturate(100%) invert(45%) sepia(10%) saturate(2609%) hue-rotate(237deg) brightness(86%) contrast(89%)",
    magenta: "brightness(0) saturate(100%) invert(20%) sepia(35%) saturate(5125%) hue-rotate(305deg) brightness(90%) contrast(92%)",
    orange: "brightness(0) saturate(100%) invert(60%) sepia(41%) saturate(5078%) hue-rotate(338deg) brightness(98%) contrast(93%)",
    teal: "brightness(0) saturate(100%) invert(62%) sepia(16%) saturate(818%) hue-rotate(129deg) brightness(87%) contrast(93%)",
    pink: "brightness(0) saturate(100%) invert(21%) sepia(83%) saturate(1952%) hue-rotate(305deg) brightness(98%) contrast(98%)",
    grassgreen: "filter: invert(69%) sepia(8%) saturate(1410%) hue-rotate(65deg) brightness(93%) contrast(91%);",
    none: "none",
}

interface FilterStyle {
    filter: string;
    width: string;
    marginBottom?: number;
}

export const getFilterStyle = (color: Colors): FilterStyle => 
    ({filter: filters[color] || '', width: '100%', marginBottom: -1});

interface BgColorStyle {
    backgroundColor: string;
}

export const getBgColorStyle = (color: Colors): BgColorStyle => 
    ({backgroundColor: colors[color]});

interface TextColorStyle {
    color: string;
}

export const getTextColorStyle = (color: Colors): TextColorStyle => ({color: colors[color]});

export const images = {
    symmetricalCurveTop: require('../img/symmetrical-curve.png'),
    asymmetricalCurveTop: require('../img/asymmetrical-curve.png'),
    symmetricalCurveBottom: require('../img/symmetrical-curve-bottom.png'),
    asymmetricalCurveBottom: require('../img/asymmetrical-curve-bottom.png'),
    peopleLong: require('../img/people_long3.png'),
    topcurvegrass: require ('../img/top-curve-grass.png'),
    topcurvedarkgreen: require ('../img/top-curve-darkgreen.png'),
    bottomcurvedarkgreen: require ('../img/bottom-curve-darkgreen.png'),
    symmetricalbottomcurvedarkgreen: require ('../img/symmetrical-bottom-curve-green.png'),
    symmetricaltopcurvedarkgreen: require ('../img/symmetrical-top-curve-green.png'),
    topcurvesky: require ('../img/top-curve-sky.png'),
    bottomcurvegrass: require ('../img/bottom-curve-grass.png'),
    bottomcurvegrassspecial: require ('../img/bottom-curve-grass-special.png'),
    bottomcurvesky: require ('../img/bottom-curve-sky.png'),
    bottomgrass2: require ('../img/bottom-grass2.png'),
    bottomgrassthin: require ('../img/bottom-grass.png'),
    desktoppeople: require('../img/people_long3.png'),
    mobilepeople: require('../img/people_long_mobile.png'),

}

