import React, { useState } from 'react';
import '../../index.css';
import '../../stoke.css';
import '../../bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ContentItem } from '../../types';
import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'


const bill1 = require('../../img/bill1.jpg');
const bill2 = require('../../img/bill2.jpg');


const MyVerticallyCenteredModal: React.FC<any> = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style= {{color:"black"}}>
          {props.text}
        </p>
      </Modal.Body>
    </Modal>



  );
}

const modalContent = {
  button1: {
    title: 'Account details',
    text: 'Here you will see your account unique identifier and the date the Community Energy Scheme started supplying energy to the property.',
  },
  button2 : {
    title: 'Payment information',
    text: `Details on how to pay are found in this area, including a link which you can copy and paste into your online browser to make a payment.`,
  },
  button3: {
    title: 'Invoice details',
    text: 'This shows the unique identifier for the present invoice and the date it was issued. It is useful to quote this information to our service agents if you need assistance with a specific bill.',
  },
  button4: {
    title: 'Account Balance at Start of Period',
    text: `This is your "Owing from last month" (as shown in the previous page), before deducting your Solar Rebate (Shown below as "Community Energy Scheme Credit 2020 Adjustment").`,
  },
  button5 : {
    title: 'Invoice consumption this period',
    text: 'The figure calculated here is carried up to the previous section',
  },
  button6: {
    title: 'Payments received',
    text: 'This shows any payments that have been made since last statement, all of which contribute to the current balance of your account which is shown here.',
  },
  button7 : {
    title: 'Solar rebate',
    text: 'This is a bonus against your bill, totalling 25% of your solar consumption',
  },
  button8: {
    title: 'Total outstanding amount',
    text: `This is the current balance of your account. If you pay by cash, cheque or bank transfer, this tells you the amount you need to pay if you have a debit balance. If your account is in credit, you may be due a refund – please contact us to discuss. If you pay by monthly Direct Debit, you don't need to do anything.  However, if you are building up a large debit or credit balance, take a meter reading and contact us to discuss. `,
  },
  button9 : {
    title: 'Savings and consumption to date',
    text: 'In this area you can find your total consumption and savings since joining the scheme',
  },
  button11: {
    title: 'Meter readings',
    text: 'Here we will show you your consumption during the billing period. The letter E in front of the closing reading indicate if the read is an estimate. While we try to make our estimated readings as accurate as possible, they could be too low, meaning you are building up a debt, or they could be too high which means you could be paying too much. If your bill is based on an estimate, you can give us a new meter reading or give us a call, and an advisor will review your account and, in some circumstances, we’ll issue you with a new bill or statement. ',
  },
button12: {
  title: 'Solar energy charges',
  text: 'This shows the total number of kilowatt hours (or kWh for short) you have been charged for during the billing period and the price per kWh at which you have been charged',
},
button13: {
  title: 'Savings made against OFGEM price cap rates',
  text: ' Here you can find details on your savings as well as the positive impact of your membership on the environment. ',
},
button14: {
  title: 'Statement of your account',
  text: 'This is a summary of how your bill has been calculated. Any outstanding balance from previous usage is added to the charges generated during the billing period. If you’re up to date with your bills, have a direct debit or a payment plan in place, then you’re entitled to a solar rebate which will be discounted in this area.',
},
button15: {
  title: 'Summary and support information',
  text: 'Here you can find some a short summary of some key information in your bill including due balance and CES rebate accrued to date for the current year. In addition, you can find the contact details of the Citizens Advice Bureau, who can support you with independent financial advice if needed.',
},
};


const Imagepopupstyle: React.FC<ContentItem> = ({
  leftContent,
  rightContent,
  topCurve,
  topCurveFilter,
  backgroudColorSection,
  bottomCurveFilter,
  bottomCurve,
  leftContentStyle,
  rightContentStyle,
  leftImage,
  rightImage,
  useMargin = false,
  sectionStyle,
}) => {

  const marginContent = React.useMemo(
    () => bottomCurve && useMargin ? { marginTop: "-130px" } : undefined
    , []);

  const sectionRef = React.useRef<HTMLDivElement | null>(null);

  const onScrollIntoView = () => {
    let position: DOMRect | undefined = sectionRef?.current?.getBoundingClientRect();

    // checking for partial visibility
    if (position && (position.top < window.innerHeight && position.bottom >= 0)) {
      sectionRef?.current?.classList.add('animation-end');
      window.removeEventListener('scroll', onScrollIntoView);
    }
  }

  const [selectedModal, setSelectedModal] = React.useState<any>(undefined);


  React.useEffect(() => {
    onScrollIntoView();
    window.addEventListener('scroll', onScrollIntoView);
  }, []);

  return (
    <div id='first-section-of-page'>
      <div className="content site-content">
        <div className="primary content-area">
          <div className="main site-main" style={backgroudColorSection}>
            <Container className='animation-start' ref={sectionRef}>
              <Row style={{ padding: "40px" }}>

                <div className="container-image-popup"  >
                  <img src={bill1} />
                  <Button className="btn-pop-up btn-pop-up1" onClick={() => setSelectedModal(modalContent.button1)}></Button>
                  <Button className="btn-pop-up btn-pop-up2" onClick={() => setSelectedModal(modalContent.button2)}></Button>
                  <Button className="btn-pop-up btn-pop-up3" onClick={() => setSelectedModal(modalContent.button3)}></Button>
                  <Button className="btn-pop-up btn-pop-up11" onClick={() => setSelectedModal(modalContent.button11)}></Button>
                  <Button className="btn-pop-up btn-pop-up12" onClick={() => setSelectedModal(modalContent.button12)}></Button>
                  <Button className="btn-pop-up btn-pop-up13" onClick={() => setSelectedModal(modalContent.button13)}></Button>
                  <Button className="btn-pop-up btn-pop-up14" onClick={() => setSelectedModal(modalContent.button14)}></Button>
                  <Button className="btn-pop-up btn-pop-up15" onClick={() => setSelectedModal(modalContent.button15)}></Button>
                </div>
              </Row>


              <Row style={{ padding: "40px" }}>
                <div className="container-image-popup">
                  <img src={bill2} />
                  {/* <Button className="btn-pop-up btn-pop-up4" onClick={() => setSelectedModal(modalContent.button4)} ></Button>
                  <Button className="btn-pop-up btn-pop-up5" onClick={() => setSelectedModal(modalContent.button5)} ></Button>
                  <Button className="btn-pop-up btn-pop-up6" onClick={() => setSelectedModal(modalContent.button6)}></Button>
                  <Button className="btn-pop-up btn-pop-up7" onClick={() => setSelectedModal(modalContent.button7)}></Button>
                  <Button className="btn-pop-up btn-pop-up8" onClick={() => setSelectedModal(modalContent.button8)}></Button>
                  <Button className="btn-pop-up btn-pop-up9" onClick={() => setSelectedModal(modalContent.button9)}></Button> */}

                  <MyVerticallyCenteredModal
                    show={Boolean(selectedModal)}
                    onHide={() => setSelectedModal(undefined)}
                    title={selectedModal?.title || ''}
                    text={selectedModal?.text || ''}
                  />
                </div>
              </Row>
            </Container>
            <img src={bottomCurve} style={bottomCurveFilter} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Imagepopupstyle;