import { ContentItem } from "../../types";
import { images, getFilterStyle, Colors, getBgColorStyle, getTextColorStyle, colors } from "../../theme";
import Helpstyle from "../../components/styles/Helpstyle";
import Singlestyle from "../../components/styles/Singlestyle";
import FAQsstyle from "src/components/styles/FAQsstyle";
import Circlestyle from "src/components/styles/Circlestyle";
import Imagepopupstyle from "src/components/styles/Imagepopupstyle";
import Buttonstyle2 from "../../components/styles/Buttonstyle2";

// const newcustomer = require('../../img/newcustomer.png');
const tariffs = require('../../img/tariffs.png');
// const handglobe = require('../../img/hand-globe.png');

const getintouch = require('../../img/getintouchwith.png');
const heretohelp = require('../../img/heretohelp.png');
const redlight = require('../../img/redlight.png');
const billing_large = require('../../img/billing.png');

const newcustomer = require('../../img/newcustomer.png');

// const handglobe = require('../img/hand-globe.png');

const photobg2 = require('../../img/2-photo-bg3.jpg');

const katie = require('../../img/katie.jpg');
const handsun = require('../../img/hand-sun.png');
const coinssmall = require('../../img/coinssmall.png');
const logo = require('../../img/ces-logo.png');
const keys2 = require('../../img/ces-key2.png');
const house = require('../../img/illustration.png');
const plug = require('../../img/plug.png');
const ceshouse = require('../../img/ces-house.png');
const community = require('../../img/community.png');
const pig = require('../../img/pig.png');
const people = require('../../img/people.png');

const fair = require('../../img/fair.png');
const match = require('../../img/match.png');
const rebate = require('../../img/rebate.png');
const payments = require('../../img/payments.png');

const hello = require('../../img/hello.png');
const house2 = require('../../img/house2.png');

export const config: ContentItem[] = [

  {
    leftContent: `	
      <img src="${payments}" style="max-width:300px;" /> 
      <h1 class="heading" style="margin-top:60px">
          How to pay
      </h1>
      <p>
          <span style="font-weight:500; color:white">Five ways to pay: </span>direct debit, over the phone, web link, cheque or bank transfer. Whichever you chose, you will get the same rate!
      </p> 
    `,
    topCurve: images.topcurvedarkgreen,
    topCurveFilter: getFilterStyle(Colors.none),
    backgroudColorSection: getBgColorStyle(Colors.teal),
    leftContentStyle: { marginBottom: "0px" },
    component: Singlestyle,
    useMargin: false,
  },
  {
    leftImage: keys2,
    leftContent: ``,
    component: Buttonstyle2,
    backgroudColorSection: getBgColorStyle(Colors.teal),
    leftContentStyle: getTextColorStyle(Colors.white),
    rightContentStyle: getTextColorStyle(Colors.white),
    useMargin: true,
    bottomCurve: images.symmetricalbottomcurvedarkgreen,
    bottomCurveFilter: getFilterStyle(Colors.none),
  },
]