import { ContentItem } from "../../types";
import { images, getFilterStyle, Colors, getBgColorStyle, getTextColorStyle, colors } from "../../theme";
import NTPstyle from "../../components/styles/NTPstyle";
import Narrowstyle from "../../components/styles/Narrow";
import FAQsstyle from "src/components/styles/FAQsstyle";
import Narrowstyle2 from "../../components/styles/Narrow2";
import Formstyle from "../../components/styles/Formstyle.js";

// const newcustomer = require('../../img/newcustomer.png');
const tariffs = require('../../img/tariffs.png');
// const handglobe = require('../../img/hand-globe.png');

const people = require('../../img/people.png');

const hello = require('../../img/hello.png');

const newcustomer = require('../../img/newcustomer.png');

// const handglobe = require('../img/hand-globe.png');


const telephone = require('../../img/telephone.png');
const email = require('../../img/email.png');
const talkyellow = require('../../img/talkyellow.png');

export const config: ContentItem[] = [
    {
      topCurve: images.topcurvedarkgreen,
      topCurveFilter: getFilterStyle(Colors.none),
      leftContent: `<img src=${hello} />`,
      rightContent: `<h2 class="heading heading--large" >We’re here to help</h2>
      <p>Got a question?<br>
      Need some help?<br>
      There are many ways you can get in touch with us, just choose from our contact options below. <br>
      For media enquires please email <a style='text-decoration:underline!important;' href='mailto:media@communityenergyscheme.com'>media@communityenergyscheme.com</a>.
      `,
  
      component: NTPstyle,
      backgroudColorSection: getBgColorStyle(Colors.blue),
      leftContentStyle: getTextColorStyle(Colors.grey),
      rightContentStyle: getTextColorStyle(Colors.grey),
      bottomCurve: images.asymmetricalCurveBottom,
      bottomCurveFilter: getFilterStyle(Colors.teal),
    },

    {

      leftImage: telephone,
  
      rightContent: `<h2 class="heading">Give us a call</h2>

      <p>Our Customer Service opening times are as follows:</p>
      
      <p>9am-5.30pm, Monday-Friday</p>

      <h2 class="heading">Our number is:<br><a style='text-decoration:underline!important;' href="tel:01782438427">01782 438 427</a></h2><br>
      
      <a class="download-button download-button--dark-purple" href="tel:01782438427">Call now</a>`,
      // topCurve: images.symmetricalCurveTop,
      // topCurveFilter: getFilterStyle(Colors.magenta),
      component: Narrowstyle,
      backgroudColorSection: getBgColorStyle(Colors.teal),
      // bottomCurve: images.asymmetricalCurveBottom,
      // bottomCurveFilter: getFilterStyle(Colors.blue),
      leftContentStyle: getTextColorStyle(Colors.white),
      rightContentStyle: getTextColorStyle(Colors.blue),
      useMargin: false,
  
    },
  
  
    {
  
      leftContent: `<h2 class="heading heading--large">Send us an email</h2>	
      
      <p><strong><a style='text-decoration:underline!important;' href="mailto:stoke@communityenergyscheme.com">stoke@communityenergyscheme.com</a></strong></p>
      <p>We will get back to you within 3 working days</p>
      
      <br>
      
      <a class="download-button download-button--dark-purple" href="mailto:stoke@communityenergyscheme.com">Send email</a>
      `,
  
      rightImage: email ,
      // topCurve: images.symmetricalCurveTop,
      // topCurveFilter: getFilterStyle(Colors.magenta),
      component: Narrowstyle2,
      backgroudColorSection: getBgColorStyle(Colors.teal),

      leftContentStyle: getTextColorStyle(Colors.white),
      rightContentStyle: getTextColorStyle(Colors.blue),
      useMargin: false,
      bottomCurve: images.asymmetricalCurveBottom,
      bottomCurveFilter: getFilterStyle(Colors.orange),
  
    },

    
    // {

    //   leftImage: people,
  
    //   rightContent: `<h2 class="heading">Book a consultation</h2>

    //   <p>Book an appointment in person or over the phone to discuss your account with a specialist.</p>
    //   <br>
      
    //   <a class="download-button download-button--dark-purple" href=${`/calendar`} >Book now</a>
    //   `,
    //   // topCurve: images.symmetricalCurveTop,
    //   // topCurveFilter: getFilterStyle(Colors.magenta),
    //   component: Narrowstyle,
    //   backgroudColorSection: getBgColorStyle(Colors.teal),

    //   leftContentStyle: getTextColorStyle(Colors.white),
    //   rightContentStyle: getTextColorStyle(Colors.blue),
    //   useMargin: false,
    //   bottomCurve: images.asymmetricalCurveBottom,
    //   bottomCurveFilter: getFilterStyle(Colors.orange),
    // },

    { 

        component: Formstyle,

      },


];