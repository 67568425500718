import { ContentItem } from "../../types";
import { images, getFilterStyle, Colors, getBgColorStyle, getTextColorStyle, colors } from "../../theme";

import Singlestyle from "../../components/styles/Singlestyle";
import Narrowstyle2 from "../../components/styles/Narrow2";
import NTPstyle from "../../components/styles/NTPstyle";
import Iframestyle from "../../components/styles/Iframestyle";
import Narrowwpopstyle from "../../components/styles/Narrowwpopstyle";
import Joinpopupstyle from "../../components/styles/Joinpopupstyle";
import Signupformstyle from "../../components/styles/Signupformstyle";

// const newcustomer = require('../../img/newcustomer.png');
const tariffs = require('../../img/tariffs.png');
// const handglobe = require('../../img/hand-globe.png');
 
const coinsmany = require('../../img/coinsmany.png');
const cesfamily = require('../../img/ces-family.png');
const renewal = require('../../img/Renewals_infographic-1.png');
const keys2 = require('../../img/ces-key2.png');

const phone = require('../../img/phone.png');
const hello = require('../../img/hello.png');
const directdebit = require('../../img/directdebit-1.png');
const handglobe = require('../../img/hand_globe.png');
const ceshouse = require('../../img/ces-house.png');
const community = require('../../img/community.png');
const pig = require('../../img/pig.png');

export const config: ContentItem[] = [
  {
    leftContent: `	<img src="${coinsmany}" style="max-width:370px;" /> <h1 class="heading" ">Ready for big savings?</h1>

    <p>No deposit. No upfront cost. No worries.</p>
      
    <p>Take control of your power bill against price fluctuations and do your part to protect the future of Stoke, all without a penny out of your pocket!    </p>
    
    
    `,
    topCurve: images.topcurvedarkgreen,
    topCurveFilter: getFilterStyle(Colors.none),
    backgroudColorSection: getBgColorStyle(Colors.blue),
    leftContentStyle: { marginBottom: "0px" },
    component: Singlestyle,
    useMargin: false,

  },

  {

    leftImage: pig,

    rightContent: `<h2 class="heading heading--alt">The lowest rate in the market, locked down for years to come</h2>	<p>
 
    Fair prices locked down forever and greener energy from truly renewable sources. Save hundreds of pounds a year with the Community Energy Scheme all while creating a better tomorrow for Stoke-on-Trent. </p>`,
    component: Narrowwpopstyle,
    backgroudColorSection: getBgColorStyle(Colors.blue),
    bottomCurve: images.asymmetricalCurveBottom,
    bottomCurveFilter: getFilterStyle(Colors.yellow),
    leftContentStyle: getTextColorStyle(Colors.white),
    rightContentStyle: getTextColorStyle(Colors.blue),
    useMargin: false,

  },
 

  {
    leftContent: `	

    <img src="${handglobe}" style="max-width:200px;" />
    
  
    <h2 style="margin-top:30px;" class="heading heading--alt">A step closer to a better future</h2>
    
    <p>Don't wait to save hundreds of pounds a year and enjoy greener energy from a truly renewable source. 
    </p>
    
    <p>You have lots of options when it comes to joining:</p> `,
    topCurve: images.asymmetricalCurveTop,
    topCurveFilter: getFilterStyle(Colors.yellow),
    backgroudColorSection: getBgColorStyle(Colors.teal),
    leftContentStyle: { marginBottom: "0px" },
    component: Singlestyle,
    useMargin: false,

  },

  {

    leftImage: pig,

    rightContent: `<h2 class="heading heading--alt">The lowest rate in the market, locked down for years to come</h2>	<p>
 
    Fair prices locked down forever and greener energy from truly renewable sources. Save hundreds of pounds a year with the Community Energy Scheme all while creating a better tomorrow for Stoke-on-Trent. </p>`,
    component: Joinpopupstyle,
    backgroudColorSection: getBgColorStyle(Colors.teal),
    bottomCurve: images.asymmetricalCurveBottom,
    bottomCurveFilter: getFilterStyle(Colors.orange),
    leftContentStyle: getTextColorStyle(Colors.white),
    rightContentStyle: getTextColorStyle(Colors.blue),
    useMargin: false,

  },

  
  { 

    component: Signupformstyle,


    },
];