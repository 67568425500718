import { ContentItem } from "../../types";
import { images, getFilterStyle, Colors, getBgColorStyle, getTextColorStyle, colors } from "../../theme";
import NTPstyle from "../../components/styles/NTPstyle";
import Singlestyle from "../../components/styles/Singlestyle";
import FAQsstyle from "src/components/styles/FAQsstyle";
import Circlestyle from "src/components/styles/Circlestyle";
import Imagepopupstyle from "src/components/styles/Imagepopupstyle";
import Promostyle from "src/components/styles/Promostyle";


// const newcustomer = require('../../img/newcustomer.png');
const tariffs = require('../../img/tariffs.png');
// const handglobe = require('../../img/hand-globe.png');

const getintouch = require('../../img/getintouchwith.png');
const heretohelp = require('../../img/heretohelp.png');
const redlight = require('../../img/redlight.png');
const billing_large = require('../../img/billing.png');

const newcustomer = require('../../img/newcustomer.png');

// const handglobe = require('../img/hand-globe.png');

const photobg2 = require('../../img/2-photo-bg3.jpg');

const katie = require('../../img/katie.jpg');
const handsun = require('../../img/hand-sun.png');
const coinssmall = require('../../img/coinssmall.png');
const logo = require('../../img/ces-logo.png');
const keys2 = require('../../img/ces-key2.png');
const house = require('../../img/illustration.png');
const plug = require('../../img/plug.png');
const ceshouse = require('../../img/ces-house.png');
const community = require('../../img/community.png');
const pig = require('../../img/pig.png');
const people = require('../../img/people.png');

const fair = require('../../img/fair.png');
const match = require('../../img/match.png');
const rebate = require('../../img/rebate.png');

const handsmall = require('../../img/hand_globe_small.png');


export const config: ContentItem[] = [
  {
    leftImage: handsmall,
    rightContent: `
    <h1 class="heading heading--large" " >  
    Did you know? 
    </h1> 
    <p>
    You can save big money with these 10 simple tips.
    </p>    
    <p>
    Don't forget! In addition to these savings, all customers who are up to date with their bills or have a payment plan or direct debit in place will benefit from an 18% to 25% solar rebate. This means that you can save even more money by just getting in touch and paying what you can afford.
    </p>
    `,
    topCurve: images.symmetricaltopcurvedarkgreen,
    topCurveFilter: getFilterStyle(Colors.none),
    component: NTPstyle,
    backgroudColorSection: getBgColorStyle(Colors.teal),
    rightContentStyle: getTextColorStyle(Colors.grey),
    bottomCurve: images.asymmetricalCurveBottom,
    bottomCurveFilter: getFilterStyle(Colors.blue),
    
  },

  {
    leftContent: `	
      <h4>
      To see energy saving tips, click the appliances in the house.
      </h4> 
    `,
    topCurve: images.symmetricalCurveTop,
    topCurveFilter: getFilterStyle(Colors.blue),
    backgroudColorSection: getBgColorStyle(Colors.blue),
    leftContentStyle: { marginBottom: "0px" },
    component: Singlestyle,
    useMargin: false,
  },

  {
    // topCurve: images.symmetricalCurveTop,
    // topCurveFilter: getFilterStyle(Colors.teal),
    component: Promostyle,
    backgroudColorSection: getBgColorStyle(Colors.blue),
    bottomCurve: images.asymmetricalCurveBottom,
    bottomCurveFilter: getFilterStyle(Colors.orange),
    
  }



];

