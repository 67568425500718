import React, { useCallback, useState } from 'react';
import '../../index.css';
import '../../stoke.css';
import '../../bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ContentItem } from '../../types';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';


import './VideoLibrary.css';

const VideoLibrary: React.FC<ContentItem> = ({
    topCurve,
    topCurveFilter,
    backgroudColorSection,
    bottomCurveFilter,
    bottomCurve,
    leftContentStyle,
    sectionStyle,
}) => {




    const functionTest = () => { console.log('hi Sarai') };

    console.log('hi', isMobile);

    const screen1 = useFullScreenHandle();
    const screen2 = useFullScreenHandle();
    const screen3 = useFullScreenHandle();
    const screen4 = useFullScreenHandle();
    const screen5 = useFullScreenHandle();

    const reportChange = useCallback((state, handle) => {
        if (handle === screen1) {
            console.log('Screen 1 went to', state, handle);
        }
        if (handle === screen2) {
            console.log('Screen 2 went to', state, handle);
        }
        if (handle === screen3) {
            console.log('Screen 3 went to', state, handle);
        }
        if (handle === screen4) {
            console.log('Screen 4 went to', state, handle);
        }
        if (handle === screen5) {
            console.log('Screen 5 went to', state, handle);
        }
    }, [screen1, screen2, screen3, screen4, screen5]);



    if (screen2.active) {
        console.log('handle is active');
    }



    const sectionRef = React.useRef<HTMLDivElement | null>(null);

    const onScrollIntoView = () => {
        let position: DOMRect | undefined = sectionRef?.current?.getBoundingClientRect();

        // checking for partial visibility
        if (position && (position.top < window.innerHeight && position.bottom >= 0)) {
            sectionRef?.current?.classList.add('animation-end');
            window.removeEventListener('scroll', onScrollIntoView);
        }
    }

    React.useEffect(() => {
        onScrollIntoView();
        window.addEventListener('scroll', onScrollIntoView);
    }, []);




    return (

        <div className="content site-content" style={sectionStyle}  >
            <div className="primary content-area" >
                <div className="main site-main" style={backgroudColorSection}>
                    <div className='section help-child-section-1' style={{ ...leftContentStyle }}><img className="curveError" src={topCurve} style={topCurveFilter} />
                        <Container style={{ paddingLeft: 30, paddingRight: 30 }}>
                            <Row className='animation-start videoLibrary' ref={sectionRef}>
                                <Col sm="6" md="4" className="videoLibrary_item col-xs-6 col-md-4 help-grid-item" >

                                    <img src={require('../../img/good/Group5.png')} className="section-image--help videoLibrary__item__image" />


                                    <a
                                        className="videoLibrary__Button--desktop"
                                        style={{ display: !isMobile ? 'inherit' : 'none' }}
                                        onClick={screen1.enter}
                                    />
                                    <a
                                        className="videoLibrary__Button--mobile"
                                        href='https://www.youtube.com/embed/lMq_54XcQEE'
                                        target="_blank"
                                        style={{ display: isMobile ? 'inherit' : 'none' }}
                                    />


                                    <p style={{ backgroundColor: "#ED6A3B" }}>What to do if your<br /> RCD trips
                                    </p>

                                    <div style={{ display: screen1.active ? 'inherit' : 'none' }} >
                                        <FullScreen className="videoLibrary__Iframe" handle={screen1}>
                                            <iframe
                                                width="100%"
                                                height="100%"
                                                src={`https://www.youtube.com/embed/lMq_54XcQEE`}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen={true}
                                                name="iframe1"

                                            />
                                        </FullScreen>


                                    </div>

                                </Col>

                                <Col sm="6" md="4" className="videoLibrary_item col-xs-6 col-md-4 help-grid-item" >
                                    <img src={require('../../img/good/Group6.png')} className="section-image--help videoLibrary__item__image" />
                                    <a
                                        className="videoLibrary__Button--desktop"
                                        style={{ display: !isMobile ? 'inherit' : 'none' }}
                                        onClick={screen2.enter}
                                    />
                                    <a
                                        className="videoLibrary__Button--mobile"
                                        href='https://www.youtube.com/embed/42NaEfAeAWg'
                                        target="_blank"
                                        style={{ display: isMobile ? 'inherit' : 'none' }}
                                    />

                                    <p style={{ backgroundColor: "#8C57A3" }}>How to Claim your <br />Free LED Bulbs
                                    </p>

                                    <div style={{ display: screen2.active ? 'inherit' : 'none' }} >
                                        <FullScreen className="videoLibrary__Iframe" handle={screen2}>

                                            <iframe
                                                width="100%"
                                                height="100%"
                                                src={`https://www.youtube.com/embed/42NaEfAeAWg`}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen={true}
                                                title="Embedded youtube"
                                            />
                                        </FullScreen>
                                    </div>
                                </Col>
                                <Col sm="6" md="4" className="videoLibrary_item col-xs-6 col-md-4 help-grid-item" >
                                    <img src={require('../../img/good/Group7.png')} className="section-image--help videoLibrary__item__image" />
                                    <a
                                        className="videoLibrary__Button--desktop"
                                        style={{ display: !isMobile ? 'inherit' : 'none' }}
                                        onClick={screen3.enter}
                                    />
                                    <a
                                        className="videoLibrary__Button--mobile"
                                        href='https://www.youtube.com/embed/zq7F5NpdMRo'
                                        target="_blank"
                                        style={{ display: isMobile ? 'inherit' : 'none' }}
                                    />

                                    <p style={{ backgroundColor: "#3B78B8" }}>Why do I have <br />two energy bills?</p>

                                    <div style={{ display: screen3.active ? 'inherit' : 'none' }} >
                                        <FullScreen className="videoLibrary__Iframe" handle={screen3}>
                                            <iframe
                                                width="100%"
                                                height="100%"
                                                src={`https://www.youtube.com/embed/zq7F5NpdMRo`}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen={true}
                                                title="Embedded youtube"
                                            />
                                        </FullScreen>
                                    </div>
                                </Col>
                                {/* <Col sm="6" md="4" className="videoLibrary_item col-xs-6 col-md-4 help-grid-item" >
                                    <img src={require('../../img/good/Group8.png')} className="section-image--help videoLibrary__item__image" />
                                    <a
                                        className="videoLibrary__Button--desktop"
                                        style={{ display: !isMobile ? 'inherit' : 'none' }}
                                        onClick={screen4.enter}
                                    />
                                    <a
                                        className="videoLibrary__Button--mobile"
                                        href='https://www.youtube.com/embed/ZJRPEnbmxa0'
                                        target="_blank"
                                        style={{ display: isMobile ? 'inherit' : 'none' }}
                                    />

                                    <p style={{ backgroundColor: "#7C1C5E" }}>How to find your SOLAR energy<br />meter reading
                                    </p>

                                    <div style={{ display: screen4.active ? 'inherit' : 'none' }} >
                                        <FullScreen className="videoLibrary__Iframe" handle={screen4}>
                                            <iframe
                                                width="100%"
                                                height="100%"
                                                src={`https://www.youtube.com/embed/ZJRPEnbmxa0`}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen={true}
                                                title="Embedded youtube"

                                            />
                                        </FullScreen>
                                    </div>
                                </Col> */}
                                <Col sm="6" md="4" className="videoLibrary_item col-xs-6 col-md-4 help-grid-item" >
                                    <img src={require('../../img/good/Group9.png')} className="section-image--help videoLibrary__item__image" />
                                    <a
                                        className="videoLibrary__Button--desktop"
                                        style={{ display: !isMobile ? 'inherit' : 'none' }}
                                        onClick={screen4.enter}
                                    />
                                    <a
                                        className="videoLibrary__Button--mobile"
                                        href='https://www.youtube.com/embed/NOJj5yN7mI4'
                                        target="_blank"
                                        style={{ display: isMobile ? 'inherit' : 'none' }}
                                    />

                                    <p style={{ backgroundColor: "#4A8482" }}>How to find your solar energy and electricity grid supplier readings
                                    </p>

                                    <div style={{ display: screen5.active ? 'inherit' : 'none' }} >
                                        <FullScreen className="videoLibrary__Iframe" handle={screen5}>
                                            <iframe
                                                width="100%"
                                                height="100%"
                                                src={`https://www.youtube.com/embed/NOJj5yN7mI4`}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen={true}
                                                title="Embedded youtube"
                                            />
                                        </FullScreen>
                                    </div>
                                </Col>


                            </Row>
                        </Container>
                        <img src={bottomCurve} style={bottomCurveFilter} />
                    </div>
                </div>
            </div>
        </div>);
}





export default VideoLibrary;