import React, { useState } from 'react';
import '../../index.css';
import '../../stoke.css';
import '../../bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ContentItem } from '../../types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'

const keys2 = require('../../img/ces-key2.png');
const allyouneedtoknow = require('../../img/allyouneedtoknow.png');
const letter = require('../../img/letter.png');
const credit = require('../../img/pay_ces.png');
const coins = require('../../img/coins.png');
const telephone = require('../../img/telephone.png');
const buildings = require('../../img/buildings.png');
const talkyellow = require('../../img/talk.png');




const MyVerticallyCenteredModal: React.FC<any> = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ color: "black" }}>
          {props.text}
        </p>
      </Modal.Body>
    </Modal>



  );
}

const modalContent = {


  button12: {
    title: 'Pay via weblink',
    text: `On your monthly statement on the right-hand panel, you'll find your unique web link. You'll need to copy and paste or type this into the URL bar to make an online payment. If you're having issues with your link. Then please give us a call on  01782 438391.`,
  },
  button13: {
    title: 'To pay via cheque please use the following details:',
    text: 'Community Energy Scheme Stoke Limited, Unit 8 Peerglow Centre, Marsh Lane, Ware. Hertfordshire, SG12 9QL'
  },
  button14: {
    title: 'Account details',
    text: `
    • Account Number: 64496353   
    • Sort Code: 23-18-84   
    • Account Name: Community Energy Scheme Stoke Limited  
    • Bank: Lloyds   
    • Reference: Please Quote your STO Number   
    `,
  },
  button15: {
    title: 'Special assistance ',
    text: `
    Please contact via one of the following:
    • Call: 01782 438391
    • Email: cc@communityenergyscheme.com
    `,
  },





}












const Buttonstyle: React.FC<ContentItem> = ({
  leftContent,
  rightContent,
  topCurve,
  topCurveFilter,
  backgroudColorSection,
  bottomCurveFilter,
  bottomCurve,
  leftContentStyle,
  rightContentStyle,
  leftImage,
  rightImage,
  useMargin = true,
  link,
  sectionStyle,
}) => {

  const marginContent = React.useMemo(
    () => bottomCurve && useMargin ? { marginTop: "70px" } : undefined
    , []);

  const sectionRef = React.useRef<HTMLDivElement | null>(null);

  const onScrollIntoView = () => {
    let position: DOMRect | undefined = sectionRef?.current?.getBoundingClientRect();

    // checking for partial visibility
    if (position && (position.top < window.innerHeight && position.bottom >= 0)) {
      sectionRef?.current?.classList.add('animation-end');
      window.removeEventListener('scroll', onScrollIntoView);
    }
  }


  const [selectedModal, setSelectedModal] = React.useState<any>(undefined);

  React.useEffect(() => {
    onScrollIntoView();
    window.addEventListener('scroll', onScrollIntoView);
  }, []);

  return (
    <div id='first-section-of-page' style={sectionStyle} className="container2-image-popup" >
      <div className="content site-content">
        <div className="primary content-area">
          <main className="main site-main" style={backgroudColorSection}>

            <div className='section business-section-4'><img src={topCurve} style={topCurveFilter} />
              <Container className="section" style={{ paddingBottom: `40px` }}>
                <Row className='animation-start' ref={sectionRef}>
                  <Col md="6" className="col-md-6"   >
                    <div className="section-content">
                      <div className="section-content__inner">
                        <img src={credit} className="section-image" style={{ maxHeight: `160px` }} />
                        <h3 className="heading" >Direct debit</h3>
                        {/* <p>Click below to download your Direct Debit instruction or call us on <a href="tel:01782438427" style={{ textDecoration: 'underline!important' }}><span style={{ fontWeight: 500, color: 'white' }}>01782 438 427</span></a> to set this up. You will pay the same amount each month, so your will always know how much you're paying. </p> <Button className="btn-pop-up btn-pop-up10" style={{ background: `#a7226e` }} href="https://www.keepandshare.com/doc24/110899/racl-new-ddi-17-july-2020-pdf-161k?da=y" target="_blank" >Download DD instruction</Button> 
                        <Button className="btn-pop-up btn-pop-up10" style={{ background: `#a7226e` }} href="https://www.keepandshare.com/doc24/110899/racl-new-ddi-17-july-2020-pdf-161k?da=y" target="_blank" >
                            Download DD instruction
                        </Button>*/}
                        <p>To set up a Direct Debit please give our credit control team a call to set this up for you. You will pay the same amount each month, so your will always know how much you're paying.
                        </p> 
                        <Button className="btn-pop-up btn-pop-up11" style={{ background: `#a7226e` }} href="tel:01782 438391"  >
                          Call now
                        </Button>
                      </div>
                      <div className="section-content__inner" style={marginContent} >
                        <img src={telephone} className="section-image" style={{ maxHeight: `160px` }} />
                        <h3 className="heading" >Over the phone</h3>
                        <p>
                          Pay your bill over the phone with a credit or debit card by calling <a href="tel:01782 438391" style={{ textDecoration: 'underline!important' }}><span style={{ fontWeight: 500, color: 'white' }}> 01782 438391</span></a>. Please have your bill handy so you can check any details you'll need. We accept most major cards.
                        </p> 
                        <Button className="btn-pop-up btn-pop-up11" style={{ background: `#a7226e` }} href="tel:01782 438391" >
                          Call now
                        </Button>
                      </div>
                      <div className="section-content__inner" style={marginContent} >
                        <img src={allyouneedtoknow} className="section-image" style={{ maxHeight: `160px` }} />
                        <h3 className="heading" >
                          Web link
                        </h3>
                        <p>
                        Our Payment URL functionality allows you to pay by copying and pasting the link on your invoice sent to you monthly via email. If you would like to set this up just get in touch with one of our advisors who will be happy to help.
                        </p> 
                        <Button className="btn-pop-up btn-pop-up12" style={{ background: `#a7226e` }} onClick={() => setSelectedModal(modalContent.button12)} >
                            Learn more
                        </Button>
                      </div>
                    </div>
                  </Col>
                  <Col md="6" className="col-md-6" >
                    <div className="section-content">
                      <div className="section-content__inner">
                        <img src={letter} className="section-image" style={{ maxHeight: `160px` }} />
                        <h3 className="heading" >Cheque</h3>
                        <p>
                          Please make cheques payable to <br/> Community Energy Scheme Stoke Limited <br/> and post to the address below:
                        </p> 
                        <Button className="btn-pop-up btn-pop-up13" style={{ background: `#a7226e` }} onClick={() => setSelectedModal(modalContent.button13)} >
                          Get address
                        </Button>
                      </div>
                      <div className="section-content__inner" style={marginContent} >
                        <img src={buildings} className="section-image" style={{ maxHeight: `160px` }} />
                        <h3 className="heading" >Bank transfer</h3>
                        <p>You can make a bank transfer from your account to ours. Payments take between three and four working days to reach us. Use your Customer Number as the reference. </p> 
                        <Button className="btn-pop-up btn-pop-up14" style={{ background: `#a7226e` }} onClick={() => setSelectedModal(modalContent.button14)} >
                          Get transfer details
                        </Button>
                      </div>
                      <div className="section-content__inner" style={marginContent} >
                        <img src={talkyellow} className="section-image" style={{ maxHeight: `160px` }} />
                        <h3 className="heading" >Special assistance</h3>
                        <p>We recognise that some customers might need special accommodations due to disabilities or special circumstances. <br/> If this is the case for you please get in touch, we are listening and we want to help. </p> 
                        <Button className="btn-pop-up btn-pop-up14" style={{ background: `#a7226e` }} onClick={() => setSelectedModal(modalContent.button15)} >
                          Learn more
                        </Button>
                      </div>
                      <MyVerticallyCenteredModal
                        show={Boolean(selectedModal)}
                        onHide={() => setSelectedModal(undefined)}
                        title={selectedModal?.title || ''}
                        text={selectedModal?.text || ''}
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
              <img src={bottomCurve} style={bottomCurveFilter} />
            </div >
          </main>
        </div>
      </div>
    </div>
  );
}
export default Buttonstyle;

//dangerouslySetInnerHTML={{ __html: topContent }} this makes html work somehow, try to avoid using it because it means DANGEEEEERRRR

