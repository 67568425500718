import { ContentItem } from "../../types";
import { images, getFilterStyle, colors, Colors, getBgColorStyle, getTextColorStyle } from "../../theme";
import NTPstyle from "../../components/styles/NTPstyle";
import Imagestyle from "../../components/styles/Imagestyle";
import Formstyle from "../../components/styles/Formstyle";
import Circlestyle from "../../components/styles/Circlestyle";
import Buttonstyle from "../../components/styles/Buttonstyle";
import Bannerstyle from "../../components/styles/Bannerstyle";
import Narrowstyle from "../../components/styles/Narrow";
import Singlestyle from "../../components/styles/Singlestyle";
import Narrowstyle2 from "../../components/styles/Narrow2";

const videoSource = "https://www.w3schools.com/tags/movie.mp4"

const clouds = require('../../img/video.mp4');

const newcustomer = require('../../img/newcustomer.png');
const tariffs = require('../../img/tariffs.png');
// const handglobe = require('../img/hand-globe.png');

const photobg2 = require('../../img/2-photo-bg2.jpg');

const katie = require('../../img/katie.jpg');
const handsun = require('../../img/hand-sun.png');
const coinssmall = require('../../img/coinssmall.png');
const logo = require('../../img/ces-logo.png');
const keys2 = require('../../img/ces-key2.png');
const house2 = require('../../img/house2.png');
const plug2 = require('../../img/plug.png');
const ceshouse = require('../../img/ces-house.png');
const community = require('../../img/community.png');
const pig = require('../../img/pig.png');
const people = require('../../img/people.png');

const fair = require('../../img/fair.png');
const match = require('../../img/match.png');
const rebate = require('../../img/rebate.png');

const hello = require('../../img/hello.png');
const directdebit = require('../../img/directdebit-1.png');
const talk = require('../../img/talk.png');
const grid = require('../../img/grid.png');
const meter = require('../../img/meter-1.png');
const led = require('../../img/led.png');
const family = require('../../img/family.png');
const phone = require('../../img/phone.png');

export const config: ContentItem[] = [




  {
    leftContent: `	 <h1 class="heading heading--large">Cookies</h1>

    <p><p class="p1"><span class="s1">This site uses cookies – these are small text files that are placed on your machine to help the site provide a better user experience. In general, cookies are used to retain user preferences, store information for things like shopping carts, and provide anonymised tracking data to third party applications like Google Analytics.</span></p>
<p class="p2"><span class="s1">As a rule, cookies will make your browsing experience better. However, you may prefer to disable cookies on this site and on others. The most effective way to do this is to disable cookies in your browser. We suggest consulting the Help section of your browser or taking a look at the About Cookies website</span><span class="s2"> (<a href="http://www.aboutcookies.org/" target="_blank" rel="noopener noreferrer">http://www.aboutcookies.org/</a>)</span><span class="s1"> which offers guidance for all modern browsers.</span></p>
<p class="p2">
</p> `,
    topCurve: images.topcurvedarkgreen,
    topCurveFilter: getFilterStyle(Colors.none),
    backgroudColorSection: getBgColorStyle(Colors.teal),
    leftContentStyle: { marginBottom: "0px" },
    component: Singlestyle,
    useMargin: false,
    bottomCurve: images.bottomcurvedarkgreen,
    bottomCurveFilter: getFilterStyle(Colors.none),
  },


];