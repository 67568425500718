import React, { useState } from 'react';
import '../../index.css';
import '../../stoke.css';
import '../../bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ContentItem } from '../../types';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';



const Newcenteredstyle: React.FC<ContentItem> = ({
  leftContent,
  rightContent,
  topCurve,
  topCurveFilter,
  backgroudColorSection,
  bottomCurveFilter,
  bottomCurve,
  leftContentStyle,
  rightContentStyle,
  leftImage,
  rightImage,
  useMargin,
  sectionStyle,
}) => {


  const sectionRef = React.useRef<HTMLDivElement | null>(null);

  const onScrollIntoView = () => {
    let position: DOMRect | undefined = sectionRef?.current?.getBoundingClientRect();

    // checking for partial visibility
    if (position && (position.top < window.innerHeight && position.bottom >= 0)) {
      sectionRef?.current?.classList.add('animation-end');
      window.removeEventListener('scroll', onScrollIntoView);
    }
  }

  React.useEffect(() => {
    onScrollIntoView();
    window.addEventListener('scroll', onScrollIntoView);
  }, []);

  return (

    <div className="content site-content"   >
      <div className="primary content-area" >
        <div className="main site-main"
          style={backgroudColorSection}>
          <div 
            className='section help-child-section-1'
            style={{ ...leftContentStyle, marginTop: -1 }}
          >              
          <img 
            src={topCurve}
            style={topCurveFilter} 
          />

            <section className="section home-section-whale--bottom" >
              <Container className='animation-start' ref={sectionRef} >
                <Row className="no-desktop">
                  <Col md="6" className="col-md-push-6 ">
                    <div className="section-content fadeInTop fadeInTop--active" style={{}}>
                      <div className="section-content__inner" >
                        {/* <img src={require('../../img/plug.png')} style={{ maxWidth: '350px' }} /> */}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" className="secondSection flex-center " >
                    <div className="section-content">
                      <div className="section-content__inner" >
                        <div className="section-text section-text--wide fadeInTop fadeInTop--active" >
                          <h2 className="heading heading--large" >Simply a better way.</h2>
                          <p>The Community Energy Scheme offers Stoke-on-Trent council tenants affordable, renewable electricity. It is a partnership between Community Energy and Stoke-on-Trent City Council that you can easily sign up for.
                         </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
            <section className="section home-section-3">
              <img src={require('../../img/plug.png')} alt="Plug" className="section-image fadeInTop pig-image fadeInTop--active" style={{}} />
              <Container>
                <Row>
                  <Col md="12" className="col-md-offset-3 flex-center">
                    <div className="section-content">
                      <div className="section-content__inner">
                        <div className="section-text section-text--wide fadeInTop fadeInTop--active" style={{}}>
                          <h2 className="heading" style={{marginTop:30, color:' #464e51 '}}>About the scheme</h2>
                          {/* <p style={{marginTop:30, color:' #464e51 '}} >Working with the Council, we install solar panels on your property for FREE. We’ll also give you FREE energy-saving LED bulbs too. PLUS our Fair Market Price guarantees you’ll always pay less than the UK’s ‘Big 6’ energy suppliers (saving you an estimated &pound;166<sup>*</sup> a year.)</p> <p style={{marginTop:30, color:' #464e51 '}}>In addition, you will also get 18% to 25% rebate on your solar energy consumption. Amazing isn't it?</p> */}

                          <p style={{marginTop:30, color:' #464e51 '}} >Working with the Council, we install solar panels on your property for FREE. We’ll also give you FREE energy-saving LED bulbs too. PLUS our Fair Market Price guarantees you’ll always pay less than the UK’s ‘Big 6’ energy suppliers. </p> <p style={{marginTop:30, color:' #464e51 '}}>In addition, you will also get 18% to 25% rebate on your solar energy consumption. Amazing isn't it?</p>

                          
                        </div>
                      </div>
                    </div>
                  </Col>

                </Row>
              </Container>
            </section>


          </div>
        </div>
      </div>
    </div>






  );
}

export default Newcenteredstyle;

// //dangerouslySetInnerHTML={{ __html: topContent }} this makes html work somehow, try to avoid using it because it means DANGEEEEERRRR

