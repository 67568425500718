import React from 'react';

export interface ContentItem {
    leftContent?: any;
    rightContent?: any;
    component: any;
    topCurve?: any;
    backgroudColorSection?: any;
    topCurveFilter?: any;
    bottomCurve?: any;
    bottomCurveFilter?: any;  
    leftContentStyle?: any;
    rightContentStyle?: any;
    leftImage?: any;
    rightImage?: any;
    useMargin?: boolean;
    faqs?: Faq[];
    cardColor?: string;
    help?: Help[];
    backgroundImage?: any;
    circle?: Circle[];
    link?: any;
    sectionStyle?: React.CSSProperties;
    contract?: any;
    vidSource?: any;
    bottomImage?: any;
    bottomCurvePeopleMobile?: any,
    bottomCurvePeopleDesktop?: any,
}

export enum FAQcategory {
    BILLING = 'billing',
    SCHEME = 'scheme',
    PAYMENTS = 'payments',
    SYSTEM = 'system',
    SUPPLIER = 'supplier',
    COMPLAINTS = 'complaints',
    MISC = 'misc',
}

export interface Faq {
    question: string; 
    answer: string;
    category: FAQcategory[];
}

export interface Help {
    helpImage: any; 
    helpCategory: string;
    helpLink: any;
    faqCategory: FAQcategory;
}

export interface Circle {
    circleImage?: any; 
    circleTitle?: string;
    circleSubtitle?: any;
    circleLink?: any;
}