import React, { useState } from 'react';
import '../../index.css';
import '../../stoke.css';
import '../../bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ContentItem } from '../../types';
import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'

const bill1 = require('../../img/bill1.jpg');
const bill2 = require('../../img/bill2.jpg');

const contracts = {
  left: 'https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=211cc798-cf85-4033-8189-cc14cd95d15a&env=na3&acct=5efc5ef9-8040-4151-a79b-96e354f2aed6&v=2',
  right: 'https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=53db0a89-63ae-4eb2-b5a7-7707abac6ded&env=na3&acct=5efc5ef9-8040-4151-a79b-96e354f2aed6&v=2',
};

const Iframestyle: React.FC<ContentItem> = ({
  leftContent,
  rightContent,
  topCurve,
  topCurveFilter,
  backgroudColorSection,
  bottomCurveFilter,
  bottomCurve,
  leftContentStyle,
  rightContentStyle,
  leftImage,
  rightImage,
  useMargin = false,
  sectionStyle,
  contract,
}) => {

  const marginContent = React.useMemo(
    () => bottomCurve && useMargin ? { marginTop: "-130px" } : undefined
    , []);

  const sectionRef = React.useRef<HTMLDivElement | null>(null);

  const onScrollIntoView = () => {
    let position: DOMRect | undefined = sectionRef?.current?.getBoundingClientRect();

    // checking for partial visibility
    if (position && (position.top < window.innerHeight && position.bottom >= 0)) {
      sectionRef?.current?.classList.add('animation-end');
      window.removeEventListener('scroll', onScrollIntoView);
    }
  }

  React.useEffect(() => {
    onScrollIntoView();
    window.addEventListener('scroll', onScrollIntoView);
  }, []);

  const [iFrameContent, setiFrameContent] = React.useState<any>(null);

  const onLeftButtonClick = React.useCallback(() => {
    setiFrameContent(contracts.left);
  }, []);

  const onRightButtonClick = React.useCallback(() => {
    setiFrameContent(contracts.right);
  }, []);

  return (
    <div id='first-section-of-page'>
      <div className="content site-content">
        <div className="primary content-area">
          <div className="main site-main" style={backgroudColorSection}>
            <img src={topCurve} style={topCurveFilter}/>
            <Container className='animation-start' ref={sectionRef}>
              <Row >
                  <div className="section-content"  >
                        <div className="section-content__inner">
                            <div 
                              className="section-text section-text--wide fadeInTop fadeInTop--active" 
                              style={leftContentStyle}
                            >
                                {
                                  leftContent && <div dangerouslySetInnerHTML={{ __html: leftContent }} />
                                }
                            </div>
                            <Row >
                                <Col md="6" >
                                  <Button 
                                    className="download-button--dark-purple" 
                                    style={{backgroundColor:"#a7226e"}}
                                    onClick={onLeftButtonClick}
                                  >   ⠀
                                  Right-to-Buy  ⠀
                                  </Button>
                                </Col>
                                <Col md="6" >
                                  <Button 
                                    className="download-button--dark-purple" 
                                    style={{backgroundColor:"#a7226e"}} 
                                    onClick={onRightButtonClick}
                                  > 
                                  Council Tenants
                                  </Button>
                                </Col>
                            </Row>

                        </div>
                  </div>
              </Row>

              <Row style={{ padding: "40px" }}>
                  <div className="container-image-popup">
                    {
                      iFrameContent &&
                      <iframe id="dswpf" className="form_cont" src={iFrameContent}/>
                    }
                  </div>
              </Row>
            </Container>
            <img src={bottomCurve} style={bottomCurveFilter} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Iframestyle;

