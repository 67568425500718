import React, { useState } from 'react';
import '../../index.css';
import '../../stoke.css';
import '../../bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ContentItem } from '../../types';
import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'


const etdesktop = require('../../img/et-desktop.png');
const etmobile = require('../../img/et-mobile-345.png');
const plus = require('../../img/plus.png');

const MyVerticallyCenteredModal: React.FC<any> = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style= {{color:"black"}}>
          {props.text}
        </p>
      </Modal.Body>
    </Modal>



  );
}

const modalContent: Record<string, any> = {
  shower: {
    title: 'Take shorter showers -  £6 a year',
    text: 'You can save by cutting down your shower by one minute. A few small changes could add up to big savings in both energy and money.',
    top: 412,
    left: 262,
    topmobile:216,
    leftmobile:77,
  },
  lamp: {
    title: 'Remember to switch off your lights -  £14 a year',
    text: 'You can save energy simply by turning off your lights when you’re not in the room. Now that’s a bright idea.',
    top: 493,
    left: 500,
    topmobile:265,
    leftmobile:261,
  },
  thermostat: {
    title: 'Adjust your thermostat - £80 a year',
    text: 'Did you know that turning down your thermostat by only 1 degree can provide big savings in both energy and money. You’ll notice the difference in savings, not the temperature.',
    top: 437,
    left: 627,
    topmobile:497,
    leftmobile:74,
  },
plug: {
    title: 'Switch off your plugs - £30 a year',
    text: 'Remembering to turn off your appliances when they are in standby mode is another simple way to save money. Why pay for energy when you’re not even using it?',
    top: 448,
    left: 792,
    topmobile:543,
    leftmobile:149,
  },
washing: {
    title: 'Adjust your washing machine - £6 a year',
    text: 'Set your washing machine to 30 degrees rather than higher temperatures to save on your energy bills. There are plenty of detergents designed to work perfectly at a lower temperature.',
    top: 544,
    left: 762,
    topmobile:638,
    leftmobile:134,
},
light:{
    title: 'Switch your bulbs to LEDs - £46 a year',
    text: 'You can pick them for free by joining the Community Energy Scheme',
    top: 731,
    left: 484,
    topmobile:834,
    leftmobile:282,
},
kettle:{
    title: 'Don’t overfill your kettle - £13 a year',
    text: 'Save money each year simply by filling it up with just the amount you need.',
    top: 865,
    left: 670,
    topmobile:1165,
    leftmobile:55,
  },
microwave: {
    title: 'The microwave is your friend - £15 a year',
    text: 'Bake cakes, make jam and sanitise your kitchen sponges; your microwave is one of the most energy efficient appliances in your house – get creative!',
    top: 850,
    left: 931,
    topmobile:1142,
    leftmobile:254,
  },
clothesline: {
    title: 'Make the most of summer - £30 a year',
    text: 'Dry your clothes on a clothesline in the summer instead of tumble dryer to save even more money.',
    top: 803,
    left: 1029,
    topmobile:1438,
    leftmobile:129,
},

};


const Promostyle: React.FC<ContentItem> = ({
  leftContent,
  rightContent,
  topCurve,
  topCurveFilter,
  backgroudColorSection,
  bottomCurveFilter,
  bottomCurve,
  leftContentStyle,
  rightContentStyle,
  leftImage,
  rightImage,
  useMargin = false,
  sectionStyle,
}) => {

  const marginContent = React.useMemo(
    () => bottomCurve && useMargin ? { marginTop: "-130px" } : undefined
    , []);

  const sectionRef = React.useRef<HTMLDivElement | null>(null);

  const onScrollIntoView = () => {
    let position: DOMRect | undefined = sectionRef?.current?.getBoundingClientRect();

    // checking for partial visibility
    if (position && (position.top < window.innerHeight && position.bottom >= 0)) {
      sectionRef?.current?.classList.add('animation-end');
      window.removeEventListener('scroll', onScrollIntoView);
    }
  }

  const [selectedModal, setSelectedModal] = React.useState<any>(undefined);


  React.useEffect(() => {
    onScrollIntoView();
    window.addEventListener('scroll', onScrollIntoView);
  }, []);

  return (
    <div id='first-section-of-page' >
      <section className="energy-tips-section" style={backgroudColorSection}>
      <img src={topCurve} style={topCurveFilter} />
      <Container className="section"  >
          <Row className='animation-start' ref={sectionRef} >
            <Col md="12"  className="col-md-12">
              
              {/* START  DESKTOP */}
              <div className="energy-tips-house-container energy-tips-house-container-desktop">
                
                {/* STARTS HOUSE IMAGE */}
                <img src={etdesktop} className="energy-tips-house-image" />
                {/* ENDS HOUSE IMAGE */}
                
                
                {/* STARTS BUTTONS  */}
                {
                  Object.keys(modalContent).map((key: string) => {
                    const button = modalContent[key];
                    return (
                      <a 
                        key={key}
                        href="#"
                        data-featherlight={button.id} 
                        onClick={(e: any) => {
                          e.preventDefault();
                          setSelectedModal(button);  
                        }}
                      >
                          <img 
                            src={plus} 
                            className="energy-tips-plus" 
                            style={{top: button.top, left: button.left}} 
                          />
                      </a>
                    )
                  })
                }
              {/* ENDS BUTTONS */}


              </div>
              {/* END  DESKTOP */}
              
              {/* START  MOBILE */}
              <div className="energy-tips-house-container energy-tips-house-container-mobile" >
                
                {/* START HOUSE */}
                <img src={etmobile} className="energy-tips-house-image" />
                {/* END HOUSE */}

                {/* START BUTTONS */}
                {
                  Object.keys(modalContent).map((key: string) => {
                    const button = modalContent[key];
                    return (
                      <a 
                        key={key}
                        href="#"
                        data-featherlight={button.id} 
                        onClick={(e: any) => {
                          e.preventDefault();
                          setSelectedModal(button);  
                        }}
                      >
                          <img 
                            src={plus} 
                            className="energy-tips-plus" 
                            style={{top: button.topmobile, left: button.leftmobile}} 
                          />
                      </a>
                    )
                  })
                }
                {/* END BUTTONS */}
                
              </div>
              {/* END MOBILE */}
              

              
            </Col>
          </Row>
          <MyVerticallyCenteredModal
            show={Boolean(selectedModal)}
            onHide={() => setSelectedModal(undefined)}
            title={selectedModal?.title || ''}
            text={selectedModal?.text || ''}
          />
        </Container>
        <img src={require ('../../img/symmetrical-bottom-curve-green.png')} />
      </section>
    </div>
  );
}
export default Promostyle;