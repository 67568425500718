import { ContentItem } from "../../types";
import { images, getFilterStyle, colors, Colors, getBgColorStyle, getTextColorStyle } from "../../theme";
import NTPstyle from "../../components/styles/NTPstyle";
import Imagestyle from "../../components/styles/Imagestyle";
import Formstyle from "../../components/styles/Formstyle";
import Circlestyle from "../../components/styles/Circlestyle";
import Buttonstyle from "../../components/styles/Buttonstyle";
import Bannerstyle from "../../components/styles/Bannerstyle";
import Narrowstyle from "../../components/styles/Narrow";
import Singlestyle from "../../components/styles/Singlestyle";
import Narrowstyle2 from "../../components/styles/Narrow2";

const videoSource = "https://www.w3schools.com/tags/movie.mp4"

const clouds = require('../../img/video.mp4');

const newcustomer = require('../../img/newcustomer.png');
const tariffs = require('../../img/tariffs.png');
// const handglobe = require('../img/hand-globe.png');

const photobg2 = require('../../img/2-photo-bg2.jpg');

const katie = require('../../img/katie.jpg');
const handsun = require('../../img/hand-sun.png');
const coinssmall = require('../../img/coinssmall.png');
const logo = require('../../img/ces-logo.png');
const keys2 = require('../../img/ces-key2.png');
const house = require('../../img/illustration.png');
const plug = require('../../img/plug.png');
const ceshouse = require('../../img/ces-house.png');
const community = require('../../img/community.png');
const pig = require('../../img/pig.png');
const people = require('../../img/people.png');

const fair = require('../../img/fair.png');
const match = require('../../img/match.png');
const rebate = require('../../img/rebate.png');

const hello = require('../../img/hello.png');
const purse = require('../../img/purse.png');
const talk = require('../../img/talk.png');
const grid = require('../../img/grid.png');
const meter = require('../../img/meter-1.png');
const led = require('../../img/led.png');
const payments = require('../../img/payments2.png');

export const config: ContentItem[] = [


  {

    leftImage: talk,

    rightContent: `	<img src=${purse} style = "max-width: 120px;" /> <h2 class="heading heading--large" ">Ready to learn about the scheme?</h2>
    <p ">Here are some of the main key points to help you along your solar journey. </p> `,
    topCurve: images.topcurvedarkgreen,
    topCurveFilter: getFilterStyle(Colors.none),
    component: Narrowstyle,
    backgroudColorSection: getBgColorStyle(Colors.teal),
    bottomCurve: images.asymmetricalCurveBottom,
    bottomCurveFilter: getFilterStyle(Colors.blue),
    leftContentStyle: getTextColorStyle(Colors.white),
    rightContentStyle: getTextColorStyle(Colors.blue),
    useMargin: false,

  },

  {
    topCurve: images.asymmetricalCurveTop,
    topCurveFilter: getFilterStyle(Colors.blue),
    leftContent: `	<h2 class="heading heading--large">Your energy</h2>
    <p>Before we can move forward it is important to clarify that members of the scheme receive energy from two sources: </br> • The main portion is renewable energy generated by the panels on their roof and billed by the Community Energy Scheme. </br> • The rest comes from the national grid and it is billed by their grid supplier of choice.</p>`,
    rightContent: `<img src=${grid} />`,

    component: NTPstyle,
    backgroudColorSection: getBgColorStyle(Colors.blue),
    bottomCurve: images.asymmetricalCurveBottom,
    bottomCurveFilter: getFilterStyle(Colors.teal),
    leftContentStyle: getTextColorStyle(Colors.white),
    rightContentStyle: getTextColorStyle(Colors.white),
    useMargin: true,

  },

  {

    leftImage: fair,

    rightContent: `<h2 class="heading heading--large">Fair Market Price Guarantee</h2>	<p>Your solar energy rate is protected under our Fair Market Price promise.


    This means that your price is guaranteed to stay at (or below) the average of the Standard Variable Tariff of the Big 6* (always). </p>
                          
                       <p>   We don’t have confusing standing charges; you only pay for the energy you use and everyone pays the same rate .
    </p>`,
    // topCurve: images.symmetricalCurveTop,
    // topCurveFilter: getFilterStyle(Colors.magenta),
    component: Narrowstyle,
    backgroudColorSection: getBgColorStyle(Colors.teal),
    // bottomCurve: images.asymmetricalCurveBottom,
    // bottomCurveFilter: getFilterStyle(Colors.blue),
    leftContentStyle: getTextColorStyle(Colors.white),
    rightContentStyle: getTextColorStyle(Colors.blue),
    useMargin: false,

  },


  {

    leftContent: `
    <h2 class="heading heading--large">
        Price Match Promise
    </h2>	
    <p>
        As mentioned before the energy generated by the panels installed on your home will be charged to you by the Community Energy Scheme, independently of who you buy your grid energy grid from. But what if your grid supplier offers you a better rate?
    </p>
    <p>
        Here is where our Price Match Guarantee kicks in. If your grid supplier offers you a price cheaper than the Fair Market Price for your electricity you can contact us and we will match it!
    </p>
    `,
    rightImage: match,
    component: Narrowstyle2,
    backgroudColorSection: getBgColorStyle(Colors.teal),
    leftContentStyle: getTextColorStyle(Colors.white),
    rightContentStyle: getTextColorStyle(Colors.blue),
    useMargin: false,

  },
  {
    leftImage: rebate,
    rightContent: `
    <h2 class="heading heading--large">
        Solar Rebate
    </h2>	
    <p> 
        All members who have no overdue balance on their account or those that have an agreed payment plan or active direct debit will receive a rebate as a bonus for being part of the Community Energy Scheme. This means that you will receive a bonus in the form of a credit against your bill. The total of the rebate will be 18% of your Solar bill if you have storage and solar panels installed and 25% if you only have solar panels.
    </p>`,
    // topCurve: images.symmetricalCurveTop,
    // topCurveFilter: getFilterStyle(Colors.magenta),
    component: Narrowstyle,
    backgroudColorSection: getBgColorStyle(Colors.teal),
    bottomCurve: images.asymmetricalCurveBottom,
    bottomCurveFilter: getFilterStyle(Colors.orange),
    leftContentStyle: getTextColorStyle(Colors.white),
    rightContentStyle: getTextColorStyle(Colors.blue),
    useMargin: false,

  },

  {
    topCurve: images.asymmetricalCurveTop,
    topCurveFilter: getFilterStyle(Colors.orange),
    leftContent: `	
      <h2 class="heading heading--large">
          How will you measure my consumption?
      </h2>
      <p>
          For this we will install a solar electricity sub-meter which will operate in parallel to your grid energy meter. This means that the smart meter installed by your supplier will not be affected, we just install an additional meter that will allow us to measure how much solar energy is generated by your home and how much you consume.
      </p>
    `,
    rightContent: `<img src=${plug} />`,
    component: NTPstyle,
    backgroudColorSection: getBgColorStyle(Colors.blue),
    bottomCurve: images.symmetricalCurveBottom,
    bottomCurveFilter: getFilterStyle(Colors.yellow),
    leftContentStyle: getTextColorStyle(Colors.white),
    rightContentStyle: getTextColorStyle(Colors.white),
    useMargin: true,
  },
  {
    leftContent: `<img src=${led} />`,
    rightContent: `
      <h2 class="heading heading--large" style="color:#464e51;">
          Other perks
      </h2>
      <p style="color:#464e51; ">
          In addition to these savings, you will receive a free lifetime supply of money-saving LED lightbulbs for your home. This means not only will you be charged less for your electricity, you will also use less!  
      </p> 
    `,
    component: NTPstyle,
    backgroudColorSection: getBgColorStyle(Colors.yellow),
    bottomCurve: images.asymmetricalCurveBottom,
    bottomCurveFilter: getFilterStyle(Colors.blue),
    leftContentStyle: getTextColorStyle(Colors.white),
    rightContentStyle: getTextColorStyle(Colors.blue),
    useMargin: false,
  },
  {
    leftContent: `
      <h2 class="heading heading--large">
          Billing
      </h2>	
      <p>
          If you opt for email billing, you will receive it monthly. Whereas if you receive your bill via post, you will receive them quarterly. We only bill you for your solar usage, these are separate from your electricity grid supplier.  </br> 
          We are dedicated to saving the planet, and always recommend switching to a greener option. To opt into a paperless option please contact our customer services team to add your email to our system. 
      </p> 
      <a class="download-button download-button--dark-purple" href="/paperless">
          Go Paperless
      </a>
    `,
    rightImage: payments,
    // topCurve: images.symmetricalCurveTop,
    // topCurveFilter: getFilterStyle(Colors.magenta),
    component: Narrowstyle2,
    backgroudColorSection: getBgColorStyle(Colors.blue),
    bottomCurve: images.asymmetricalCurveBottom,
    bottomCurveFilter: getFilterStyle(Colors.teal),
    leftContentStyle: getTextColorStyle(Colors.white),
    rightContentStyle: getTextColorStyle(Colors.blue),
    useMargin: false,

  },






  {
    // topCurve: images.symmetricalCurveTop,
    // topCurveFilter: getFilterStyle(Colors.blue),

    leftContent: `<img src=${hello} />`,
    rightContent: `<h2 class="heading heading--large" >We’re here to help</h2>
    <p >Got a question?<br>
    Need some help?<br>
    You can give us a call on <a href="tel:01782438427" style="text-decoration:underline!important;"><span style="font-weight:500;">01782 438 427</span></a><br>
    Or drop us a line at <a href="mailto:stoke@communityenergyscheme.com" style="text-decoration:underline!important;font-weight:500;">stoke@communityenergyscheme.com </br></a> Alternatively, check out our help section.</p><a class="download-button download-button--dark-purple" href=${`/faq`} >Find help</a> `,

    component: NTPstyle,
    backgroudColorSection: getBgColorStyle(Colors.teal),
    leftContentStyle: getTextColorStyle(Colors.grey),
    rightContentStyle: getTextColorStyle(Colors.grey),
    bottomCurve: images.symmetricalbottomcurvedarkgreen,
    bottomCurveFilter: getFilterStyle(Colors.none),
  },




];