import React from 'react';
import '../../index.css';
import '../../stoke.css';
import '../../bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ContentItem, FAQcategory } from '../../types';

const Helpstyle: React.FC<ContentItem> = ({
    leftContent,
    rightContent,
    topCurve,
    topCurveFilter,
    backgroudColorSection,
    bottomCurveFilter,
    bottomCurve,
    leftContentStyle,
    rightContentStyle,
    leftImage,
    rightImage,
    useMargin = true,
    help = [],
    cardColor,
}) => {

    const marginContent = React.useMemo(
        () => bottomCurve && useMargin ? { marginTop: "-130px" } : undefined
        , []);

    
        const sectionRef = React.useRef<HTMLDivElement | null>(null);
    
        const onScrollIntoView = () => {
          let position: DOMRect | undefined = sectionRef?.current?.getBoundingClientRect();
        
          // checking for partial visibility
          if(position && (position.top < window.innerHeight && position.bottom >= 0)) {
            sectionRef?.current?.classList.add('animation-end');
            window.removeEventListener('scroll', onScrollIntoView);
          }
        }
      
        React.useEffect(() => {
          onScrollIntoView();
          window.addEventListener('scroll', onScrollIntoView);
        }, []);

    // when you click the botton it will create an event that says pass the data with this name in this case chanegFAQcategory and dispatchEvent

    const onClick = (category: FAQcategory) => {
        var event = new CustomEvent("changeFAQCategory", {
            detail: category,
          });
          window.dispatchEvent(event);
    }

    return (

        <div id='first-section-of-page'>
            <div className="content site-content">
                <div className="primary content-area">
                    <div className="main site-main" style={backgroudColorSection}>

                        <div className="section help-section-22">
                            <Container>
                                <Row className='animation-start' ref={sectionRef}>
                                    {
                                        help.map((help, index) => {
                                            return (
                                                <Col sm="6" md="4" className=" col-xs-6 col-md-4 help-grid-item" >
                                                    <div className="section-content fadeInTop fadeInTop--active" >
                                                        <img src={help.helpImage} className="section-image--help"  />
                                                        <h2 className="heading heading--small">{help.helpCategory}</h2>
                                                        <a className="download-button download-button--dark-purple" href='#faq'  onClick={() => onClick(help.faqCategory)}>Find out more</a>
                                                    </div>
                                                </Col>
                                            )
                                    })}
                                </Row>

                            </Container>
                            <img src={bottomCurve} style={bottomCurveFilter} id='faq' />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}




export default Helpstyle;

// //dangerouslySetInnerHTML={{ __html: topContent }} this makes html work somehow, try to avoid using it because it means DANGEEEEERRRR

