import React, { useState } from 'react';
import '../../index.css';
import '../../stoke.css';
import '../../bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ContentItem } from '../../types';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';



const Singlestyle: React.FC<ContentItem> = ({
    leftContent,
    rightContent,
    topCurve,
    topCurveFilter,
    backgroudColorSection,
    bottomCurveFilter,
    bottomCurve,
    leftContentStyle,
    rightContentStyle,
    leftImage,
    rightImage,
    useMargin,
    sectionStyle,
}) => {


    const sectionRef = React.useRef<HTMLDivElement | null>(null);

    const onScrollIntoView = () => {
        let position: DOMRect | undefined = sectionRef?.current?.getBoundingClientRect();

        // checking for partial visibility
        if (position && (position.top < window.innerHeight && position.bottom >= 0)) {
            sectionRef?.current?.classList.add('animation-end');
            window.removeEventListener('scroll', onScrollIntoView);
        }
    }

    React.useEffect(() => {
        onScrollIntoView();
        window.addEventListener('scroll', onScrollIntoView);
    }, []);

    return (

        <div className="content site-content" style={sectionStyle}  >
            <div className="primary content-area" >
                <div className="main site-main"
                    style={backgroudColorSection}>

                    <div className='section help-child-section-1'
                        style={{ ...leftContentStyle, marginTop: -1 }}><img src={topCurve}
                            style={topCurveFilter} />

                        <Container>
                            <Row className='animation-start' ref={sectionRef}>






                                <div className="section-content" style={{paddingLeft:30, paddingRight:30}} >
                                    <div className="section-content__inner">
                                        <div className="section-text section-text--wide fadeInTop fadeInTop--active"
                                            style={leftContentStyle}>
                                            {leftContent && (
                                                <div dangerouslySetInnerHTML={{ __html: leftContent }} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </Container>
                        <img src={bottomCurve} style={bottomCurveFilter} />
                    </div>
                </div>
            </div>
        </div>
    );
}





export default Singlestyle;

// //dangerouslySetInnerHTML={{ __html: topContent }} this makes html work somehow, try to avoid using it because it means DANGEEEEERRRR

