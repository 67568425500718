import React, { useState } from 'react';
import '../../index.css';
import '../../stoke.css';
import '../../bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ContentItem } from '../../types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'

const keys2 = require('../../img/ces-key2.png');
const allyouneedtoknow = require('../../img/allyouneedtoknow.png');
const letter = require('../../img/letter.png');
const credit = require('../../img/credit.png');
const coins = require('../../img/coins.png');
const telephone = require('../../img/telephone.png');
const buildings = require('../../img/buildings.png');
const talkyellow = require('../../img/talk.png');

const MyVerticallyCenteredModal: React.FC<any> = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ color: "black" }}>
          {props.text}
        </p>
      </Modal.Body>
    </Modal>
  );
}


const modalContent = {
  button14: {
    title: 'Price Match Promise',
    text: 'How can we guarantee that you will always pay the best rate? Because if someone offers you a better price you just need to contact us with your bill and we will match it! ',
  },
  button15: {
    title: 'Solar Rebate',
    text: 'All members without overdue balance or with a payment plan in place will receive a monthly rebate of 18% of their Solar bill if they have storage installed and 25% if they only have solar panels.'
  },
  button16: {
    title: 'Membership perks',
    text: 'As a member of the scheme you will receive a £50 discount off your first electrical bill. In addition to this you will receive free money-saving LED lightbulbs for your home. This means not only will you be charged less for your electricity, you will also use less!'
  },
}

const Narrowwpopstyle: React.FC<ContentItem> = ({
  leftContent,
  rightContent,
  topCurve,
  topCurveFilter,
  backgroudColorSection,
  bottomCurveFilter,
  bottomCurve,
  leftContentStyle,
  rightContentStyle,
  leftImage,
  rightImage,
  useMargin = true,
  link,
  sectionStyle,
}) => {

  const marginContent = React.useMemo(
    () => bottomCurve && useMargin ? { marginTop: "70px" } : undefined
    , []);

  const sectionRef = React.useRef<HTMLDivElement | null>(null);

  const onScrollIntoView = () => {
    let position: DOMRect | undefined = sectionRef?.current?.getBoundingClientRect();

    // checking for partial visibility
    if (position && (position.top < window.innerHeight && position.bottom >= 0)) {
      sectionRef?.current?.classList.add('animation-end');
      window.removeEventListener('scroll', onScrollIntoView);
    }
  }


  const [selectedModal, setSelectedModal] = React.useState<any>(undefined);

  React.useEffect(() => {
    onScrollIntoView();
    window.addEventListener('scroll', onScrollIntoView);
  }, []);

  return (
    <div className="content site-content">
      <div className="primary content-area">
        <div className="main site-main"
          style={backgroudColorSection}>

          <div className='section help-child-section-1' style={leftContentStyle}><img src={topCurve} style={topCurveFilter} />
            <Container>
              <Row className='animation-start' ref={sectionRef}>
                <Col md="6">
                  <div className="section-content">
                    <div className="section-content__inner">
                      <img src={leftImage}
                        className="section-image fadeInTop fadeInTop--active"
                      />
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="section-content">
                    <div className="section-content__inner">
                      <div className="section-text section-text--wide fadeInTop fadeInTop--active">
                        <div>
                          <h2 className="heading heading--alt">The lowest rate in the market, locked down for years to come</h2>

                          <p>Upon approval of membership you will have all your installation costs covered and benefit from BIG savings from day one. <p></p>This is because your membership will allow you to buy the energy generated by the panels on your roof at a price that is guaranteed to always work out in your favour. Seriously.<br/> For as long as you live in the property you will have access to the absolute, hands-down <a className="forceUnderline" onClick={() => setSelectedModal(modalContent.button14)}>best price per unit</a>. On top of that you will get <a className="forceUnderline" onClick={() => setSelectedModal(modalContent.button15)}>18% to 25% of your bill back as a rebate</a> and  <a className="forceUnderline" onClick={() => setSelectedModal(modalContent.button16)}> free LEDs for life. </a> </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <MyVerticallyCenteredModal
                    show={Boolean(selectedModal)}
                    onHide={() => setSelectedModal(undefined)}
                    title={selectedModal?.title || ''}
                    text={selectedModal?.text || ''}
                  />
            </Container>
            <img src={bottomCurve} style={bottomCurveFilter} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Narrowwpopstyle;

//dangerouslySetInnerHTML={{ __html: topContent }} this makes html work somehow, try to avoid using it because it means DANGEEEEERRRR

