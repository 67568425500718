import { ContentItem } from "../../types";
import { images, getFilterStyle, colors, Colors, getBgColorStyle, getTextColorStyle } from "../../theme";
import NTPstyle from "../../components/styles/NTPstyle";
import Imagestyle from "../../components/styles/Imagestyle";
import Formstyle from "../../components/styles/Formstyle";
import Circlestyle from "../../components/styles/Circlestyle";
import Buttonstyle from "../../components/styles/Buttonstyle";
import Bannerstyle from "../../components/styles/Bannerstyle";
import Narrowstyle from "../../components/styles/Narrow";
import Singlestyle from "../../components/styles/Singlestyle";
import Narrowstyle2 from "../../components/styles/Narrow2";

const videoSource = "https://www.w3schools.com/tags/movie.mp4"

const clouds = require('../../img/video.mp4');

const newcustomer = require('../../img/newcustomer.png');
const tariffs = require('../../img/tariffs.png');
// const handglobe = require('../img/hand-globe.png');

const photobg2 = require('../../img/2-photo-bg2.jpg');

const katie = require('../../img/katie.jpg');
const handsun = require('../../img/hand-sun.png');
const coinssmall = require('../../img/coinssmall.png');
const logo = require('../../img/ces-logo.png');
const keys2 = require('../../img/ces-key2.png');
const house2 = require('../../img/house2.png');
const plug2 = require('../../img/plug.png');
const ceshouse = require('../../img/ces-house.png');
const community = require('../../img/community.png');
const pig = require('../../img/pig.png');
const people = require('../../img/people.png');

const fair = require('../../img/fair.png');
const match = require('../../img/match.png');
const rebate = require('../../img/rebate.png');

const hello = require('../../img/hello.png');
const directdebit = require('../../img/directdebit-1.png');
const talk = require('../../img/talk.png');
const grid = require('../../img/grid.png');
const meter = require('../../img/meter-1.png');
const led = require('../../img/led.png');
const family = require('../../img/family.png');
const phone = require('../../img/phone.png');

export const config: ContentItem[] = [




  {
    leftContent: `	<img src="${house2}" style="max-width:300px;" /> <h1 class="heading" style="margin-top:60px">Getting started with the Community
    Energy Scheme</h1>

    <p>Once you sign up, what
    exactly happens next?</p>
      
     <p>Here’s a summary of the
     most important steps of
     your sign-up journey.
</p> `,
    topCurve: images.topcurvedarkgreen,
    topCurveFilter: getFilterStyle(Colors.none),
    backgroudColorSection: getBgColorStyle(Colors.teal),
    leftContentStyle: { marginBottom: "0px" },
    component: Singlestyle,
    useMargin: false,

  },

  {

    leftImage: phone,

    rightContent: `<h2 class="heading heading--large">Within 2 days</h2>	<p>Within 2 days of signing up, we’ll be in touch
    to arrange the survey if we haven’t already
    done the survey before you signed up.
    </p>`,
    // topCurve: images.symmetricalCurveTop,
    // topCurveFilter: getFilterStyle(Colors.magenta),
    component: Narrowstyle,
    backgroudColorSection: getBgColorStyle(Colors.teal),
    // bottomCurve: images.asymmetricalCurveBottom,
    // bottomCurveFilter: getFilterStyle(Colors.blue),
    leftContentStyle: getTextColorStyle(Colors.white),
    rightContentStyle: getTextColorStyle(Colors.blue),
    useMargin: false,

  },


  {
    
    leftContent: `<h2 class="heading heading--large">...by day 10</h2>	<p>By day 10 a trained surveyor will have visited your property to 
    decide if solar panels can be fitted or not. 
    </p>`,

    rightImage: people ,
    // topCurve: images.symmetricalCurveTop,
    // topCurveFilter: getFilterStyle(Colors.magenta),
    component: Narrowstyle2,
    backgroudColorSection: getBgColorStyle(Colors.teal),
    // bottomCurve: images.asymmetricalCurveBottom,
    // bottomCurveFilter: getFilterStyle(Colors.blue),
    leftContentStyle: getTextColorStyle(Colors.white),
    rightContentStyle: getTextColorStyle(Colors.blue),
    useMargin: false,

  },

  {

    leftImage: directdebit,

    rightContent: `<h2 class="heading heading--large">...before 28 days</h2>	<p>Before 28 days of signing up, we will have completed your
    solar panel installation.
    </p>`,
    // topCurve: images.symmetricalCurveTop,
    // topCurveFilter: getFilterStyle(Colors.magenta),
    component: Narrowstyle,
    backgroudColorSection: getBgColorStyle(Colors.teal),

    leftContentStyle: getTextColorStyle(Colors.white),
    rightContentStyle: getTextColorStyle(Colors.blue),
    useMargin: false,

  },

  {

    leftContent: `<h2 class="heading heading--large">Within 30 days...</h2>	<p>Within 30 days of signing up with us, all the paperwork will be done, all your equipment will be in and you’re ready to save and smile, knowing you’re now
    using greener, cheaper energy.
    </p>`,

    rightImage: plug2 ,
    // topCurve: images.symmetricalCurveTop,
    // topCurveFilter: getFilterStyle(Colors.magenta),
    component: Narrowstyle2,
    backgroudColorSection: getBgColorStyle(Colors.teal),
    bottomCurve: images.asymmetricalCurveBottom,
    bottomCurveFilter: getFilterStyle(Colors.yellow),
    leftContentStyle: getTextColorStyle(Colors.white),
    rightContentStyle: getTextColorStyle(Colors.blue),
    useMargin: false,

  },









  {    
    // topCurve: images.symmetricalCurveTop,
    // topCurveFilter: getFilterStyle(Colors.teal),

    leftContent: `<img src=${hello} />`,
    rightContent: `<h2 class="heading heading--large" style="color:#464e51;">We’re here to help</h2>
    <p style="color:#464e51; ">Got a question?<br>
    Need some help?<br>
    You can give us a call on <a href="tel:01782438427" style="text-decoration:underline!important;"><span style="font-weight:500;">01782 438 427</span></a><br>
    Or drop us a line at <a href="mailto:stoke@communityenergyscheme.com" style="text-decoration:underline!important;font-weight:500;">stoke@communityenergyscheme.com </br></a> Alternatively, check out our help section.</p><a class="download-button download-button--dark-purple" href=${`/faq`} >Find help</a> `,

    component: NTPstyle,
    backgroudColorSection: getBgColorStyle(Colors.yellow),
    leftContentStyle: getTextColorStyle(Colors.grey),
    rightContentStyle: getTextColorStyle(Colors.grey),
  bottomCurve: images.symmetricalbottomcurvedarkgreen,  
  bottomCurveFilter: getFilterStyle(Colors.none),  
  },
 



];