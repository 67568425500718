import { ContentItem } from "../../types";
import { images, getFilterStyle, Colors, getBgColorStyle, getTextColorStyle, colors } from "../../theme";
import Buttonstyle from "src/components/styles/Buttonstyle";
import Singlestylewide from "src/components/styles/Singlestylewide";

const renewal = require('../../img/Renewals_infographic-1.png');
const keys2 = require('../../img/ces-key2.png');
const booklet = require('../../img/good/booklet.svg');
const howto = require('../../img/good/howto.svg');
const sun = require('../../img/sun.png');

export const config: ContentItem[] = [
  {
    leftContent: `	
    <h2 class="heading heading--large" >All the details...</h2> 
        <p >The Community Energy Scheme is committed to providing clear and transparent information to all our tenants. Check out the following links for a full copy of your terms and conditions.</p>
      `,
    topCurve: images.topcurvedarkgreen,
    topCurveFilter: getFilterStyle(Colors.none),
    backgroudColorSection: getBgColorStyle(Colors.blue),
    component: Singlestylewide,
    useMargin: true,
  },
  {
    leftContent: `	
    <div>
      <div>
        <div>
          <img src="${renewal}" style="max-height:150px;" />
          <h3 class="heading" style="margin-top:1em;" >Power Purchase Agreement (PPA)</h3>
          <p>
            The PPA is the contract between you and the scheme for the provision of solar energy, as well as your contractual
            agreement to pay for the power used within your home.
          </p>
        </div>
        <div>
          <h4 style="margin-top:1.3em;">(PPA from 28/05/2019 to 31/10/2022)</h4>
          <p>
            If you signed your PPA during this period and did not agree to the new PPA, this will be the PPA relevant to you.
          </p>
          <a class="download-button download-button--dark-purple"
            href="https://community-energy-scheme-documents.netlify.app/ppa_pre22.pdf"
            target="blank">
            View PDF
          </a>
        </div>
        <div>
          <h4 style="margin-top:1.3em;">(PPA from 01/11/2022)</h4>
          <a 
            style="margin-top:1em;" class="download-button download-button--dark-purple"
            href="https://community-energy-scheme-documents.netlify.app/ppa_post22.pdf"
            target="blank"
          >
            View PDF
          </a>
        </div>
        <div>
          <h4 style="margin-top:1.3em;">(PPA from 01/10/2023)</h4>
          <a 
            style="margin-top:1em;" class="download-button download-button--dark-purple"
            href="https://community-energy-scheme-documents.netlify.app/ppa-23.pdf"
            target="blank"
          >
            View PDF
          </a>
        </div>
      </div>
      <div>
        <img src="${keys2}" style="max-height:150px; margin-top:4em;" />
        <h3 class="heading" style="margin-top:1em;">Tenancy Variation Agreement (TVA)</h3>
        <p>
          This document is an add on to your tenancy agreement with Stoke City Council. This allows us to have access to your property to install and maintain the system.
        </p>
        <a 
          class="download-button download-button--dark-purple"
          href="https://community-energy-scheme-documents.netlify.app/tva.pdf"
          target="blank"
          style="margin-bottom:2em;"
        >
          View PDF
        </a>
      </div>
    </div>
    `,
    topCurveFilter: getFilterStyle(Colors.none),
    backgroudColorSection: getBgColorStyle(Colors.blue),
    component: Singlestylewide,
    useMargin: true,
    bottomCurveFilter: getFilterStyle(Colors.skyblue),
    bottomCurve: images.symmetricalCurveBottom,
  },
  {
    leftContent: `	
      <h3 class="heading" style="color:#464e51;margin-top:2em;margin-bottom:1em;">Looking for more information?</h3>
      <p style="color:#464e51;">
        Check out these guides to help you during your journey with the Community Energy Scheme
      </p>
      `,
    topCurveFilter: getFilterStyle(Colors.orange),
    backgroudColorSection: getBgColorStyle(Colors.skyblue),
    component: Singlestylewide,
    useMargin: true,
  },
  {
    leftImage:booklet ,
    leftContent: `
      <div>
        <h2 style="color:#464e51;" class="heading">Community Energy Scheme <br> Information Booklet</h1>
        <p style="color:#464e51;">
          Everything you need to know about the Community Energy Scheme in one single document. 
        </p>
        <a 
          class="download-button download-button--dark-purple" 
          href="https://community-energy-scheme-documents.netlify.app/booklet-23.pdf" 
          target="blank" 
        >
          Learn more
        </a>
      </div>

    `,
    rightImage:howto ,
    component: Buttonstyle,
    backgroudColorSection: getBgColorStyle(Colors.skyblue),
    leftContentStyle: getTextColorStyle(Colors.white),
    rightContentStyle: getTextColorStyle(Colors.white),
    rightContent: `
    <div>
      <h2 style="color:#464e51;" class="heading">Community Energy Scheme <br> How To Guide</h1>
      <p style="color:#464e51;">
        This includes troubleshooting instructions, energy tips and other helpful information.
      </p>
      <a 
        class="download-button download-button--dark-purple" 
        href="https://community-energy-scheme-documents.netlify.app/how-to-guide-23.pdf" 
        target="blank" 
      >
        Learn more
      </a>
    </div>
    `,
    useMargin: true,
    bottomCurve: images.symmetricalCurveBottom,
    bottomCurveFilter: getFilterStyle(Colors.blue),
  },
  {
    leftContent: `	
      <h3 class="heading" style="color:#fff;margin-top:2em;margin-bottom:1em;">Exit PPA contract with fees Form</h3>
      <a 
        class="download-button download-button--dark-purple"
        href="https://community-energy-scheme-documents.netlify.app/end-of-supply-form-2023.pdf"
        target="blank"
        style="margin-bottom:2em;"
      >
        View PDF
      </a>
      `,
    leftContentStyle: getTextColorStyle(Colors.white),
    topCurveFilter: getFilterStyle(Colors.none),
    backgroudColorSection: getBgColorStyle(Colors.blue),
    component: Singlestylewide,
    useMargin: true,
    bottomCurveFilter: getFilterStyle(Colors.none),
    bottomCurve: images.symmetricalbottomcurvedarkgreen,
  },
];