import { ContentItem } from "../../types";
import { images, getFilterStyle, Colors, getBgColorStyle, getTextColorStyle, colors } from "../../theme";

import Singlestyle from "../../components/styles/Singlestyle";
import Narrowstyle2 from "../../components/styles/Narrow2";
import NTPstyle from "../../components/styles/NTPstyle";
import Iframestyle from "../../components/styles/Iframestyle";
import Calendarstyle from "../../components/styles/Calendarstyle";
import Narrowwpopstyle from "../../components/styles/Narrowwpopstyle";
import Buttonstyle from "../../components/styles/Buttonstyle";

// const newcustomer = require('../../img/newcustomer.png');
const tariffs = require('../../img/tariffs.png');
// const handglobe = require('../../img/hand-globe.png');
 
const coinsmany = require('../../img/coinsmany.png');
const cesfamily = require('../../img/ces-family.png');
const renewal = require('../../img/renewals.png');
const keys2 = require('../../img/ces-key2.png');
const house2 = require('../../img/house2.png');
const phone = require('../../img/phone.png');
const hello = require('../../img/hello.png');
const directdebit = require('../../img/directdebit.png');
const plug2 = require('../../img/plug.png');
const ceshouse = require('../../img/ces-house.png');
const ceslogo = require('../../img/ces-logo.png');
const allyouneedtoknow = require('../../img/allyouneedtoknow.png');

export const config: ContentItem[] = [




  {
    topCurve: images.topcurvedarkgreen,
    topCurveFilter: getFilterStyle(Colors.none),
    component: Calendarstyle,
    leftContent: `	<img src="${directdebit}" style="max-width:370px;" /> <h1 class="heading" >Let's book you in!</h1>

    <p>Pick a date at a time of your convenience and a member of the team will be in touch within 24 hours to confirm your appointment. </p>
    `,
    backgroudColorSection: getBgColorStyle(Colors.blue),
    bottomCurve: images.asymmetricalCurveBottom,
    bottomCurveFilter: getFilterStyle(Colors.orange),

  },
  {
    topCurve: images.symmetricalCurveTop,
    topCurveFilter: getFilterStyle(Colors.orange),

    leftContent: `<img src=${hello} />`,
    rightContent: `<h2 class="heading heading--large" style="color:#464e51;">We’re here to help</h2>
    <p style="color:#464e51; ">Got a question?<br>
    Need some help?<br>
    You can give us a call on <a href="tel:01782438427" style="text-decoration:underline!important;"><span style="font-weight:500;">01782 438 427</span></a><br>
    Or drop us a line at <a href="mailto:stoke@communityenergyscheme.com" style="text-decoration:underline!important;font-weight:500;">stoke@communityenergyscheme.com </br></a> Alternatively, check out our help section.</p><a class="download-button download-button--dark-purple" href=${`/help`} >Find help</a> `,

    component: NTPstyle,
    backgroudColorSection: getBgColorStyle(Colors.yellow),
    leftContentStyle: getTextColorStyle(Colors.grey),
    rightContentStyle: getTextColorStyle(Colors.grey),
  bottomCurve: images.symmetricalbottomcurvedarkgreen,  
  bottomCurveFilter: getFilterStyle(Colors.none),  
  },


];