import { ContentItem } from "../../types";
import { images, getFilterStyle, colors, Colors, getBgColorStyle, getTextColorStyle } from "../../theme";
import NTPstyle from "../../components/styles/NTPstyle";
import Imagestyle from "../../components/styles/Imagestyle";
import Formstyle from "../../components/styles/Formstyle";
import Circlestyle from "../../components/styles/Circlestyle";
import Buttonstyle from "../../components/styles/Buttonstyle";
import Bannerstyle from "../../components/styles/Bannerstyle";
import Narrowstyle from "../../components/styles/Narrow";
import Singlestyle from "../../components/styles/Singlestyle";
import Singlestylewide from "src/components/styles/Singlestylewide";
import Overlaystyle from "src/components/styles/Overlaystyle";
import Centeredstyle from "src/components/styles/Centeredstyle";
import Newcenteredstyle from "src/components/styles/Newcenteredstyle";
import VideoLibrary from "src/components/styles/VideoLibrary";



const clouds = require('../../img/video.mp4');

const newcustomer = require('../../img/newcustomer.png');
const tariffs = require('../../img/tariffs.png');
// const handglobe = require('../img/hand-globe.png');

const photobg2 = require('../../img/2-photo-bg3.jpg');

const katie = require('../../img/katie.jpg');
const handsun = require('../../img/hand-sun.png');
const coinssmall = require('../../img/coinssmall.png');
const logo = require('../../img/ces-logo.png');
const keys2 = require('../../img/ces-key2.png');
const house = require('../../img/illustration.png');
const plug = require('../../img/plug.png');
const ceshouse = require('../../img/ces-house.png');
const community = require('../../img/community.png');
const pig = require('../../img/pig.png');
const people = require('../../img/people.png');
const billing_large_new = require('../../img/billing_large_new.png');
const fair = require('../../img/fair.png');
const match = require('../../img/match.png');
const rebate = require('../../img/rebate.png');

const hello = require('../../img/hello.png');
const family = require('../../img/family.png');
const renewal = require('../../img/renewals.png');
const familyhouse = require('../../img/family-house.png');
const sun = require('../../img/sun.png');
const house2 = require('../../img/house2.png');


const babyvideo = require('../../img/baby.mp4');

export const config: ContentItem[] = [


// START HEADER

  {

    topCurve: images.topcurvedarkgreen,
    topCurveFilter: getFilterStyle(Colors.none),
    backgroudColorSection: getBgColorStyle(Colors.teal),
    component: Bannerstyle,
    bottomCurve: images.peopleLong,
    bottomCurvePeopleMobile: images.mobilepeople,
    bottomCurvePeopleDesktop: images.desktoppeople,
    bottomCurveFilter: getFilterStyle(Colors.none),
    leftContent: ` <img src="${coinssmall}" style="max-width:150px;" /> <h2 class="heading" style="font-weight:400; color: #464e51; margin-top: 0px!important; padding-top: 0px!important; "
    
    >Save money with the Community Energy Scheme.</h2><p style="color: #464e51">Call us on <a href="tel:01782438427" style="text-decoration:underline!important;"><span style="font-weight:500;">01782 438 427</span></a> now to book your appointment and watch the savings add up.</p>
    `,

    rightContent: `<img src="${logo}"  class=" logoremove" /> `,
    useMargin: true,
    
  },

// END HEADER


// STARTS NEW CENTERED STYLE SECTION

  {


    topCurve: images.topcurvegrass,
    component: Newcenteredstyle,
    backgroudColorSection: getBgColorStyle(Colors.blue),
    bottomCurve: images.symmetricalCurveBottom,
    bottomCurveFilter: getFilterStyle(Colors.yellow),
    leftContentStyle: getTextColorStyle(Colors.white),
    rightContentStyle: getTextColorStyle(Colors.white),
    

  },

// ENDS NEW CENTERED STYLE SECTION

// // START A BETTER WAY

  // {

  //   leftContent: `  <h2 class="heading heading--large">Simply a better way.</h2>
  //                         <p>The Community Energy Scheme offers the people from Stoke-on-Trent affordable, renewable electricity. It is a partnership between Community Energy and Stoke-on-Trent City Council that you can easily sign up for. 
  //                         Whenever it’s possible, Community Energy will fit solar panels on tenant’s roofs, completely FREE. These panels use the sun’s rays to make renewable energy. </p>`,

  //   rightContent: `	<img src="${handsun}" style="max-width:300px; margin-top:60px" /> <h2 class="heading heading--large"#" style="margin-top:60px">About the scheme</h2>
  //   <p >Working with the Council, we install solar panels on your property for FREE. We’ll also give you FREE energy-saving LED bulbs too. PLUS our Fair Market Price guarantees you’ll always pay less than the UK’s ‘Big 6’ energy suppliers (saving you an estimated &pound;166<sup>††</sup> a year.)</p> <p>In addition, you will also get 18% to 25% rebate on your solar energy consumption. Amazing isn't it?</p> `,
  //   topCurve: images.topcurvegrass,
  //   component: Centeredstyle,
  //   backgroudColorSection: getBgColorStyle(Colors.blue),
  //   bottomCurve: images.symmetricalCurveBottom,
  //   bottomCurveFilter: getFilterStyle(Colors.yellow),
  //   leftContentStyle: getTextColorStyle(Colors.white),
  //   rightContentStyle: getTextColorStyle(Colors.white),
  //   useMargin: false,

  // },

// // END A BETTER WAY




// START PROMO SECTION 

{
  leftContent: `	<img src="${sun}" style="max-height:150px;" /> <h2 class="heading heading--large" style="color:#464e51;">Already a customer? </h2> <h3 class="heading" style="color:#464e51;">Looking for a "how to" or a little help?</h3>
      <p style="color:#464e51;">If you’re looking for a little bit of help with regards to how to submit a meter reading, locating your meter and energy saving tips and tricks, then look no further.  If you still have a couple more questions, then head over to our <a style="text-decoration:underline!important; " href="/faq">FAQ’s</a>. If you still cannot find your answer, then please <a style="text-decoration:underline!important; " href="/contact">get in touch</a> with one of our advisors who will be more than happy to assist you.</p>

    `,
  topCurve: images.symmetricalCurveTop,
  topCurveFilter: getFilterStyle(Colors.yellow),
  backgroudColorSection: getBgColorStyle(Colors.lightsky),

  component: Singlestylewide,
  useMargin: true,
},

{

  component: VideoLibrary,
  backgroudColorSection: getBgColorStyle(Colors.lightsky),
  bottomCurve: images.bottomcurvegrass ,
  bottomCurveFilter: getFilterStyle(Colors.none),
  leftContentStyle: getTextColorStyle(Colors.white),
  rightContentStyle: getTextColorStyle(Colors.blue),
  useMargin: false,

},

// END PROMO SECTION 




// START THE PROCESS


  {
    topCurve: images.topcurvegrass,
    topCurveFilter: getFilterStyle(Colors.none),


    rightContent: `	<h2 class="heading heading--large" >The process</h2>
    <p >1. Find out how much you can save by booking an appointment to meet one of our friendly team members or call us on the number <a href="tel:01782438427" style="text-decoration:underline!important; "><span style="font-weight:500; ">01782&nbsp;438&nbsp;427</span></a></p>
                    <p >2. If you like what you hear, we’ll do a preliminary survey of your home to check that it is suitable for solar panels/solar storage to be fitted.</p>
                    <p >3. Once we’ve done that, you’ll start to enjoy big savings on your energy bills... in both the first year and for years to come.</p> <a class="download-button download-button--dark-purple" href="/the_process" >Learn more</a>`,
    leftContent: `<img src=${people} />`,


    component: NTPstyle,
    backgroudColorSection: getBgColorStyle(Colors.grassgreen),
    bottomCurve: images.symmetricalCurveBottom,
    bottomCurveFilter: getFilterStyle(Colors.yellow),
    leftContentStyle: getTextColorStyle(Colors.white),
    rightContentStyle: getTextColorStyle(Colors.white),
    useMargin: true,

  },

// ENDS THE PROCESS


// START THE BILLING


{
  // topCurve: images.topcurvegrass,
  // topCurveFilter: getFilterStyle(Colors.none),

  rightContent: `	<h1 class="heading" style="color: #464e51"> We've redesigned your bill with you in mind. </h1> <p style="color: #464e51">Based on feedback from customers, we’re excited to show you your new monthly bill. All the same information is here, it's just easier to find and understand, saving you time.</p> <a class="download-button download-button--dark-purple" href="/billing" >Learn more</a>`,
  leftContent: `<img src=${billing_large_new} />`,
  component: NTPstyle,
  backgroudColorSection: getBgColorStyle(Colors.yellow),
  bottomCurve: images.symmetricalCurveBottom,
  bottomCurveFilter: getFilterStyle(Colors.yellow),
  leftContentStyle: getTextColorStyle(Colors.white),
  rightContentStyle: getTextColorStyle(Colors.white),
  

},



// ENDS THE BILLING




// START PRICE GUARANTEES

  {
    topCurve: images.asymmetricalCurveTop,
    topCurveFilter: getFilterStyle(Colors.yellow),
    leftContent: `	<h1 class="heading" style="margin-top:60px">How does the scheme ensure I will save money?</h1>
    <p>There are several guarantees within your terms and conditions that ensure the scheme will save you money. </p>
      
     <p> What are they? How do they work? Just keep reading to find out.
</p> <a class="download-button download-button--dark-purple" href=${`/the_scheme`}>Find out more</a>`,
    // topCurve: images.topcurvegrass,
    // topCurveFilter: getFilterStyle(Colors.none),
    backgroudColorSection: getBgColorStyle(Colors.blue),
    leftContentStyle: { marginBottom: "0px" },
    component: Singlestyle,
    useMargin: false,


  },

  {
    // topCurve: images.asymmetricalCurveTop,
    // topCurveFilter: getFilterStyle(Colors.teal),
    component: Circlestyle,
    backgroudColorSection: getBgColorStyle(Colors.blue),
    bottomCurve: images.symmetricalCurveBottom,
    bottomCurveFilter: getFilterStyle(Colors.blue),
    // circle: [
    //   {
    //     circleImage: fair,

    //   },
    //   {
    //     circleImage: match,

    //   },
    //   {
    //     circleImage: rebate,

    //   },


    // ],
  },

// END PRICE GUARANTEES



// START JOIN

  {
    leftContent: `	<h2 class="heading heading--large" style="margin-top:60px">Who can join?</h2>
        <p>The Community Energy Scheme is now available to all Stoke-on-Trent council tenants, Right to Buy and private homeowners.</p>
  
      `,
    topCurve: images.asymmetricalCurveTop,
    topCurveFilter: getFilterStyle(Colors.blue),
    backgroudColorSection: getBgColorStyle(Colors.teal),

    component: Singlestyle,
    useMargin: true,
  },

  {
    // leftImage: `<img src=${tariffs} />`,
    leftImage: keys2,
    leftContent: `<h3 class="heading" >Sign up options</h3>
    <p>Over the phone, over a cup of tea or straight from your computer. Joining the scheme is easy and our advisors will be happy to assist you through the process. </p>
    
    <a class="download-button download-button--dark-purple" href="/joining" >Learn more</a>`,

    component: Buttonstyle,
    backgroudColorSection: getBgColorStyle(Colors.teal),
    leftContentStyle: getTextColorStyle(Colors.white),
    rightContentStyle: getTextColorStyle(Colors.white),
    rightImage: renewal,
    rightContent: `<h1 class="heading">Sign up online</h1>
    <p>Ready for greener living without compromising on affordability? Sign up to the Community Energy Scheme online and start saving hundreds of pounds a year on your bill.  </p>
    <a class="download-button download-button--dark-purple" href="/sign_up" >Sign up today</a>`,
    useMargin: true,
    sectionStyle: {
      marginTop: '-7.5%',
    },
  },

// END JOIN



// START CERAMIC IMAGE

  {
    component: Imagestyle,
    leftImage: photobg2,
    leftContent: ` So far we <br />
    have invested <br />
    over £20 million GBP in  <br />
    the local economy and we <br />
    saved  £1.8 million for <br />
    tenants in our first  <br /> year. `,
    
    rightContent: ` 
    Thanks to <br>
    our members  <br>
    3,802 tons of carbon are <br>
    saved every 12 months,  <br>
    the equivalent of over  <br>
    11 thousand  <br>
    trees! <br> 
    `,


  },

// END CERAMIC IMAGE



//START HELP

  {
    leftContent: `<img src=${hello} />`,
    rightContent: `<h2 class="heading heading--large" style="color:#464e51;">We’re here to help</h2>
    <p style="color:#464e51; ">Got a question?<br>
    Need some help?<br>
    You can give us a call on <br> <a href="tel:01782438427" style="text-decoration:underline!important;"><span style="font-weight:500;">01782 438 427</span></a><br>
    Or drop us a line at <a href="mailto:stoke@communityenergyscheme.com" style="text-decoration:underline!important;font-weight:500;">stoke@communityenergyscheme.com </br></a> Alternatively, check out our help section.</p><a class="download-button download-button--dark-purple" href="/faq" >Find help</a> `,
    // topCurve: images.asymmetricalCurveTop,
    // topCurveFilter: getFilterStyle(Colors.magenta),
    component: NTPstyle,
    backgroudColorSection: getBgColorStyle(Colors.yellow),
    leftContentStyle: getTextColorStyle(Colors.grey),
    rightContentStyle: getTextColorStyle(Colors.grey),
    bottomCurve: images.symmetricalbottomcurvedarkgreen,  
    bottomCurveFilter: getFilterStyle(Colors.none), 
  },

// END HELP



];