import React, { useState } from 'react';
import '../../index.css';
import '../../stoke.css';
import '../../bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ContentItem } from '../../types';


const Narrowstyle: React.FC<ContentItem> = ({
    leftContent,
    rightContent,
    topCurve,
    topCurveFilter,
    backgroudColorSection,
    bottomCurveFilter,  
    bottomCurve,
    leftContentStyle,
    rightContentStyle,
    leftImage,
    rightImage,
    useMargin = true,
  }) => {

    const marginContent = React.useMemo(
        () => bottomCurve && useMargin ? { marginTop: "-130px"} : undefined
      ,[]);
    
      const sectionRef = React.useRef<HTMLDivElement | null>(null);
    
      const onScrollIntoView = () => {
        let position: DOMRect | undefined = sectionRef?.current?.getBoundingClientRect();
      
        // checking for partial visibility
        if(position && (position.top < window.innerHeight && position.bottom >= 0)) {
          sectionRef?.current?.classList.add('animation-end');
          window.removeEventListener('scroll', onScrollIntoView);
        }
      }
    
      React.useEffect(() => {
        onScrollIntoView();
        window.addEventListener('scroll', onScrollIntoView);
      }, []);

return (

    <div className="content site-content">
        <div className="primary content-area">
            <div className="main site-main" 
                style={backgroudColorSection}>

                <div className='section help-child-section-1'
                    style={leftContentStyle}><img src={topCurve}
                        style={topCurveFilter}/>

                    <Container>
                        <Row className='animation-start' ref={sectionRef}>

                            <Col md="6">
                                <div className="section-content">
                                    <div className="section-content__inner">
                                        <img src={leftImage}
                                            className="section-image fadeInTop fadeInTop--active"
                                            />
                                    </div>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="section-content">
                                    <div className="section-content__inner">
                                        <div className="section-text section-text--wide fadeInTop fadeInTop--active"
                                            style={rightContentStyle}>
                                                                    {rightContent && (
                          <div style={rightContentStyle} dangerouslySetInnerHTML={{ __html: rightContent }} />
                        )}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <img src={bottomCurve} style={bottomCurveFilter} />
                </div>
            </div>
        </div>
    </div>);
}





export default Narrowstyle;

// //dangerouslySetInnerHTML={{ __html: topContent }} this makes html work somehow, try to avoid using it because it means DANGEEEEERRRR

