import  React , { useState } from 'react';
import '../../index.css';
import { ReactComponent as BellIcon } from '../../icons/bell.svg';
import { ReactComponent as MessengerIcon } from '../../icons/messenger.svg';
import { ReactComponent as CaretIcon } from '../../icons/caret.svg';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ReactComponent as CogIcon } from '../../icons/cog.svg';
import { ReactComponent as ChevronIcon } from '../../icons/chevron.svg';

import { ReactComponent as BoltIcon } from '../../icons/bolt.svg';
import { CSSTransition } from 'react-transition-group';
import { Link, withRouter } from 'react-router-dom';

const config: Record<string, () => React.ReactNode> = {
  // '/the_scheme': () => {
  //   return (
  //     <p className="footer-address" >
  //     * The Big Six are the United Kingdom's largest retail suppliers of gas and electricity.<br />
  //     </p>
  //   )
  // },
  // '/': () => {
  //   return (
  //     <p className="footer-address" >
  //     * Savings against OFGEM price cap April 2021<br />
  //     </p>
  //   )
  // },
  '/covid-relief': () => {
    return (
      <p className="footer-address" style={{marginBottom: '170px'}}>
      * Standing charge included in cost per unit. Calculation based on average household consumption.<br/>
      † Cost per unit includes solar rebate of 18% for tenants who have storage and 25% for tenants who do not have solar installed. Calculation based on average household consumption.<br />
      </p>
    )
  }
}
 
function Footer(props: any) {

    const ArrowIcon = require('../../icons/arrow.svg');

    const footerContent = config[props.location.pathname];

    return (
        <footer className="footer-section">
        <div className="container" style={{border: 0}}>
          <div className="row">
            <div className="col-lg-6">
              <a href="tel:01782438427"></a>
              <a href="tel:01782438427" className="footer-contact">01782 438 427</a>
            </div>
            <div className="col-lg-6">
              <a href="mailto:stoke@communityenergyscheme.com"></a>
              <a href="mailto:stoke@communityenergyscheme.com" className="footer-contact">stoke@communityenergyscheme.com</a>
            </div>
          </div>
          <div className="row"  style={{color: '#ffffff'}}>
            <div className="col-md-12"  style={{color: '#ffffff'}}>
              <ul className="footer-nav"  style={{color: '#ffffff'}}>
                <li><a href="/privacy">Privacy Policy</a></li>
                <li><a href="/cookies">Cookies</a></li>
                <li><a href="/covid-19">COVID-19 Plan</a></li>
              </ul>
            </div> 
          </div>
          <div className="row">
            <div className="col-md-12" role="contentinfo" style={{marginBottom:80}}>
              <p className="footer-copyright">© 2021 Community Energy Scheme Limited</p>
              
              {footerContent && footerContent()}
            </div>
          </div>
        </div>
      </footer>
    );
  }
  
  export default withRouter(Footer);