import React from 'react';
import { ContentItem } from '../../types';

interface Props {
  contents: ContentItem[];
}

// Note: This is a page wrapper, every page will be rendered through here
const Page: React.FC<Props> = ({
  contents,
}) => {
    return (
      <>
      <div style={{background: 'transparent', height: 98}} />
      <div>
        {contents.map((section: ContentItem) => {
          const StyledComponent = section.component;
  
          return <StyledComponent {...section} />
        })}
      </div>
      </>
    )
  }; 
export default Page;  

//'contents.map()' is returning everything inside the array 'contents' passing them to whatever is inside of the () which is a callback function, in this case it is assigning every item of the array to the variable 'contentItem'

//in the callback function the variable 'StyledComponent' is assigned to the value of 'contentsItem.component'

//'contentsItem.component' is is using the dot notation to access the value of the key 'component' of each element of the array, which in this case if we look at the data file it is a Component in this case Style1

//'return <StyledComponent/>' returns a component renamed as the 'component' key value so <StyleComponent/> will be <Style1>

//{...contentsItem} uses the apread operator to pass the value of contentsItem as a JSX element prop

