import React, { useState } from 'react';
import '../../index.css';
import '../../stoke.css';
import '../../bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ContentItem } from '../../types';
import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'

const bill1 = require('../../img/bill1.jpg');
const bill2 = require('../../img/bill2.jpg');


const Calendarstyle: React.FC<ContentItem> = ({
  leftContent,
  rightContent,
  topCurve,
  topCurveFilter,
  backgroudColorSection,
  bottomCurveFilter,
  bottomCurve,
  leftContentStyle,
  rightContentStyle,
  leftImage,
  rightImage,
  useMargin = false,
  sectionStyle,
  contract,
}) => {

  const marginContent = React.useMemo(
    () => bottomCurve && useMargin ? { marginTop: "-130px" } : undefined
    , []);

  const sectionRef = React.useRef<HTMLDivElement | null>(null);

  const onScrollIntoView = () => {
    let position: DOMRect | undefined = sectionRef?.current?.getBoundingClientRect();

    // checking for partial visibility
    if (position && (position.top < window.innerHeight && position.bottom >= 0)) {
      sectionRef?.current?.classList.add('animation-end');
      window.removeEventListener('scroll', onScrollIntoView);
    }
  }

  React.useEffect(() => {
    onScrollIntoView();
    window.addEventListener('scroll', onScrollIntoView);
  }, []);




  return (
    <div id='first-section-of-page'>
      <div className="content site-content">
        <div className="primary content-area">
          <div className="main site-main" style={backgroudColorSection}>
            <img src={topCurve} style={topCurveFilter}/>
            <Container className='animation-start' ref={sectionRef}>
              <Row >
                  <div className="section-content"  >
                        <div className="section-content__inner">
                            <div 
                              className="section-text section-text--wide fadeInTop fadeInTop--active" 
                              style={leftContentStyle}
                            >
                                {
                                  leftContent && <div dangerouslySetInnerHTML={{ __html: leftContent }} />
                                }
                            </div>


                        </div>
                  </div>
              </Row>

              <Row style={{ padding: "40px" }}>
                  <div className="container-image-popup">
                  <iframe src='https://outlook.office365.com/owa/calendar/CommunityEnergySchemeStoke@DAVIDELBOURNE.onmicrosoft.com/bookings/'  scrolling='no' style={{border:'0', width:'100%', height:'1400px',}}></iframe>
                  </div>
              </Row>
            </Container>
            <img src={bottomCurve} style={bottomCurveFilter} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Calendarstyle;

