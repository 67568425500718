import React from 'react';
import Navbar from './components/navbar/navbar';
import Footer from './components/footer/footer';
import { Switch, Route} from 'react-router-dom';
import { Home } from './pages/Home';
import { Faq } from './pages/Faq';
import { Help } from './pages/Help';
import { Scheme } from './pages/Scheme';
import { Process } from './pages/Process';
import { Billing } from './pages/Billing';
import { Payments } from './pages/Payments';
import { Joining } from './pages/Joining';
import { Contact } from './pages/Contact';
import { Thanks } from './pages/Thanks';
import { SignUp } from './pages/SignUp';
import { Calendar } from './pages/Calendar';
import { Paperless } from './pages/Paperless';
import { Testimonials } from './pages/Testimonials';
import { Privacy } from './pages/Privacy';
import { Cookies } from './pages/Cookies';
import { Covid19 } from './pages/Covid19';
import { Promo } from './pages/Promo';
import { Covidrelief } from './pages/Covidrelief';
import { PPA } from './pages/PPA';
import { stokeled } from './pages/stokeled';
import { PayOnline } from './pages/PayOnline';
import { Videos } from './pages/Videos';

import BannerComponent from './components/standalone/bannercomponent/bannercomponent';




function App(props: any) {

  console.log('app', props);

  return (
      <div className="App" style={{overflow: 'hidden'}}>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/faq" component={Faq} />  
          <Route path="/help" component={Help} />  
          <Route path="/the_scheme" component={Scheme} />         
          <Route path="/the_process" component={Process} />   
          <Route path="/billing" component={Billing} />   
          <Route path="/payments" component={Payments} />   
          <Route path="/joining" component={Joining} />           
          <Route path="/contact" component={Contact} />   
          <Route path="/thank_you" component={Thanks} />            
          <Route path="/sign_up" component={SignUp} />  
          <Route path="/calendar" component={Calendar} />  
          <Route path="/paperless" component={Paperless} />  
          <Route path="/testimonials" component={Testimonials} />      
          <Route path="/privacy" component={Privacy} />  
          <Route path="/cookies" component={Cookies} />  
          <Route path="/covid-19" component={Covid19} />       
          <Route path="/tips" component={Promo} />  
          {/* <Route path="/covid-relief" component={Covidrelief} /> */}
          <Route path="/PPA" component={PPA} />      
          <Route path="/stoke-led" component={stokeled} />
          <Route path="/stoke-leds" component={stokeled} />      
          <Route path="/pay-online" component={PayOnline} />       
          <Route path="/videos" component={Videos} />          
        </Switch>

        <Footer/>
     </div>
  ); 
}



export default App; 