import { ContentItem } from "../../types";
import { images, getFilterStyle, colors, Colors, getBgColorStyle, getTextColorStyle } from "../../theme";
import NTPstyle from "../../components/styles/NTPstyle";
import Imagestyle from "../../components/styles/Imagestyle";
import Formstyle from "../../components/styles/Formstyle";
import Circlestyle from "../../components/styles/Circlestyle";
import Buttonstyle from "../../components/styles/Buttonstyle";
import Bannerstyle2 from "../../components/styles/Bannerstyle2";
import Narrowstyle from "../../components/styles/Narrow";
import Singlestyle from "../../components/styles/Singlestyle";
import Narrowstyle2 from "../../components/styles/Narrow2";


const clouds = require('../../img/video.mp4');

const newcustomer = require('../../img/newcustomer.png');
const tariffs = require('../../img/tariffs.png');
// const handglobe = require('../img/hand-globe.png');

const jump = require('../../img/jump.jpg');

const womantest = require('../../img/womantest.png');
const womankidtest = require('../../img/womankidtest.png');
const mantest = require('../../img/mantest.png');

const womanil = require('../../img/womanil.png');
const womankidil = require('../../img/womankidil.png');
const manil = require('../../img/manil.png');

const tv = require('../../img/tv.png');
const keys2 = require('../../img/ces-key2.png');
const house = require('../../img/illustration.png');
const plug = require('../../img/plug.png');
const ceshouse = require('../../img/ces-house.png');
const community = require('../../img/community.png');
const pig = require('../../img/pig.png');
const people = require('../../img/people.png');
const phone = require('../../img/phone.png');
const directdebit = require('../../img/directdebit-1.png');
const fair = require('../../img/fair.png');
const match = require('../../img/match.png');
const rebate = require('../../img/rebate.png');

const hello = require('../../img/hello.png');
const logo = require('../../img/ces-logo.png');
const renewal = require('../../img/renewals.png');

const videosource = require('../../img/planet2.mp4');
const family2 = require('../../img/ces-family.png');

export const config: ContentItem[] = [

  // {
  //   topCurve: images.topcurvedarkgreen,
  //   topCurveFilter: getFilterStyle(Colors.none),
  //   backgroudColorSection: getBgColorStyle(Colors.teal),
  //   component: Bannerstyle2,
  //   bottomCurve: images.bottomcurvedarkgreen,
  //   bottomCurveFilter: getFilterStyle(Colors.none),
  //   rightContent: ` <img src="${community}" style="max-width:300px;" /> <h2 class="heading heading--large" style=" color: #464e51; margin-top: 0px!important; padding-top: 0px!important; "
  //   >Here are some of our success stories.</h2><p style="font-weight:500; color: #464e51" >You too could save money on your energy bills with the Community Energy Scheme. </p>
  //   `,
  //   leftContent: `<img src="${family2}"  class=" logoremove" style="max-height:350px;"/> `,
  //   useMargin: true,
  //   vidSource: videosource,
  // },

  {
    leftContent: `	
      <img src="${community}" style="max-width:300px;" /> 
      <h1 class="heading" style="margin-top:60px">
          Here are some of our success stories.
      </h1>
      <p>
          <span style="font-weight:500; color:white">You too could save money on your energy bills with the Community Energy Scheme. </span>
      </p> 
    `,
    topCurve: images.topcurvedarkgreen,
    topCurveFilter: getFilterStyle(Colors.none),
    backgroudColorSection: getBgColorStyle(Colors.blue),
    leftContentStyle: { marginBottom: "0px" },
    component: Singlestyle,
    useMargin: false,
  },
  {
    leftContent: ` 
      <img src="${plug}" style="max-width: 150px" /> 
      <h2 class="heading heading--large">
          Building a better future
      </h2>
      <p>
          Every year thousands of Stoke-on-Trent tenants power their homes whilst helping the planet thanks to the Community Energy Scheme. Check out some of their stories from our customers and how they are saving on their energy bills
      </p>
    `,
    rightContent: `<img src=${tv} />`,
    topCurve: images.symmetricalCurveTop,
    topCurveFilter: getFilterStyle(Colors.blue),
    component: NTPstyle,
    backgroudColorSection: getBgColorStyle(Colors.teal),
    bottomCurve: images.symmetricalCurveBottom,
    bottomCurveFilter: getFilterStyle(Colors.blue),
    leftContentStyle: getTextColorStyle(Colors.white),
    rightContentStyle: getTextColorStyle(Colors.white),
    useMargin: false,
  },
  {
    leftImage: womanil,

    rightContent: `<img src="${womantest}" style="max-width: 150px" /> <p>Mrs Keen joined the Community Energy Scheme, following in the footsteps of other members of her family. She has her solar panels installed so she can start making some of the savings that attracted her in the first place.</p>
										
    <p><strong>“The solar installation went ahead with no worries at all and the engineers took the time to make sure I understood how they work.”</strong></p>
`,
    topCurve: images.symmetricalCurveTop,
    topCurveFilter: getFilterStyle(Colors.blue),
    component: Narrowstyle,
    backgroudColorSection: getBgColorStyle(Colors.blue),
    // bottomCurve: images.asymmetricalCurveBottom,
    // bottomCurveFilter: getFilterStyle(Colors.blue),
    leftContentStyle: getTextColorStyle(Colors.white),
    rightContentStyle: getTextColorStyle(Colors.blue),
    useMargin: false,

  },


  {

    leftContent: `<img src="${womankidtest}" style="max-width: 150px" /> <p>Mrs Blundred of Blurton was nervous when joining the Community Energy Scheme as it was an altogether new process for her. “I hadn’t switched energy supplier for a very long time, so I was a bit nervous. Because of this though I was on a very high rate, so the time was right to make a change. <strong>When I saw what I could save a year I was amazed so I had to go for it.</strong>”</p>

    <p>Once the switch was underway, she was happy to see everything go smoothly. <strong>“It was so simple as the Community Energy Scheme team did all of the work.”</strong></p>`,

    rightImage: womankidil,
    // topCurve: images.symmetricalCurveTop,
    // topCurveFilter: getFilterStyle(Colors.magenta),
    component: Narrowstyle2,
    backgroudColorSection: getBgColorStyle(Colors.blue),
    // bottomCurve: images.asymmetricalCurveBottom,
    // bottomCurveFilter: getFilterStyle(Colors.blue),
    leftContentStyle: getTextColorStyle(Colors.white),
    rightContentStyle: getTextColorStyle(Colors.blue),
    useMargin: false,

  },

  {

    leftImage: manil,

    rightContent: `<img src="${mantest}" style="max-width: 150px" /><p>Mr &amp; Mrs Cragg had been on a list for another solar panel scheme, but decided to join the Community Energy Scheme instead.</p>
    <p>The Craggs, of Joiners Square, were also delighted with the overall service. After an easy switch over, they were particularly impressed with the solar installation.</p>
										
    <p>“We didn’t even know they were here!” </p>`,
    // topCurve: images.symmetricalCurveTop,
    // topCurveFilter: getFilterStyle(Colors.magenta),
    component: Narrowstyle,
    backgroudColorSection: getBgColorStyle(Colors.blue),

    leftContentStyle: getTextColorStyle(Colors.white),
    rightContentStyle: getTextColorStyle(Colors.blue),
    useMargin: false,

  },



  {
    leftContent: `	<h2 class="heading heading--large" style="margin-top:60px">Would you like to learn more?</h2>
    <p>Don't wait to enjoy great savings</p>

   </p>
      `,
    topCurve: images.asymmetricalCurveTop,
    topCurveFilter: getFilterStyle(Colors.blue),
    backgroudColorSection: getBgColorStyle(Colors.teal),

    component: Singlestyle,
    useMargin: true,
  },

  {
    // leftImage: `<img src=${tariffs} />`,
    leftImage: logo,
    leftContent: `<h3 class="heading" >About the scheme</h3>
    <p>Learn how you too can start taking advantage of all the perks the scheme has to offer. </p>
    
    <a class="download-button download-button--dark-purple" href="/the_scheme" >Learn more</a>`,

    component: Buttonstyle,
    backgroudColorSection: getBgColorStyle(Colors.teal),
    leftContentStyle: getTextColorStyle(Colors.white),
    rightContentStyle: getTextColorStyle(Colors.white),
    rightImage: renewal,
    rightContent: `<h1 class="heading">Joining options</h1>
    <p>Joining the scheme is easy. Find out about our flexible sign up process today.  </p>
    <a class="download-button download-button--dark-purple" href="/joining" >Sign up today</a>`,
    useMargin: true,
    sectionStyle: {
      marginTop: '-7.5%',
    },
  },




  {
    component: Imagestyle,
    leftImage: jump,
    leftContent: ` So far we <br />
    have invested <br />
    over £20 million GBP in  <br />
    the local economy and we <br />
    saved  £1.8 million for <br />
    tenants in our first  <br /> year. `,

    rightContent: ` 
    Thanks to <br />
    our members  <br />
    3,802 tons of carbon are <br />
    saved every 12 months,  <br />
    the equivalent of over  <br />
    11 thousand  <br />
    trees! <br />
    `,


  },

  {
    // topCurve: images.symmetricalCurveTop,
    // topCurveFilter: getFilterStyle(Colors.magenta),
    leftContent: `<img src=${hello} />`,
    rightContent: `<h2 class="heading heading--large" style="color:#464e51;">We’re here to help</h2>
    <p style="color:#464e51; ">Got a question?<br>
    Need some help?<br>
    You can give us a call on <a href="tel:01782438427" style="text-decoration:underline!important;"><span style="font-weight:500;">01782 438 427</span></a><br>
    Or drop us a line at <a href="mailto:stoke@communityenergyscheme.com" style="text-decoration:underline!important;font-weight:500;">stoke@communityenergyscheme.com </br></a> Alternatively, check out our help section.</p><a class="download-button download-button--dark-purple" href="/faq" >Find help</a> `,

    component: NTPstyle,
    backgroudColorSection: getBgColorStyle(Colors.yellow),
    leftContentStyle: getTextColorStyle(Colors.grey),
    rightContentStyle: getTextColorStyle(Colors.grey),
    bottomCurve: images.symmetricalbottomcurvedarkgreen,
    bottomCurveFilter: getFilterStyle(Colors.none),
  },




];