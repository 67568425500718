import React, { useState } from 'react';
import '../../index.css';
import '../../stoke.css';
import '../../bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ContentItem } from '../../types';

const symmetricalcurvebottom = require('../../img/symmetrical-curve-bottom.png');
const curvetophome = require('../../img/curve-top-home.png');

const tealfilter = {
  filter: "brightness(0) saturate(100%) invert(62%) sepia(16%) saturate(818%) hue-rotate(129deg) brightness(87%) contrast(93%)",
  width: "100vw"
};

const orangefilter = {
  filter: "brightness(0) saturate(100%) invert(60%) sepia(41%) saturate(5078%) hue-rotate(338deg) brightness(98%) contrast(93%)",
  width: "100vw"
};





export default class Formstyle extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }

  render() {
    const { status } = this.state;
    return (

      <div id='first-section-of-page'>
      <div className="content site-content">
        <div className="primary content-area">
          <main className="main site-main" style={{backgroundColor:"#3b78b7"}}>
            <div className='stoke-section--1' ><img src={curvetophome} style={orangefilter} />

              <Container >
                <Row >
                  <Col md="12" className="col-md-6 col-md-offset-3">
                    <div className="contact-form fadeInTop fadeInTop--active" >
                      <h2 className="heading heading--push">Or, simply fill in this form...</h2>
                      <div role="form" className="wpcf7" id="wpcf7-f1768-o1" lang="en-US" dir="ltr">
                        <div className="screen-reader-response" />



                        <form
        onSubmit={this.submitForm}
        action="https://formspree.io/f/meqvdjka"
        method="POST"
      >
        <label style={{ color: "white" }}>Name:</label>
        <input type="text" name="name" />
        <label style={{ color: "white" }}>Email:</label>
        <input type="email" name="email" />
        <label style={{ color: "white" }}>Home address:</label>
        <input type="text" name="address" />

        <label style={{ color: "white" }}>Message:</label>
        


        <textarea rows={3} cols={60} type="text" name="message" /><br />



        {status === "SUCCESS" ? <p>Thanks!</p> : <button className="download-button download-button--dark-purple" style={{backgroundColor:"#a7226e"}} style={{backgroundColor:"#a7226e"}}>Submit</button>}
        {status === "ERROR" && <p>Ooops! There was an error.</p>}
      </form>


      </div>
                    </div>
                  </Col>
                </Row>
              </Container>

              <img src={require ('../../img/symmetrical-bottom-curve-green.png')} />
            </div >
          </main>
        </div>
      </div>
    </div>


      
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}