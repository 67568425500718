import { ContentItem } from "../../types";
import { images, getFilterStyle, Colors, getBgColorStyle, getTextColorStyle, colors } from "../../theme";
import NTPstyle from "../../components/styles/NTPstyle";
import Narrowstyle from "../../components/styles/Narrow";
import FAQsstyle from "src/components/styles/FAQsstyle";

// const newcustomer = require('../../img/newcustomer.png');
const tariffs = require('../../img/tariffs.png');
// const handglobe = require('../../img/hand-globe.png');

const getintouch = require('../../img/getintouchwith.png');
const heretohelp = require('../../img/heretohelp.png');
const redlight = require('../../img/redlight.png');
const phone = require('../../img/phone.png');

export const config: ContentItem[] = [
  {
    leftImage: phone,
    rightContent: `<h1 class="heading heading--large" > Thank you for your submission!</h1><p > A member of the team will get in touch within 72 horus</p>
    `, 
    topCurve: images.topcurvedarkgreen,
    topCurveFilter: getFilterStyle(Colors.none),
    component: Narrowstyle,
    backgroudColorSection: getBgColorStyle(Colors.lilac),
    rightContentStyle: getTextColorStyle(Colors.grey),
    bottomCurve: images.asymmetricalCurveBottom,  
    bottomCurveFilter: getFilterStyle(Colors.orange),   
    },
];