import { ContentItem } from "../../types";
import { images, getFilterStyle, Colors, getBgColorStyle, getTextColorStyle, colors } from "../../theme";

import Singlestyle from "../../components/styles/Singlestyle";
import Narrowstyle2 from "../../components/styles/Narrow2";
import NTPstyle from "../../components/styles/NTPstyle";
import Iframestyle from "../../components/styles/Iframestyle";
import Calendarstyle from "../../components/styles/Calendarstyle";
import Narrowwpopstyle from "../../components/styles/Narrowwpopstyle";
import Paperlessstyle from "../../components/styles/Paperlessstyle.js";
import FormstyleLEDs from "../../components/styles/FormstyleLEDs.js";

// const newcustomer = require('../../img/newcustomer.png');
const tariffs = require('../../img/tariffs.png');
// const handglobe = require('../../img/hand-globe.png');
 
const coinsmany = require('../../img/coinsmany.png');
const cesfamily = require('../../img/ces-family.png');
const renewal = require('../../img/renewals.png');
const keys2 = require('../../img/ces-key2.png');
const house2 = require('../../img/house2.png');
const phone = require('../../img/phone.png');
const hello = require('../../img/hello.png');
const directdebit = require('../../img/directdebit.png');
const plug2 = require('../../img/plug.png');
const ceshouse = require('../../img/ces-house.png');
const ceslogo = require('../../img/ces-logo.png');
const allyouneedtoknow = require('../../img/allyouneedtoknow.png');
const led = require('../../img/led.png');

export const config: ContentItem[] = [


  {
  
    leftContent:`	<h2 class="heading heading--large" style="color:#464e51;">Order your FREE LEDs</h2>
    <p style="color:#464e51; ">In addition to other savings, you will receive a free lifetime supply of money-saving LED lightbulbs for your home. This means not only will you be charged less for your electricity, you will also use less!  </p> `,

    rightImage: led ,
    topCurve: images.topcurvedarkgreen,
    topCurveFilter: getFilterStyle(Colors.none),
    component: Narrowstyle2,
    backgroudColorSection: getBgColorStyle(Colors.yellow),


    leftContentStyle: getTextColorStyle(Colors.white),
    rightContentStyle: getTextColorStyle(Colors.blue),
    useMargin: false,

  },

  { 

      component: FormstyleLEDs,

    },


];