import { ContentItem } from "../../types";
import { images, getFilterStyle, Colors, getBgColorStyle, getTextColorStyle, colors } from "../../theme";

import Singlestyle from "../../components/styles/Singlestyle";
import Narrowstyle2 from "../../components/styles/Narrow2";
import NTPstyle from "../../components/styles/NTPstyle";
import Iframestyle from "../../components/styles/Iframestyle";
import Calendarstyle from "../../components/styles/Calendarstyle";
import Narrowwpopstyle from "../../components/styles/Narrowwpopstyle";
import Buttonstyle from "../../components/styles/Buttonstyle";

// const newcustomer = require('../../img/newcustomer.png');
const tariffs = require('../../img/tariffs.png');
// const handglobe = require('../../img/hand-globe.png');
 
const coinsmany = require('../../img/coinsmany.png');
const cesfamily = require('../../img/ces-family.png');
const renewal = require('../../img/renewals.png');
const keys2 = require('../../img/ces-key2.png');
const house2 = require('../../img/house2.png');
const phone = require('../../img/phone.png');
const hello = require('../../img/hello.png');
const directdebit = require('../../img/directdebit-1.png');
const plug2 = require('../../img/plug.png');
const ceshouse = require('../../img/ces-house.png');
const ceslogo = require('../../img/ces-logo.png');
const allyouneedtoknow = require('../../img/allyouneedtoknow.png');

export const config: ContentItem[] = [


  {
    leftContent: `	<h2 class="heading heading--large" style="margin-top:60px;">Alright, let's get started!</h2>
    <p style="margin-bottom:50px;">Joining the scheme has never been easier. Just choose your tenancy type, fill in your details, and a member of the team will be in touch to confirm your membership. </p>
      `,
    topCurve: images.topcurvedarkgreen,
    topCurveFilter: getFilterStyle(Colors.none),
    backgroudColorSection: getBgColorStyle(Colors.teal),

    component: Singlestyle,
    useMargin: true,
  },

  {
    leftImage: allyouneedtoknow,
    leftContent: `<h3 class="heading" " >Checklist to get ready</h3>
    <p>You will need the full name of the lease holder(s), signature(s), contact details, and the property address. The fields &quot;Customer Reference Number&quot; and &quot;Existing Energy Supplier Details&quot; are not mandatory, you can leave them blank and our advisors will assist you adding them later.</p>

<p>Got a question? You can give us a call on <a href="tel:01782438427" style="text-decoration:underline!important;"><span style="font-weight:500;">01782 438 427</span></a> for real-time support during the sign-up process</p>`,

    component: Buttonstyle,
    backgroudColorSection: getBgColorStyle(Colors.teal),
    leftContentStyle: getTextColorStyle(Colors.white),
    rightContentStyle: getTextColorStyle(Colors.white),
    bottomCurve: images.asymmetricalCurveBottom,
    bottomCurveFilter: getFilterStyle(Colors.yellow),
    rightImage: renewal,
    rightContent: `<h1 class="heading"  >Documents to sign</h1>
    <p>You will be signing the document &quot;<em>Electricity supply contract for domestic roof top supply and Community Energy Scheme</em>&quot; and &quot;<em>Variation of Tenancy Agreement</em>&quot; (if applicable), allowing us to install Solar Panels at the property and supply you with the energy generated for the next 25 years.</p>
<p>Remember, this means that you&#39;ll have locked down the absolute best rate for your energy all throughout this period.&nbsp;</p>`,
    useMargin: true,
    sectionStyle: {
      // marginTop: '-7.5%',
    },
  },



  {
    topCurve: images.symmetricalCurveTop,
    topCurveFilter: getFilterStyle(Colors.yellow),
    component: Iframestyle,
    leftContent: `	<img src="${ceslogo}" style="max-width:370px;" /> <h1 class="heading" style="margin-top:30px;">Let's begin</h1>

    <p>Choose your tenancy type:</p>
    `,
    backgroudColorSection: getBgColorStyle(Colors.blue),
    bottomCurve: images.asymmetricalCurveBottom,
    bottomCurveFilter: getFilterStyle(Colors.orange),

  },




  
  {
    topCurve: images.symmetricalCurveTop,
    topCurveFilter: getFilterStyle(Colors.orange),

    leftContent: `<img src=${hello} />`,
    rightContent: `<h2 class="heading heading--large" style="color:#464e51;">We’re here to help</h2>
    <p style="color:#464e51; ">Got a question?<br>
    Need some help?<br>
    You can give us a call on <a href="tel:01782438427" style="text-decoration:underline!important;"><span style="font-weight:500;">01782 438 427</span></a><br>
    Or drop us a line at <a href="mailto:stoke@communityenergyscheme.com" style="text-decoration:underline!important;font-weight:500;">stoke@communityenergyscheme.com </br></a> Alternatively, check out our help section.</p><a class="download-button download-button--dark-purple" href=${`/help`} >Find help</a> `,

    component: NTPstyle,
    backgroudColorSection: getBgColorStyle(Colors.yellow),
    leftContentStyle: getTextColorStyle(Colors.grey),
    rightContentStyle: getTextColorStyle(Colors.grey),
  bottomCurve: images.symmetricalbottomcurvedarkgreen,  
  bottomCurveFilter: getFilterStyle(Colors.none),  
  },


];