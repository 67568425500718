import React, { useState } from 'react';
import '../../index.css';
import '../../stoke.css';
import '../../bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ContentItem } from '../../types';

const NTPstyle: React.FC<ContentItem> = ({
  leftContent,
  rightContent,
  topCurve,
  topCurveFilter,
  backgroudColorSection,
  bottomCurveFilter,  
  bottomCurve,
  leftContentStyle,
  rightContentStyle,
  leftImage,
  rightImage,
  useMargin = false,
  sectionStyle,
}) => {

  const marginContent = React.useMemo(
    () => bottomCurve && useMargin ? { marginTop: "-130px"} : undefined
  ,[]);

  const sectionRef = React.useRef<HTMLDivElement | null>(null);

  const onScrollIntoView = () => {
    let position: DOMRect | undefined = sectionRef?.current?.getBoundingClientRect();
  
    // checking for partial visibility
    if(position && (position.top < window.innerHeight && position.bottom >= 0)) {
      sectionRef?.current?.classList.add('animation-end');
      window.removeEventListener('scroll', onScrollIntoView);
    }
  }

  React.useEffect(() => {
    onScrollIntoView();
    window.addEventListener('scroll', onScrollIntoView);
  }, []);

  return (
    <div id='first-section-of-page' style={sectionStyle}>
      <div className="content site-content">
        <div className="primary content-area" >
          <main className="main site-main" style={backgroudColorSection}>

            <div className='stoke-section--1'>
              <img src={topCurve} style={topCurveFilter} />
              <Container className="section" >
                <Row style={marginContent} className='animation-start' ref={sectionRef}>
                  <Col md="6"  className="col-md-6"   >
                    <div className="section-content">
                      <div className="section-content__inner">
                      <img src={leftImage} />
                        {leftContent && (
                          <div style={leftContentStyle} dangerouslySetInnerHTML={{ __html: leftContent }} />
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col md="6"  className="col-md-6"   >
                    <div className="section-content">
                      <div className="section-content__inner">
                        {rightContent && (
                          <div style={rightContentStyle} dangerouslySetInnerHTML={{ __html: rightContent }} />
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
              <img src={bottomCurve} style={bottomCurveFilter} />
            </div >
          </main>
        </div>
      </div>
    </div>
  );
}
export default NTPstyle;

//dangerouslySetInnerHTML={{ __html: topContent }} this makes html work somehow, try to avoid using it because it means DANGEEEEERRRR

