import React from 'react';
import '../../index.css';
import '../../stoke.css';
import '../../bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ContentItem, FAQcategory } from '../../types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'


const coins = require('../../img/coins.png');
const telephone = require('../../img/telephone.png');
const house = require('../../img/house.png');
const renew = require('../../img/renewalssmall.png');

const MyVerticallyCenteredModal: React.FC<any> = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ color: "black" }}>
            {props.text}
          </p>
        </Modal.Body>
      </Modal>
    );
  }
  
  
  const modalContent = {
    button14: {
      title: 'Price Match Promise',
      text: 'How can we guarantee that you will always pay the best rate? Because if someone offers you a better price you just need to contact us with your bill and we will match it! ',
    },
    button15: {
      title: '25% solar rebate',
      text: 'All members without overdue balance or with a payment plan in place will receive a monthly rebate of 25% of their solar bill.'
    },
    button16: {
      title: 'Membership perks',
      text: 'As a member of the scheme you will receive a £50 discount off your first electrical bill. In addition to this you will receive free money-saving LED lightbulbs for your home. This means not only will you be charged less for your electricity, you will also use less!'
    },
  }
  


const Joinpopupstyle: React.FC<ContentItem> = ({
    leftContent,
    rightContent,
    topCurve,
    topCurveFilter,
    backgroudColorSection,
    bottomCurveFilter,
    bottomCurve,
    leftContentStyle,
    rightContentStyle,
    leftImage,
    rightImage,
    useMargin = true,
    help = [],
    cardColor,
}) => {

    const marginContent = React.useMemo(
        () => bottomCurve && useMargin ? { marginTop: "-130px" } : undefined
        , []);

    
        const sectionRef = React.useRef<HTMLDivElement | null>(null);
    
        const onScrollIntoView = () => {
          let position: DOMRect | undefined = sectionRef?.current?.getBoundingClientRect();
        
          // checking for partial visibility
          if(position && (position.top < window.innerHeight && position.bottom >= 0)) {
            sectionRef?.current?.classList.add('animation-end');
            window.removeEventListener('scroll', onScrollIntoView);
          }
        }
      
        React.useEffect(() => {
          onScrollIntoView();
          window.addEventListener('scroll', onScrollIntoView);
        }, []);



    return (

        <div id='first-section-of-page'>
            <div className="content site-content">
                <div className="primary content-area">
                    <div className="main site-main" style={backgroudColorSection}>

                        <div className="section help-section-22">
                            <Container>
                              
                                <Row className='animation-start' ref={sectionRef}>
                                      <Col sm = "6" md = "4" className = " col-xs-6 col-md-4 help-grid-item" > 
                                        <div className="section-content fadeInTop fadeInTop--active"> < img src={telephone} className="section-image--help" /> 
                                        <h2 className="heading heading--small"> Over the phone </h2>
                                            <a className="download-button download-button--dark-purple" href="tel:01782438427" >Call now</a > 
                                        </div> 
                                      </Col>


                                      <Col sm = "6" md = "4" className = " col-xs-6 col-md-4 help-grid-item" > 
                                        <div className="section-content fadeInTop fadeInTop--active"> < img src={house} className="section-image--help" /> 
                                            <h2 className="heading heading--small"> Book a home visit </h2>
                                            <a className="download-button download-button--dark-purple" href="/calendar">Book now</a > 
                                        </div> 
                                      </Col>


                                      <Col sm = "6" md = "4" className = " col-xs-6 col-md-4 help-grid-item" > 
                                        <div className="section-content fadeInTop fadeInTop--active"> < img src={renew} className="section-image--help" /> 
                                            <h2 className="heading heading--small"> Sign up online </h2>
                                            <a className="download-button download-button--dark-purple" href="/sign_up">Sign up</a > 
                                        </div> 
                                      </Col>                     
                                </Row>

                            </Container>
                            <img src={bottomCurve} style={bottomCurveFilter} id='faq' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Joinpopupstyle;

// //dangerouslySetInnerHTML={{ __html: topContent }} this makes html work somehow, try to avoid using it because it means DANGEEEEERRRR

