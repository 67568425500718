import React, { useState } from 'react';
import '../../index.css';
import '../../stoke.css';
import '../../bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ContentItem } from '../../types';

const Circlestyle: React.FC<ContentItem> = ({
  leftContent,
  rightContent,
  topCurve,
  topCurveFilter,
  backgroudColorSection,
  bottomCurveFilter,
  bottomCurve,
  leftContentStyle,
  rightContentStyle,
  leftImage,
  rightImage,
  useMargin = true,
  circle = [],
}) => {

  const marginContent = React.useMemo(
    () => bottomCurve && useMargin ? { marginTop: "150px" } : undefined
    , []);


    const sectionRef = React.useRef<HTMLDivElement | null>(null);
    
    const onScrollIntoView = () => {
      let position: DOMRect | undefined = sectionRef?.current?.getBoundingClientRect();
    
      // checking for partial visibility
      if(position && (position.top < window.innerHeight && position.bottom >= 0)) {
        sectionRef?.current?.classList.add('animation-end');
        window.removeEventListener('scroll', onScrollIntoView);
      }
    }
  
    React.useEffect(() => {
      onScrollIntoView();
      window.addEventListener('scroll', onScrollIntoView);
    }, []);

  return (
    <div id='first-section-of-page'>
    <div className="content site-content" style={{marginTop: -1}}>
        <div className="primary content-area">
            <div className="main site-main" style={backgroudColorSection}>

                <div className="section help-section-2" style={{paddingTop:100, }}>
                    <Container >
                        <Row className='animation-start' ref={sectionRef} >
                           
                            
                                <Col xs="12" md="4"  className="help-grid-item img" >
                                    <div className="section-content fadeInTop fadeInTop--active">
                                        <img src={require('../../img/fair.png')} className="section-image--help" />
                                    </div>
                                </Col>
                                <Col xs="12" md="4"  className="help-grid-item img" >
                                    <div className="section-content fadeInTop fadeInTop--active">
                                        <img src={require('../../img/match.png')} className="section-image--help" />
                                    </div>
                                </Col>
                                <Col xs="12" md="4"  className="help-grid-item img" >
                                    <div className="section-content fadeInTop fadeInTop--active">
                                        <img src={require('../../img/rebate.png')} className="section-image--help" />
                                    </div>
                                </Col>


                        </Row>
                        

                    </Container>
                </div>
            </div>
            <div style={{backgroundColor:' #3b7bb8 '}}>
            <img src={bottomCurve} style={bottomCurveFilter}  className="no-desktop"/>
            </div>
            
        </div>
        
    </div>
    
</div>
  );
}
export default Circlestyle;

//dangerouslySetInnerHTML={{ __html: topContent }} this makes html work somehow, try to avoid using it because it means DANGEEEEERRRR

