import { ContentItem } from "../../types";
import { images, getFilterStyle, Colors, getBgColorStyle, getTextColorStyle, colors } from "../../theme";
import VideoLibrary from "../../components/styles/VideoLibrary";

import Singlestyle from "../../components/styles/Singlestyle";
import Narrowstyle2 from "../../components/styles/Narrow2";
import NTPstyle from "../../components/styles/NTPstyle";
import Iframestyle from "../../components/styles/Iframestyle";
import Narrowwpopstyle from "../../components/styles/Narrowwpopstyle";
import Joinpopupstyle from "../../components/styles/Joinpopupstyle";
import Signupformstyle from "../../components/styles/Signupformstyle";



const videoill = require('../../img/good/videoill.png');

export const config: ContentItem[] = [
  {
    topCurve: images.symmetricaltopcurvedarkgreen,
    topCurveFilter: getFilterStyle(Colors.none),

    leftContent:`<h2 class="heading heading--large" style="color:#464e51;">Community Energy Scheme Guidance Videos</h2>
    <p style="color:#464e51; ">
    Below you will find videos that will assist you with all aspects of the Community Energy Scheme.
</p>

<p style="color:#464e51; ">
These videos are here to help you understand how your system works, checking for any faults, and a few tips and tricks to help you utilise your solar energy. 
</p>
    
    ` ,
    rightContent:`<img src=${videoill} />` ,

    component: NTPstyle,
    backgroudColorSection: getBgColorStyle(Colors.lightsky ),
    leftContentStyle: getTextColorStyle(Colors.grey),
    rightContentStyle: getTextColorStyle(Colors.grey),
  bottomCurve: images.bottomcurvegrass ,
  bottomCurveFilter: getFilterStyle(Colors.none),  
  },

 

  {
    leftContent: `	  
    <h2 style="margin-top:1em; color:#464e51;" class="heading heading--alt">Video Tutorials</h2>
    
    <p style="color:#464e51;">These videos include how to take a meter reading, If your system trips,  claiming light bulbs and energy-saving tips to name a few! 
 
    </p>
    
    `,
    topCurve: images.topcurvegrass,
    topCurveFilter: getFilterStyle(Colors.none),
    backgroudColorSection: getBgColorStyle(Colors.lightsky),
    leftContentStyle: { marginBottom: "0px" },
    component: Singlestyle,
    useMargin: false,

  },

  {

    component: VideoLibrary,
    backgroudColorSection: getBgColorStyle(Colors.lightsky),
    bottomCurve: images.symmetricalbottomcurvedarkgreen ,
    bottomCurveFilter: getFilterStyle(Colors.none),
    leftContentStyle: getTextColorStyle(Colors.white),
    rightContentStyle: getTextColorStyle(Colors.blue),
    useMargin: false,

  },


];